import React, { useEffect, useMemo, useState } from "react";
import {
  PageContainer,
  MRTable,
  Button,
  IconButton,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import useGetTenantsTable, {
  TenantTableEntity,
} from "../hooks/useGetTenantsTable";
import { useTranslation } from "react-i18next";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import CreateDialog from "./CreateDialog";
import usePageSettings from "domain/user/usePageSettings";
import { TableLocalStorage } from "hooks/usePageLocalStorage";

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const { pageSettings, setPageSettingsForMRTable } =
    usePageSettings<TableLocalStorage>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const {
    data: { tenants } = { tenants: [] },
    loading,
    refetch,
  } = useGetTenantsTable();

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<TenantTableEntity>[], TenantTableEntity[]]
  >(
    () => [
      [
        {
          header: t("Name"),
          id: "tenantName",
          accessorFn: (originalRow) => originalRow.tenantName,
        },
        {
          header: t("Type"),
          id: "tenantType",
          accessorFn: (originalRow) => originalRow.tenantType,
        },
        {
          header: t("External Tenant"),
          id: "externalTenantId",
          accessorFn: (originalRow) => originalRow.externalTenantId,
        },
        {
          header: t("Wd Customer"),
          id: "isWdCustomer",
          filterVariant: "checkbox",
          accessorFn: (originalRow) => originalRow.isWdCustomer,
          Cell: ({ row: { original: originalRow } }) =>
            !!originalRow.isWdCustomer && <CheckIcon />,
        },
        {
          header: t("Address"),
          id: "address",
          accessorFn: ({ address }) =>
            `${[address.companyName, address.companyName2]
              .filter((el) => el)
              .join(", ")}\n${t(`classify:Customs`)}: ${
              address.customsAccountNumber || "-"
            }, ${t(`classify:UID`)}: ${address.uidNumber || "-"}, ${t(
              `classify:Tax`
            )}: ${address.traderIdentificationNumber || "-"}, ${t(`Ref`)}: ${
              address.reference || "-"
            }`,
        },
        {
          header: t("Roles"),
          id: "roles",
          accessorFn: (originalRow) =>
            originalRow.roles?.map((el) => el.name).join(", "),
        },
        {
          header: t("Account Number"),
          id: "accountNumber",
          accessorFn: (originalRow) => originalRow.accountNumber,
        },
        {
          header: t("Trader Identification Number"),
          id: "traderIdentificationNumber",
          accessorFn: (originalRow) => originalRow.traderIdentificationNumber,
        },
        {
          header: t("Created at"),
          id: "creationDate",
          accessorFn: (originalRow) =>
            originalRow.creationDate &&
            dayjs(originalRow.creationDate).startOf("day"),
          sortingFn: "datetime",
          filterVariant: "date",
          muiFilterDatePickerProps: {
            format: "DD.MM.YYYY",
          },
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.creationDate &&
            dayjs(originalRow.creationDate).format("DD.MM.YYYY"),
        },
        {
          header: t("Modified at"),
          id: "lastModifiedDate",
          accessorFn: (originalRow) =>
            originalRow.lastModifiedDate &&
            dayjs(originalRow.lastModifiedDate).startOf("day"),
          sortingFn: "datetime",
          filterVariant: "date",
          muiFilterDatePickerProps: {
            format: "DD.MM.YYYY",
          },
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.lastModifiedDate &&
            dayjs(originalRow.lastModifiedDate).format("DD.MM.YYYY"),
        },
      ],
      [...tenants],
    ],
    [t, tenants]
  );

  const [pagination, setPagination] = useState<MRTable.MRT_PaginationState>({
    pageSize: pageSettings.itemsPerPage,
    pageIndex: 0,
  });
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(pageSettings.columnPinning ?? {});
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(pageSettings.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? columns.map((c) => c.id!)
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: pagination.pageSize,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
      });
    },
    // eslint-disable-next-line
    [
      pagination.pageSize,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <>
      <PageContainer title={t("navigation:tenants")}>
        <MRTable.Table
          columns={columns}
          data={tableData}
          initialState={{
            sorting: [{ id: "creationDate", desc: true }],
          }}
          state={{
            pagination,
            columnSizing,
            columnPinning,
            columnVisibility,
            columnOrder,
            isLoading: loading,
          }}
          onPaginationChange={setPagination}
          onColumnSizingChange={setColumnSizing}
          onColumnPinningChange={setColumnPinning}
          onColumnVisibilityChange={setColumnVisibility}
          onColumnOrderChange={setColumnOrder}
          onRefresh={() => refetch()}
          enableColumnFilters
          renderToolbarInternalActions={({ table }) => (
            <>
              <MRTable.MRT_ToggleFiltersButton table={table} />
              <MRTable.MRT_ShowHideColumnsButton table={table} />
              <MRTable.MRT_ToggleFullScreenButton table={table} />
              <Button
                variant="contained"
                color="primary"
                endIcon={<Add />}
                onClick={() => {
                  setCreateDialogOpen(true);
                }}
              >
                {t("Create new")}
              </Button>
            </>
          )}
          enableRowActions
          renderRowActions={({ row: { original: originalRow } }) => (
            <Link to={`/settings/tenants/${originalRow.id}`}>
              <IconButton color="primary">
                <Launch />
              </IconButton>
            </Link>
          )}
          muiTableBodyRowProps={({ row: { original: originalRow } }) => ({
            onDoubleClick: () =>
              history.push(`/settings/tenants/${originalRow.id}`),
          })}
          muiTableContainerProps={{
            sx: {
              maxHeight: "calc(100vh - 245px)",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content-Wrapper": {
                whiteSpace: "nowrap",
              },
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              whiteSpace: "pre",
            },
          }}
        />
      </PageContainer>
      {createDialogOpen && (
        <CreateDialog
          onClose={() => setCreateDialogOpen(false)}
          onConfirm={() => {
            refetch();
            setCreateDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
