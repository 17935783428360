import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const useDeleteFile = () => {
  const [deleteFile] = useMutation(gql`
    mutation updateFile($id: [uuid!]!) {
      update_file(where: { id: { _in: $id } }, _set: { is_deleted: true }) {
        affected_rows
      }
    }
  `);

  const removeFile = useCallback(
    async (fileIds: string | string[]) => {
      await deleteFile({
        variables: {
          id: fileIds,
        },
      });
    },
    [deleteFile]
  );

  return removeFile;
};

export default useDeleteFile;
