import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { get } from "lodash";
import { useAuth } from "contexts/Auth/AuthContext";
import { HARDCODED_TENANTS } from "domain/tenant/type";

export enum Setting {
  DocumentTypesTemplates = "00000000-0000-0000-0000-000000000001",
  TenantConfig = "00000000-0000-0000-0000-000000000003",
  FavoriteLabels = "00000000-0000-0000-0000-000000000005",
}

export enum ReferenceType {
  UserId = "user_id",
  TenantId = "tenant_id",
}

export type SettingValueType = {
  setting_id: string;
  value: any;
  display_name: string;
  id: string;
  is_template: boolean;
};

interface SettingValueResult {
  setting_value: SettingValueType[];
}

export const GET_SETTING_VALUE = gql`
  query GET_SETTING_VALUE(
    $setting_id: uuid
    $reference_type_id: String
    $reference_id: String
  ) {
    setting_value(
      where: {
        setting_id: { _eq: $setting_id }
        reference_type_id: { _eq: $reference_type_id }
        reference_id: { _eq: $reference_id }
      }
      order_by: { display_name: asc }
    ) {
      id
      display_name
      value
      setting_id
      is_template
    }
  }
`;

const useGetSettingValue = ({
  setting,
  reference_type_id = ReferenceType.UserId,
  reference_id,
}: {
  setting: Setting;
  reference_type_id?: ReferenceType;
  reference_id?: string;
}) => {
  const { user } = useAuth();

  let _reference_id =
    reference_id || reference_type_id === ReferenceType.TenantId
      ? get(user, "tenantId", HARDCODED_TENANTS.mnr)
      : get(user, "userId");

  const queryResult = useQuery<SettingValueResult>(GET_SETTING_VALUE, {
    variables: {
      setting_id: setting,
      reference_type_id,
      reference_id: _reference_id,
    },
  });

  const returnValue = useMemo(() => {
    const { data, ...rest } = queryResult;

    return {
      data: data ? data.setting_value : [],
      ...rest,
    };
  }, [queryResult]);

  return returnValue;
};

export default useGetSettingValue;
