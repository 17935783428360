import React, {
  FC,
  HtmlHTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import {
  ThreeDotsMenu,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@periplus/ui-library";
import Table from "components/Table";
import SettingsIcon from "@mui/icons-material/Settings";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import usePageLocalStorage from "hooks/usePageLocalStorage";
import { OrgId } from "domain/orgId/types";
import { makeStyles } from "tss-react/mui";

interface OrgIdsTableProps extends HtmlHTMLAttributes<HTMLElement> {
  loading: boolean;
  data?: OrgId[];
  totalCount?: number;
  fetch: (limit: number, offset: number) => void;
  onEdit: (item: OrgId) => void;
  onDelete: (item: OrgId) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: { marginTop: 16 },
  threeDotMenuIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

const OrgIdsTable: FC<OrgIdsTableProps> = ({
  loading,
  data,
  totalCount,
  fetch,
  onEdit,
  onDelete,
}) => {
  const { classes } = useStyles();
  const {
    pageLocalStorage: { itemsPerPage },
    setPageLocalStorage,
  } = usePageLocalStorage();

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setPageLocalStorage({ itemsPerPage: newItemsPerPage });
  };

  const handlePageChange = useCallback(() => {}, []);

  const loadMore = useCallback(
    (offset, limit) => {
      fetch(offset, limit);
    },
    [fetch]
  );

  const columnDefs = useMemo(
    () => [
      {
        Header: () => "OrgId",
        accessor: "org_id",
      },

      {
        Header: () => "Name",
        accessor: "display_name",
      },
      {
        Header: () => "Description",
        accessor: "org_id_description",
      },
      {
        Header: () => "Addresses",
        accessor: "addresses_desc",
      },
      {
        Header: (): ReactNode => "OrgIds",
        accessor: "org_ids_desc",
        Cell: ({ row }: { row: { original: OrgId } }): ReactNode => {
          return (
            row.original.org_ids_desc?.split(";").map((el) => (
              <>
                <span style={{ whiteSpace: "nowrap" }}>{el}</span>
                <br />
              </>
            )) || ""
          );
        },
      },
      {
        Header: () => <SettingsIcon />,
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row }: { row: { original: OrgId } }) => {
          const { classes } = useStyles();
          const options = [
            {
              content: (
                <>
                  <ListItemIcon className={classes.threeDotMenuIcon}>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edit" />
                </>
              ),
              onClick: () => onEdit(row.original),
            },
            {
              content: (
                <>
                  <ListItemIcon className={classes.threeDotMenuIcon}>
                    <DeleteOutlineOutlinedIcon color="error" />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </>
              ),
              className: classes.deleteButton,
              onClick: () => onDelete(row.original),
            },
          ];

          return <ThreeDotsMenu options={options} />;
        },
      },
    ],
    []
  );

  return (
    <div className={classes.root}>
      <Table
        columnDefs={columnDefs}
        loading={loading}
        rowData={data || []}
        itemCount={totalCount || 0}
        initialItemsPerPage={itemsPerPage}
        onChangeItemsPerPage={handleItemsPerPageChange}
        onPageChange={handlePageChange}
        loadMore={loadMore}
      />
    </div>
  );
};

export default OrgIdsTable;
