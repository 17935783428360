import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useAuth } from "contexts/Auth/AuthContext";
import { HARDCODED_TENANTS } from "domain/tenant/type";

export type TenantSetting = {
  [key: string]: {
    isSecret: boolean;
    value: number | string | string[];
  };
};

interface TenantSettingResult {
  getTenantConfig: TenantSetting;
}

export const GET_TENANT_SETTING = gql`
  query GET_TENANT_SETTING($tenantId: ID!) {
    getTenantConfig(tenantId: $tenantId)
  }
`;

const useGetTenantSettings = (tenantId?: string): any => {
  const { user } = useAuth();

  const queryResult = useQuery<TenantSettingResult>(GET_TENANT_SETTING, {
    variables: {
      tenantId: tenantId || user?.tenantId || HARDCODED_TENANTS.mnr,
    },
    fetchPolicy: "network-only",
  });

  const returnValue = useMemo(() => {
    const { data, ...rest } = queryResult;

    return {
      ...rest,
      data: data ? data.getTenantConfig : {},
      AllowAddUserInUserManagement:
        data?.getTenantConfig?.AllowAddUserInUserManagement === undefined ||
        data.getTenantConfig.AllowAddUserInUserManagement,
    };
  }, [queryResult]);

  return returnValue;
};

export default useGetTenantSettings;
