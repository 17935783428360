import React, {
  FC,
  HtmlHTMLAttributes,
  useState,
  useMemo,
  useEffect,
} from "react";
import {
  Table,
  PageContainer,
  Search,
  Button,
  Rows,
  Columns,
  Theme,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import { Document } from "domain/document/types";
import useGetDocumentsSubscription from "domain/document/useGetDocumentsSubscription";
import { useTranslation } from "react-i18next";
import CopyText from "components/CopyText";
import dayjs from "dayjs";
import { getExtensionDescriptionIcon } from "pages/Select/extensionDescription";
import { useAuth } from "contexts/Auth/AuthContext";
import DocumentStatus from "domain/document/components/DocumentStatus";
import DocumentRowActions from "domain/document/components/DocumentRowActions";
import PDFPreview from "./PDFPreview";
import FilterMenu from "./FilterMenu";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import usePageLocalStorage, {
  ColumnsSettings,
} from "hooks/usePageLocalStorage";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";
import { Sorted } from "graphql/helpers";
import getDocumentExtension from "utils/getDocumentExtension";
import { makeStyles } from "tss-react/mui";

interface DocumentsProps extends HtmlHTMLAttributes<HTMLElement> {}

export interface Filters {
  onlyMine: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  controlsContainer: {
    display: "flex",
    gap: theme.spacing(),
  },
  actionsColumn: {
    width: "0%",
    textAlign: "end",
  },
}));

const defaultFilters: Filters = {
  onlyMine: true,
};

const Documents: FC<DocumentsProps> = () => {
  const { classes } = useStyles();
  const { tenantConfig } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage<{
    itemsPerPage: number;
    filters: Filters;
    columnsSettings?: ColumnsSettings;
  }>({
    itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    filters: {
      ...defaultFilters,
    },
  });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    {
      page: number;
      itemsPerPage: number;
      search?: string;
      sorted?: Sorted;
    } & Filters
  >({
    page: 1,
    itemsPerPage: pageLocalStorage.itemsPerPage,
    ...defaultFilters,
    ...pageLocalStorage.filters,
  });

  const {
    data: { documents, isLastPage },
    loading,
  } = useGetDocumentsSubscription(urlSearchParams);

  const [columns, setColumns] = useState<Columns<Document>>({});

  useEffect(() => {
    setColumns(
      Object.entries({
        document_name: {
          header: t("fileName"),
          cell: (row) => {
            const document_name = row.data.document_name
              .split(".")
              .slice(0, -1)
              .join(".");
            return (
              <CopyText textToCopy={document_name} title={document_name}>
                {document_name}
              </CopyText>
            );
          },
        },
        number_of_pages: {
          header: t("pages"),
          cell: (row) => row.data.pages_m2m_aggregate.aggregate.count,
        },
        document_extension: {
          header: t("type"),
          cell: (row) => {
            const Icon = getExtensionDescriptionIcon(
              getDocumentExtension(
                row.data.document_name,
                row.data.document_blobs
              )
            );

            return <Icon />;
          },
        },
        org_id_description: {
          header: t("orgId"),
          cell: (row) => row.data.orgId.org_id_description,
        },
        user_display_name: {
          header: t("user"),
          cell: (row) => row.data.created_by_user.display_name,
        },
        creation_date: {
          header: t("creationDate"),
          cell: (row) =>
            dayjs(row.data.creation_date).format("DD.MM.YYYY HH:mm:ss"),
        },
        document_status: {
          header: t("status"),
          cell: (row) => {
            const defaultFlags = tenantConfig?.UseAutoclassification
              ? row.data.document_flags
              : 0;
            return (
              <div title={"Status: " + row.data.document_status}>
                <DocumentStatus
                  status={row.data.document_status}
                  defaultFlags={defaultFlags}
                />
              </div>
            );
          },
        },
        actions: {
          actionsColumn: true,
          sortable: false,
          customizable: false,
          headerClassName: classes.actionsColumn,
          cellClassName: classes.actionsColumn,
          cell: (row) => (
            <DocumentRowActions
              id={row.data.id}
              document_blobs={row.data.document_blobs}
              name={row.data.document_name}
              status={row.data.document_status}
              onDelete={(deletedDocumentId: string) => {
                setRows((prev) => {
                  return Object.entries(prev).reduce((acc, [rowId, row]) => {
                    acc[rowId] = row;
                    if (rowId === deletedDocumentId)
                      acc[rowId] = {
                        ...row,
                        selected: false,
                        multiSelected: false,
                      };
                    return acc;
                  }, {} as Rows<Document>);
                });
              }}
            />
          ),
        },
      } as Columns<Document>).reduce((acc, [id, column]) => {
        acc[id] = {
          ...column,
          sort: urlSearchParams.sorted?.[id],
          ...pageLocalStorage.columnsSettings?.[id],
        };
        return acc;
      }, {} as Columns<Document>)
    );
  }, [t]);

  const [rows, setRows] = useState<Rows<Document>>({});

  useEffect(() => {
    setRows((prev) => {
      return {
        ...Object.entries(prev).reduce((acc, [id, row]) => {
          acc[id] = {
            ...row,
            selected: false,
            activePos: undefined,
          };
          return acc;
        }, {} as Rows<Document>),
        ...documents.reduce((acc, document, i) => {
          acc[document.id] = {
            ...prev[document.id],
            data: document,
            activePos: i,
            multiSelectable: !!document.pages_m2m_aggregate.aggregate.count,
            selected: i === 0,
          };
          return acc;
        }, {} as Rows<Document>),
      };
    });
  }, [documents]);

  const [selected, multiSelected] = useMemo(
    () => [
      Object.values(rows).find((row) => row.selected)?.data,
      Object.values(rows)
        .filter((row) => row.multiSelected)
        .map((row) => row.data),
    ],
    [rows]
  );

  const filters = useMemo(
    () => ({
      onlyMine: urlSearchParams.onlyMine,
    }),
    [urlSearchParams.onlyMine]
  );

  const navigateToClassification = (
    documentsToClassify: string[] | number[]
  ) => {
    sessionStorage.setItem(
      "classify_documents",
      JSON.stringify(documentsToClassify)
    );
    sessionStorage.setItem("classify_for", "documents");
    history.push({
      pathname: `/select/classification`,
    });
  };

  return (
    <PageContainer title={t("navigation:documents")}>
      <PDFPreview id={selected?.id} />
      <Table<Document>
        sx={(theme) => ({
          mt: 2,
          "& .LuiPanel-content": {
            [theme.breakpoints.up("sm")]: {
              maxHeight: "calc(100vh - 478px)",
            },
          },
        })}
        rows={rows}
        columns={columns}
        dataIdentifier="id"
        loading={loading}
        isLastPage={isLastPage}
        page={urlSearchParams.page}
        itemsPerPage={urlSearchParams.itemsPerPage}
        onChangePage={(newPage) => {
          setUrlSearchParams({ page: newPage });
        }}
        onChangeItemsPerPage={(newItemsPerPage) => {
          setPageLocalStorage({
            itemsPerPage: newItemsPerPage,
          });
          setUrlSearchParams({
            itemsPerPage: newItemsPerPage,
            page: 1,
          });
        }}
        onSort={(newColumns) => {
          setColumns(newColumns);

          const newSorted = Object.entries(newColumns).reduce(
            (acc, [id, column]) => {
              if (column.sort)
                acc[id] = {
                  ...column.sort,
                };
              return acc;
            },
            {} as Sorted
          );
          setUrlSearchParams({
            sorted: Object.keys(newSorted).length ? newSorted : undefined,
            page: 1,
          });
        }}
        onSelect={(newRows, doubleClick) => {
          if (doubleClick) {
            const [, newSelectedRow] =
              Object.entries(newRows).find(([, row]) => row.selected) || [];
            if (newSelectedRow?.data.pages_m2m_aggregate.aggregate.count) {
              navigateToClassification([newSelectedRow.data.id]);
            }
          } else {
            setRows(newRows);
          }
        }}
        onMultiSelect={(newRows) => setRows(newRows)}
        onColumnsSettingsChange={(newColumns) => {
          setColumns(newColumns);
          setPageLocalStorage({
            columnsSettings: Object.entries(newColumns).reduce(
              (acc, [id, column]) => {
                acc[id] = {
                  pos: column.pos as number,
                  visible: column.visible,
                };
                return acc;
              },
              {} as ColumnsSettings
            ),
          });
        }}
        filterMenu={
          <FilterMenu
            filters={filters}
            onChange={(newFilters) => {
              setUrlSearchParams({ ...newFilters, page: 1 });
              setPageLocalStorage({
                filters: newFilters,
              });
            }}
            onClear={() => {
              setUrlSearchParams({ ...defaultFilters, page: 1 });
              setPageLocalStorage({
                filters: defaultFilters,
              });
            }}
          />
        }
        controls={
          <div className={classes.controlsContainer}>
            <Search
              value={urlSearchParams.search}
              onChange={(newSearch) =>
                setUrlSearchParams({ search: newSearch, page: 1 })
              }
            />
            <Button
              variant="outlined"
              color="primary"
              disabled={!multiSelected.length}
              minimizeForMobiles
              endIcon={<ArrowForward fontSize="small" />}
              onClick={() =>
                navigateToClassification(multiSelected.map((el) => el.id))
              }
            >
              {t("select:start_classification")}
            </Button>
          </div>
        }
      />
    </PageContainer>
  );
};

export default Documents;
