import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery, QueryHookOptions } from "@apollo/client";
import { ApolloQueryResult } from "@apollo/client";
import { mapPages } from "graphql/helpers";
import { BaseDocument, Page } from "graphql/hooks/useGetDocuments";
import { useAuth } from "../../contexts/Auth/AuthContext";
import { ExtractType } from "domain/extractType/types";

export type DataPointCoordinates = [number, number, number, number];

export type DataPoint = {
  id: string;
  coordinates: DataPointCoordinates;
  value: string;
  page?: number;
  type?: string;
  manual?: boolean;
  dataPointsInside?: string;
  parentDataPointId?: string;
  lineItemType?: string;
};

export type AutoClassificationPage = {
  doc_type: string;
  page_id: string;
  page_number: number;
};

export interface Pages {
  forEach(arg0: (page: AutoClassificationPage) => void): any;
}

export type PageExtract = {
  extract_type_id: number;
  dataPoints: DataPoint[];
  extract_type: ExtractType;
  tables: any;
};

export interface IPage extends Page {
  pageExtracts: PageExtract[];
}

export interface Document extends BaseDocument {
  pages: IPage[];
  document_type: string;
  annotationType: any;
  sequence: number;
  documentType: {
    translations: {
      [key: string]: string;
    };
    documentSchema: any;
    document_type_tenant_settings: [any];
  };
}

interface File {
  identification: string;
  documents: Document[];
}

export interface ServerDocument extends BaseDocument {
  pages: {
    page: IPage;
  }[];
}

interface ReturnType {
  data: File;
  loading: boolean;
  error: Error | undefined;
  refetch: (variables?: {
    id: string;
    extract_type_id: number;
    tenantId: string;
  }) => Promise<ApolloQueryResult<Document>>;
}

const useGetValidationV2Document = ({
  variables,
  ...restOptions
}:
  | QueryHookOptions<
      any,
      {
        id?: string;
      }
    >
  | undefined = {}): ReturnType => {
  const { user } = useAuth();
  const tenantId = user && user.tenantId;
  const { data, loading, error, refetch } = useQuery(
    gql`
      query ADIT_GET_VALIDATION_V2_DOCUMENT($id: uuid!, $tenantId: uuid!) {
        file: file_by_pk(id: $id) {
          identification
          documents {
            id
            name: document_name
            meta
            document_extension
            annotationType(where: { annotation_provider_id: { _eq: 5 } }) {
              annotation_schema
            }
            pages: pages_m2m(order_by: { page_number: asc }) {
              page {
                id
                thumbnailBigUrl: thumbnail_big_url
                page_status
                rotate
                meta
                creation_user
                pageExtracts: page_extracts {
                  page_id
                  extract_type_id
                  extract_type {
                    extract_type_id
                    translations
                  }
                  dataPoints: value
                  tables
                }
              }
            }
            document_blobs
            document_flags
            document_type
            sequence
            documentType {
              translations
              document_type_tenant_settings(
                where: { tenant: { _eq: $tenantId } }
              ) {
                documentSchema: document_schema
              }
            }
          }
        }
      }
    `,
    {
      ...(restOptions as any),
      variables: {
        ...variables,
        tenantId: tenantId,
      },
      fetchPolicy: "network-only",
    }
  );

  const returnValue = useMemo(() => {
    const updatedDocuments =
      data?.file.documents.map((document: Document) => {
        const { document_type_tenant_settings, ...documentType } =
          document.documentType as any;
        const docTypeTenantSettings = document_type_tenant_settings?.[0];
        documentType.documentSchema = docTypeTenantSettings?.documentSchema;

        return {
          ...document,
          documentType,
          pages: mapPages(document.pages || []),
        };
      }) || [];

    return {
      data: data && {
        ...data?.file,
        documents: updatedDocuments,
      },
      loading,
      error,
      refetch,
    };
  }, [data, error, loading, refetch]);

  return returnValue;
};

export default useGetValidationV2Document;
