import React, { HtmlHTMLAttributes, useState } from "react";
import {
  ThreeDotsMenu,
  Theme,
  ListItemIcon,
  IconButton,
  Badge,
  Box,
} from "@periplus/ui-library";
import { Add, Launch } from "@mui/icons-material";
import { Text } from "components/Text";
import { useAuth, Role } from "contexts/Auth/AuthContext";
import { useHistory } from "react-router-dom";
import config from "config";
import ExportToCevConfirmDialog from "domain/declaration/components/ExportToCevConfirmDialog";
import { makeStyles } from "tss-react/mui";
import { DeclarationTableEntity } from "../hooks/useGetDeclarationsTable";
import { useApplicationAction } from "contexts/ApplicationContext";
import { ChatOutlined } from "@mui/icons-material";
import { DeclarationTableDiscussionEntity } from "../hooks/useGetDeclarationTableDiscussionsSubscription";

interface Props extends HtmlHTMLAttributes<HTMLElement> {
  declaration: DeclarationTableEntity;
  declarationDiscussion?: DeclarationTableDiscussionEntity;
  disabled: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  listIcon: {
    minWidth: 28,
  },
}));

export default ({ declaration, declarationDiscussion, disabled }: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const isAlphaTester = user?.allowedRoles?.includes(Role.AlphaTester);
  const isUserAllowedToAutoClassification = !!isAlphaTester;
  const [isExportToCevConfirmDialogOpen, setIsExportToCevConfirmDialogOpen] =
    useState(false);
  const appAction = useApplicationAction();

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <IconButton
        onClick={(event: any) => {
          event.stopPropagation();
          appAction.showChat({
            fileId: declaration.fileId,
            title: declaration.identification || "",
            discussionId: declaration.discussionId,
          });
        }}
        size="medium"
      >
        <Badge
          badgeContent={declarationDiscussion?.messages.aggregate?.count}
          color={"primary"}
          invisible={disabled}
          sx={{
            top: 3,
          }}
        >
          <ChatOutlined />
        </Badge>
      </IconButton>
      <ThreeDotsMenu
        options={[
          ...(isUserAllowedToAutoClassification
            ? [
                {
                  content: (
                    <>
                      <ListItemIcon className={classes.listIcon}>
                        <Add />
                      </ListItemIcon>
                      <Text>Declaration</Text>
                    </>
                  ),
                  action: () => {
                    history.push(
                      `/select/declaration/${encodeURIComponent(
                        declaration.fileId
                      )}/${declaration.drNo}`
                    );
                  },
                },
              ]
            : []),
          {
            content: (
              <>
                <ListItemIcon className={classes.listIcon}>
                  <Launch />
                </ListItemIcon>
                <Text>common:details</Text>
              </>
            ),
            action: () => {
              window.open(
                `${config.wedclare}/declaration/${declaration.fileId}/${declaration.drNo}`,
                "_blank"
              );
            },
            disabled: !declaration.fileId,
          },
        ]}
      />
      {isExportToCevConfirmDialogOpen && (
        <ExportToCevConfirmDialog
          file_id={declaration.fileId}
          dr_no={declaration.drNo}
          onClose={() => {
            setIsExportToCevConfirmDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};
