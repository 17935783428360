import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { SCHEDULE_DATA_EXTRACTION } from "../../graphql/mutations/file";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useScheduleDataExtraction = () => {
  const [scheduleExtraction] = useMutation(SCHEDULE_DATA_EXTRACTION);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const scheduleDataExtraction = useCallback(
    async (fileId: string, drNo: number) => {
      await scheduleExtraction({
        variables: { fileId, drNo },
      });
      enqueueSnackbar(t("common:success"), { variant: "success" });
    },
    [scheduleExtraction]
  );

  return scheduleDataExtraction;
};

export default useScheduleDataExtraction;
