import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import Tenant, { TENANT_TABS } from "./Tenant";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/${TENANT_TABS[0].id}`} />
      <Tenant />
    </Switch>
  );
};
