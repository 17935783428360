import React from "react";
import {
  IconButton,
  InputAdornment,
  FormTextField,
  TextFieldProps,
} from "@periplus/ui-library";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

interface Props extends TextFieldProps {
  name: string;
}

export default (props: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <FormTextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              onClick={() => setShowPassword((show) => !show)}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
              }}
              edge="end"
              sx={{
                color: "#797693",
              }}
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
