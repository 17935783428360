import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import {
  Search,
  Button,
  ActionBar,
  ActionBarItem,
  Loader,
  Grid,
  Theme,
  MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  PageContainer,
} from "@periplus/ui-library";
import Colored from "components/Colored";
import Text from "components/Text/Text";
import dayjs from "dayjs";
import { reorder } from "utils/arrays";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useMutation } from "@apollo/client";
import {
  UPDATE_SETTING_VALUE_BY_ID,
  UPDATE_SETTING_VALUE,
  CREATE_SETTING_VALUE,
  DELETE_SETTING_VALUE,
} from "graphql/mutations/setting_value";
import useGetSettingValue, {
  Setting,
  GET_SETTING_VALUE,
  ReferenceType,
} from "graphql/hooks/useGetSettingValue";
import { useAuth } from "contexts/Auth/AuthContext";
import SaveTemplateDialog from "./components/SaveTemplateDialog";
import TemplateSelect from "./components/TemplateSelect";
import get from "lodash/get";
import partition from "lodash/partition";
import useGetDocumentTypes from "domain/documentType/useGetDocumentTypes";
import { DocumentType } from "domain/documentType/types";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles } from "tss-react/mui";

interface IDocumentTypesSetting extends DocumentType {
  pos: number;
  visibility: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    overflowX: "auto",
  },

  table: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },

  tableHeader: {
    fontSize: 14,
    fontWeight: 500,
  },

  tableCell: {
    paddingTop: 6,
    paddingBottom: 6,
  },

  tableCellContent: {
    fontSize: 14,
  },

  actionBar: {
    flexDirection: "column",
    gap: 8,
    alignItems: "start",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}));

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "#e4e4e4" : "white",
  // styles we need to apply on draggables
  ...draggableStyle,
});

interface IDocumentTypesProps {}

const DocumentTypeTemplates: FC<IDocumentTypesProps> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const [documentTypeSettingsTemplate, setDocumentTypeSettingsTemplate] =
    useState<IDocumentTypesSetting[]>([]);
  const [filter, setFilter] = useState("");
  const [isOpenSaveTemplateDialog, setIsOpenSaveTemplateDialog] =
    useState(false);

  const filteredDocumentTypeSettingsTemplate = useMemo(() => {
    return documentTypeSettingsTemplate.filter(
      (docType) =>
        docType.translated_name &&
        docType.translated_name
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
    );
  }, [filter, documentTypeSettingsTemplate]);

  const {
    data,
    loading: documentTypeSettingsLoading,
    refetch,
  } = useGetSettingValue({
    setting: Setting.DocumentTypesTemplates,
  });

  const [template_settings, user_settings] = partition(data, "is_template");

  const { data: documentTypesData, loading: documentTypesLoading } =
    useGetDocumentTypes();

  const currentSettingTemplate = useMemo(() => {
    if (template_settings.length === 0) return;

    const documentTypeSettingTemplateId = get(user_settings, "0.value");
    const settingTemplate = template_settings.find(
      (st: any) => st.id === documentTypeSettingTemplateId
    );
    return settingTemplate;
  }, [template_settings, user_settings]);

  const isChangedDocumentTypesTemplates = useMemo(() => {
    const currentSettingTemplateItems = get(
      currentSettingTemplate,
      "value",
      []
    );
    for (let i = 0; i < currentSettingTemplateItems.length; i++) {
      const currentT = currentSettingTemplateItems[i];
      const newT = documentTypeSettingsTemplate.find(
        (newT: any) => currentT.name === newT.name
      );
      if (
        !newT ||
        currentT.pos !== newT.pos ||
        currentT.visibility !== newT.visibility
      ) {
        return true;
      }
    }
    return false;
  }, [currentSettingTemplate, documentTypeSettingsTemplate]);

  useEffect(() => {
    const documentTypeSettingsTemplateValue = currentSettingTemplate
      ? currentSettingTemplate.value
      : [];
    let maxDocumentTypeSettingsTemplatePosition =
      documentTypeSettingsTemplateValue.reduce((acc: any, b: any) => {
        return Math.max(acc, b.pos);
      }, 0);

    const newDocumentTypeSettingsTemplate = documentTypesData
      .map((docType: any, i: number) => {
        const userDocumentTypeSetting = documentTypeSettingsTemplateValue.find(
          (docTypePos: any) => docTypePos.name === docType.name
        );
        const pos = userDocumentTypeSetting
          ? userDocumentTypeSetting.pos
          : ++maxDocumentTypeSettingsTemplatePosition;
        return {
          ...docType,
          pos,
          visibility: get(userDocumentTypeSetting, "visibility", true),
        };
      })
      .sort((el1: any, el2: any) => el1.pos - el2.pos);

    //@ts-ignore
    setDocumentTypeSettingsTemplate(newDocumentTypeSettingsTemplate);
  }, [documentTypesData, currentSettingTemplate]);

  const [createSettingValue] = useMutation(CREATE_SETTING_VALUE);
  const [updateSettingValue] = useMutation(UPDATE_SETTING_VALUE);
  const [updateSettingValueById] = useMutation(UPDATE_SETTING_VALUE_BY_ID);

  const handleChangeCurrentDocumentTypeTemplate = useCallback(
    (currentDocumentTypeTemplateId: any) => {
      if (user_settings.length) {
        updateSettingValue({
          variables: {
            value: currentDocumentTypeTemplateId || "{}",
            reference_id: user && user.userId,
            reference_type_id: ReferenceType.UserId,
            setting_id: Setting.DocumentTypesTemplates,
            is_template: false,
          },
        })
          .then(() => refetch())
          .catch((e) => {
            enqueueSnackbar(t("common:serverError"), { variant: "error" });
          });
      } else {
        createSettingValue({
          variables: {
            setting_value: {
              creation_user: user && user.userId,
              setting_id: Setting.DocumentTypesTemplates,
              reference_type_id: "user_id",
              reference_id: user && user.userId,
              display_name: "user_setting",
              value: currentDocumentTypeTemplateId?.id,
              is_template: false,
            },
          },
        })
          .then(() => refetch())
          .catch((e) => {
            enqueueSnackbar(t("common:serverError"), { variant: "error" });
          });
      }
    },
    [
      t,
      enqueueSnackbar,
      user_settings.length,
      updateSettingValue,
      user,
      createSettingValue,
      refetch,
    ]
  );

  const handleSaveDocumentTypesTemplate = useCallback(
    (createMode: boolean, value: any) => {
      const refetchQueries = [
        {
          query: GET_SETTING_VALUE,
          variables: {
            setting_id: Setting.DocumentTypesTemplates,
            reference_type_id: "user_id",
            reference_id: user && user.userId,
          },
        },
      ];
      if (createMode)
        createSettingValue({
          variables: {
            setting_value: {
              creation_user: user && user.userId,
              setting_id: Setting.DocumentTypesTemplates,
              reference_type_id: "user_id",
              reference_id: user && user.userId,
              display_name: value,
              value: documentTypeSettingsTemplate.map(
                ({ name, pos, visibility }) => ({
                  name,
                  pos,
                  visibility,
                })
              ),
              is_template: true,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        })
          .then((result: any) => {
            handleChangeCurrentDocumentTypeTemplate(
              //TODO use multiple mutations m0ire
              result.data.insert_setting_value.returning[0].id
            );
          })
          .catch((e) => {
            enqueueSnackbar(t("common:serverError"), { variant: "error" });
          });
      else
        updateSettingValueById({
          variables: {
            id: value,
            is_template: true,
            reference_type_id: ReferenceType.UserId,
            setting_id: Setting.DocumentTypesTemplates,
            reference_id: user && user.userId,
            value: documentTypeSettingsTemplate.map(
              ({ name, pos, visibility }) => ({
                name,
                pos,
                visibility,
              })
            ),
          },
          refetchQueries,
          awaitRefetchQueries: true,
        })
          .then((result: any) => {
            handleChangeCurrentDocumentTypeTemplate(
              //TODO use multiple mutations m0ire
              result.data.update_setting_value.returning[0].id
            );
          })
          .catch((e) => {
            enqueueSnackbar(t("common:serverError"), { variant: "error" });
          });

      setIsOpenSaveTemplateDialog(false);
    },
    [
      t,
      enqueueSnackbar,
      documentTypeSettingsTemplate,
      createSettingValue,
      updateSettingValue,
      user,
      handleChangeCurrentDocumentTypeTemplate,
    ]
  );

  const [deleteSettingTemplate] = useMutation(DELETE_SETTING_VALUE);
  const handleDeleteDocumentTypeSettingsTemplate = useCallback(
    (documentTypeSettingsTemplateId: string) => {
      deleteSettingTemplate({
        variables: {
          id: documentTypeSettingsTemplateId,
        },
        refetchQueries: [
          {
            query: GET_SETTING_VALUE,
            variables: {
              setting_id: Setting.DocumentTypesTemplates,
              reference_type_id: "user_id",
              reference_id: user && user.userId,
            },
          },
        ],
      });
      if (
        documentTypeSettingsTemplateId ===
        get(user_settings, "0.value", undefined)
      ) {
        updateSettingValue({
          variables: {
            value: "{}",
            reference_id: user && user.userId,
            reference_type_id: ReferenceType.UserId,
            setting_id: Setting.DocumentTypesTemplates,
            is_template: false,
          },
        });
      }
    },
    [deleteSettingTemplate, updateSettingValue, user, user_settings]
  );

  const handleChangeDocumentTypesSettingPos = useCallback(
    (currentPos: number, newPos: number, e?: any) => {
      if (currentPos === newPos) return;
      if (
        (currentPos === 1 && newPos < 1) ||
        (currentPos === documentTypeSettingsTemplate.length &&
          newPos > documentTypeSettingsTemplate.length) ||
        !Number.isInteger(newPos)
      ) {
        e.target.innerText = currentPos;
        return;
      }

      const newDocumentTypeSettingsTemplate = reorder(
        documentTypeSettingsTemplate,
        currentPos - 1,
        newPos - 1
      ).map((el: any, i) => ({
        ...el,
        pos: i + 1,
      }));

      //@ts-ignore
      setDocumentTypeSettingsTemplate(newDocumentTypeSettingsTemplate);
    },
    [documentTypeSettingsTemplate]
  );

  const handleChangeDocumentTypesVisibility = (docTypeName: string) => {
    //@ts-ignore
    setDocumentTypeSettingsTemplate((prev: any) =>
      prev.map((el: any) =>
        el.name === docTypeName ? { ...el, visibility: !el.visibility } : el
      )
    );
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    handleChangeDocumentTypesSettingPos(
      filteredDocumentTypeSettingsTemplate[result.source.index].pos,
      filteredDocumentTypeSettingsTemplate[result.destination.index].pos
    );
  };

  return (
    <PageContainer title={t("navigation:classifier-templates")}>
      <Grid className={classes.root} item container direction="column">
        {documentTypesLoading || documentTypeSettingsLoading ? (
          <Loader />
        ) : (
          <>
            {isOpenSaveTemplateDialog ? (
              <SaveTemplateDialog
                onClose={() => setIsOpenSaveTemplateDialog(false)}
                onSave={handleSaveDocumentTypesTemplate}
                onDelete={handleDeleteDocumentTypeSettingsTemplate}
                templates={template_settings}
                selectedTemplate={currentSettingTemplate}
              />
            ) : null}
            <ActionBar className={classes.actionBar}>
              <ActionBarItem
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TemplateSelect
                  options={template_settings}
                  value={
                    isChangedDocumentTypesTemplates
                      ? {
                          ...currentSettingTemplate,
                          display_name: currentSettingTemplate
                            ? currentSettingTemplate.display_name + " *"
                            : "",
                        }
                      : currentSettingTemplate
                  }
                  onChange={(documentTypeTemplate: any) => {
                    handleChangeCurrentDocumentTypeTemplate(
                      documentTypeTemplate && documentTypeTemplate.id
                    );
                  }}
                  onDelete={handleDeleteDocumentTypeSettingsTemplate}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setIsOpenSaveTemplateDialog(true)}
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {t("common:save")}
                </Button>
              </ActionBarItem>
              <ActionBarItem>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item>
                    <Search
                      label={t("common:search") + "..."}
                      onChange={setFilter}
                    />
                  </Grid>
                </Grid>
              </ActionBarItem>
            </ActionBar>
            <Paper
              variant="outlined"
              style={{
                marginTop: 15,
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided: any) => (
                    <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                      <MuiTable
                        className={classes.table}
                        ref={provided.innerRef}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <Text className={classes.tableHeader}>
                                common:pos
                              </Text>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <Text className={classes.tableHeader}>
                                common:color
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text className={classes.tableHeader}>
                                common:documentType
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text className={classes.tableHeader}>
                                common:code
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text className={classes.tableHeader}>
                                common:last_modified
                              </Text>
                            </TableCell>
                            <TableCell align="center">
                              <Text className={classes.tableHeader}>
                                common:visibility
                              </Text>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredDocumentTypeSettingsTemplate.map(
                            (filteredDocType, i) => (
                              <Draggable
                                key={filteredDocType.name}
                                draggableId={filteredDocType.name}
                                index={i}
                              >
                                {(provided: any, snapshot: any) => (
                                  <TableRow
                                    key={filteredDocType.name}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <TableCell
                                      className={classes.tableCell}
                                      align="center"
                                      style={{ width: "50px" }}
                                    >
                                      <DragHandleIcon
                                        style={{ color: "#757575" }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      align="center"
                                      style={{ width: "50px" }}
                                    >
                                      <div
                                        className={classes.tableCellContent}
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        onBlur={(e: any) => {
                                          const currentPos =
                                            filteredDocType.pos;
                                          const newPos = parseInt(
                                            e.target.innerText
                                          );
                                          handleChangeDocumentTypesSettingPos(
                                            currentPos,
                                            newPos,
                                            e
                                          );
                                        }}
                                        onKeyPress={(event: any) => {
                                          if (event.charCode === 13) {
                                            //@ts-ignore
                                            document.activeElement.blur();
                                          }
                                        }}
                                        style={{
                                          padding: "10px",
                                          backgroundColor: "#f6f6f6",
                                          borderRadius: "10%",
                                          fontWeight: 500,
                                          cursor: "text",
                                          minWidth: 40,
                                          display: "inline-block",
                                        }}
                                      >
                                        {filteredDocType.pos}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      align="center"
                                      style={{ width: "75px" }}
                                    >
                                      <Colored
                                        color={
                                          filteredDocType.meta?.color ||
                                          "#ffffff"
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{ width: "350px" }}
                                    >
                                      <Text
                                        className={classes.tableCellContent}
                                      >
                                        {filteredDocType.translated_name}
                                      </Text>
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{ width: "100px" }}
                                    >
                                      <span
                                        className={classes.tableCellContent}
                                      >
                                        {filteredDocType.code}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{ width: "250px" }}
                                    >
                                      <span
                                        className={classes.tableCellContent}
                                      >
                                        {filteredDocType.last_modified_date &&
                                          dayjs(
                                            filteredDocType.last_modified_date
                                          ).format("DD.MM.YYYY HH:mm:ss")}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      align="center"
                                      style={{ width: "75px" }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleChangeDocumentTypesVisibility(
                                            filteredDocType.name
                                          )
                                        }
                                        size="large"
                                      >
                                        {filteredDocType.visibility ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </TableBody>
                      </MuiTable>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          </>
        )}
      </Grid>
    </PageContainer>
  );
};

export default DocumentTypeTemplates;
