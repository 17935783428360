import { useCallback, useMemo } from "react";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default () => {
  const [mutation, options] = useMutation(gql`
    mutation AditUpdateDeclarationAiConfirmed(
      $file_id: uuid!
      $dr_no: smallint!
      $ai_confirmed: Boolean!
    ) {
      update_declaration_by_pk(
        pk_columns: { file_id: $file_id, dr_no: $dr_no }
        _set: { ai_confirmed: $ai_confirmed }
      ) {
        file_id
        dr_no
      }
    }
  `);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        useCallback(
          async (file_id: string, dr_no: number, ai_confirmed: boolean) => {
            await mutation({
              variables: { file_id, dr_no, ai_confirmed },
            });
            enqueueSnackbar(t("Saved"), { variant: "success" });
          },
          []
        ),
        options,
      ] as const,
    [mutation, t, options]
  );
};
