import React, { FC } from "react";
import {
  useLayoutContext,
  Theme,
  Popper,
  useMediaQuery,
  Paper,
  Collapse,
  Box,
} from "@periplus/ui-library";
import { NavigationGroup as NavigationGroupType } from "../Layout";
import NavigationItem from "./NavigationItem";
import { Typography } from "@periplus/ui-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

interface NavigationGroupProps extends NavigationGroupType {}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&:not(:first-of-type)": {
      marginTop: 14,
    },
  },
  titleContainer: {
    padding: "8px 23px 8px 24px",
    display: "flex",
    alignItems: "center",
    "&:hover *": {
      color: "#604DFF",
      fill: "#604DFF",
    },
    cursor: "pointer",
  },
  title: {
    marginLeft: 8,
    fontSize: 16,
  },
  flyOutMenu: {
    zIndex: 1200,
  },
  flyOutMenuPaper: {
    padding: "6px 16px",
  },
  contentContainer: {
    marginTop: 4,
    paddingLeft: 56,
    paddingRight: 24,
  },
  contentContainerHidden: {
    display: "none",
  },
  icon: {
    color: "#1D1D4B",
  },
  active: {
    "& > *": {
      color: "#604DFF",
      fill: "#604DFF",
      fontWeight: 500,
    },
  },
}));

const NavigationGroup: FC<NavigationGroupProps> = ({
  groupTitle,
  items,
  Icon,
  redirecting,
  indicator,
}) => {
  const { classes, cx } = useStyles();
  const { layoutState, layoutActions } = useLayoutContext();
  const history = useHistory();
  const isUpDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const [poperAnchorEl, setPoperAnchorEl] =
    React.useState<HTMLDivElement | null>(null);

  const firstItemUrl = redirecting || items?.[0]?.redirecting;

  const match = useRouteMatch({
    path: firstItemUrl,
  });

  if (!firstItemUrl) {
    return null;
  }

  const isActive =
    redirecting
      ?.split("/")
      .every(
        (pathPart, index) =>
          pathPart === history.location.pathname.split("/")[index]
      ) ||
    items?.some((el) => el.redirecting === history.location.pathname) ||
    match;

  return (
    <div className={classes.root}>
      <div
        className={cx(classes.titleContainer, {
          [classes.active]: isActive,
        })}
        onClick={() => {
          if (firstItemUrl.includes("http")) {
            window.open(firstItemUrl, "_blank");
          } else if (!match) {
            history.push(firstItemUrl);
          }
          if (!isUpDesktop) {
            layoutActions.setSidebarOpen(false);
          }
        }}
        onMouseEnter={(e: any) => {
          setPoperAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setPoperAnchorEl(null);
        }}
      >
        <Icon className={classes.icon} />
        {(!isUpDesktop || (isUpDesktop && layoutState.sidebarOpen)) && (
          <Typography
            className={classes.title}
            variant={isActive ? "subtitle1" : "body1"}
          >
            {groupTitle}
          </Typography>
        )}
        {Boolean(indicator) && layoutState.sidebarOpen && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 18,
              height: 18,
              borderRadius: "50%",
              backgroundColor: "#604DFF",
              marginLeft: 1,
            }}
          >
            <Typography
              sx={{
                color: "white!important",
                fontSize: 11,
              }}
            >
              {indicator}
            </Typography>
          </Box>
        )}
        {!!items?.length && (
          <Popper
            open={isUpDesktop && !layoutState.sidebarOpen && !!poperAnchorEl}
            anchorEl={poperAnchorEl}
            placement="right-start"
            className={classes.flyOutMenu}
          >
            <Paper
              className={classes.flyOutMenuPaper}
              square
              variant="outlined"
            >
              {items.map((item) => (
                <NavigationItem {...item} key={item.text} />
              ))}
            </Paper>
          </Popper>
        )}
      </div>
      {!!items?.length && (
        <Collapse
          in={!isUpDesktop || (isUpDesktop && layoutState.sidebarOpen)}
          classes={{
            hidden: classes.contentContainerHidden,
          }}
        >
          <div className={classes.contentContainer}>
            {items.map((item) => (
              <NavigationItem {...item} key={item.text} />
            ))}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default NavigationGroup;
