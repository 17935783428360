import React, { FC } from "react";
import { Box, BoxProps, Typography } from "@periplus/ui-library";
import { useSasToken } from "utils/azureBlobStorageUtils";
import { DeclarationDocumentTableEntity } from "../hooks/useGetDeclarationDocumentsTable";

interface SmallThumbnailProps extends BoxProps {
  pages: DeclarationDocumentTableEntity["pages"];
  extension: DeclarationDocumentTableEntity["documentExtension"];
  onClick: () => void;
}

const SmallThumbnail: FC<SmallThumbnailProps> = ({
  pages,
  extension,
  onClick,
}) => {
  const thumbnailsSasToken = useSasToken({ container: "thumbs" });

  if (extension === "xml") {
    return (
      <Box
        sx={(theme) => ({
          width: 57,
          height: 68,
          borderRadius: "4px",
          border: 1,
          borderColor: theme.palette.grey3.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Typography color="textSecondary">XML</Typography>
      </Box>
    );
  }

  const firstPage = pages[0];

  return (
    <Box
      onClick={onClick}
      component="img"
      sx={(theme) => ({
        width: 57,
        height: 68,
        borderRadius: "4px",
        border: 1,
        borderColor: theme.palette.grey3.main,
        transform: `rotate(${firstPage.rotate}deg)`,
        cursor: "pointer",
      })}
      src={`${firstPage.thumbnailSmallUrl}?${thumbnailsSasToken}`}
      alt="page"
    />
  );
};

export default SmallThumbnail;
