import { gql } from "@apollo/client";
import {
  AditGetOrgIdsListQuery,
  useAditGetOrgIdsListLazyQuery,
  useAditGetOrgIdsListQuery,
  useAditGetUserOrgIdsListQuery,
} from "graphql/generated";

export type OrgIdListEntity = AditGetOrgIdsListQuery["orgIds"][number];

gql`
  query AditGetOrgIdsList($ids: [Int!]) {
    orgIds: getOrgIds(ids: $ids) {
      ...OrgIdsListFragment
    }
  }

  query AditGetUserOrgIdsList {
    userOrgIds: getUserOrgIds {
      ...OrgIdsListFragment
    }
  }

  fragment OrgIdsListFragment on OrgIdEntity {
    orgId
    orgIdDescription
  }
`;

export const useGetOrgIdsListLazy = useAditGetOrgIdsListLazyQuery;
export const useGetOrgIdsList = useAditGetOrgIdsListQuery;

export const useGetUserOrgIdsList = useAditGetUserOrgIdsListQuery;
