import React, { FC } from "react";
import { Box, BoxProps } from "@periplus/ui-library";
import { AiConfirmedDeclarationEntity } from "../useGetAiConfirmedDeclarations";
import { useSasToken } from "utils/azureBlobStorageUtils";

interface SmallThumbnailProps extends BoxProps {
  page: NonNullable<
    NonNullable<
      AiConfirmedDeclarationEntity["declaration_request"]
    >["documents"][number]["pages_m2m"][number]["page"]
  >;
  onClick: () => void;
}

const SmallThumbnail: FC<SmallThumbnailProps> = ({ page, onClick }) => {
  const thumbnailsSasToken = useSasToken({ container: "thumbs" });

  return (
    <Box
      onClick={onClick}
      component="img"
      sx={(theme) => ({
        height: "5cm",
        borderRadius: "4px",
        border: 1,
        borderColor: theme.palette.grey3.main,
        transform: `rotate(${page.rotate}deg)`,
        cursor: "pointer",
      })}
      src={`${page.thumbnail_big_url}?${thumbnailsSasToken}`}
      alt="page"
    />
  );
};

export default SmallThumbnail;
