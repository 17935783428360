import Entity, { EntityDependencies } from "domain/Entity";
import {
  DocumentTypeDB,
  DocumentTypeTenantSettingDB,
} from "domain/documentType/types";
import { bitmaskToArray } from "utils/bitmask";
import {
  DocumentTypeFlag,
  DocumentTypeFlagDB,
} from "domain/documentTypeFlag/DocumentTypeFlag";
import {
  TenantDocumentFlagTableEntity,
  TenantDocumentTypeTableEntity,
} from "./useGetTenantDocumentTypes";

export default class TenantDocumentType extends Entity {
  tenant_id: string;
  document_type: DocumentTypeDB["name"];
  name: string;
  creation_date: DocumentTypeTenantSettingDB["creation_date"];
  creation_user: DocumentTypeTenantSettingDB["creation_user"];
  last_modified_date: DocumentTypeTenantSettingDB["last_modified_date"];
  last_modified_user: DocumentTypeTenantSettingDB["last_modified_user"];
  code: DocumentTypeTenantSettingDB["code"];
  meta: { color: string; labels: string[] };
  defaultFlags: DocumentTypeFlag[];

  constructor(
    obj: {
      tenant_id: string;
      document_type: TenantDocumentTypeTableEntity;
      document_type_flags: TenantDocumentFlagTableEntity[];
    },
    dep: EntityDependencies
  ) {
    super(dep);

    this.tenant_id = obj.tenant_id;
    this.document_type = obj.document_type.name;
    this.name =
      obj.document_type.translations[this._dep.language] || this.document_type;

    const tenantDocumentType =
      obj.document_type.document_type_tenant_settings.find(
        (dtts) => dtts.tenant === obj.tenant_id
      );
    this.creation_date = tenantDocumentType?.creation_date;
    this.creation_user = tenantDocumentType?.creation_user;
    this.last_modified_date = tenantDocumentType?.last_modified_date;
    this.last_modified_user = tenantDocumentType?.last_modified_user;
    this.code = tenantDocumentType?.code ?? undefined;
    this.meta = tenantDocumentType?.meta
      ? {
          ...tenantDocumentType.meta,
          color: tenantDocumentType.meta.color || "#ffffff",
          labels: tenantDocumentType.meta.labels || [],
        }
      : {
          color: "#ffffff",
          labels: [],
        };
    this.defaultFlags = tenantDocumentType?.default_flags
      ? bitmaskToArray(tenantDocumentType.default_flags).map(
          (default_flag) =>
            new DocumentTypeFlag(
              obj.document_type_flags.find(
                (dtf) => dtf.id === default_flag
              ) as DocumentTypeFlagDB,
              dep
            )
        )
      : [];
  }

  toDBObject(): DocumentTypeTenantSettingDB {
    return {
      tenant: this.tenant_id,
      document_type: this.document_type,
      code: this.code,
      meta: this.meta,
      default_flags: this.defaultFlags?.reduce(
        (acc, defaultFlag) => acc + defaultFlag.id,
        0
      ),
    };
  }
}
