import React, { FC } from "react";
import { Loader } from "@periplus/ui-library";
import { RouteChildrenProps } from "react-router";
import { Switch, Redirect } from "react-router-dom";
import InsertDriveFile from "@mui/icons-material/InsertDriveFileOutlined";
import { get } from "lodash";
import { Permission } from "rbac-rules";
import { HARDCODED_TENANTS } from "domain/tenant/type";
import { useAuth, Role } from "contexts/Auth/AuthContext";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import FileUpload from "components/FileUpload";
import Documents from "./Documents";
import Emails from "./Emails";
import Dashboard from "./Dashboard";
import Finished from "./Finished";
import Preview from "./Preview";
import FileHistory from "./FileHistory";
import Classify from "./Classify";
import WeDeclare from "./WeDeclare";
import DeclarationNew from "./DeclarationNew";
import { useTranslation } from "react-i18next";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { CustomsIcon, TaskIcon } from "assets/Icons";
import Tasks from "./Tasks";
import Layout from "components/Layout";
import { NavigationGroup } from "components/Layout/Layout";

const renderFileUpload = (open: boolean) => <FileUpload minimalMode={!open} />;

const SelectRoutes: FC<RouteChildrenProps> = ({ match }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  if (!user || !match) {
    return <Loader />;
  }

  const translate = (text: string) => t(`navigation:${text}`);

  const menuItems: NavigationGroup[] = [
    {
      groupTitle: translate("documents"),
      Icon: InsertDriveFile,
      items: [
        {
          text: translate("uploads"),
          redirecting: "/select/documents",
        },
        {
          text: translate("emails"),
          redirecting: "/select/emails",
        },
      ],
    },
    {
      groupTitle: translate("dossiers"),
      Icon: FolderOpenOutlinedIcon,
      items: [
        {
          text: translate("files-voyages"),
          redirecting: `/select/files-voyages`,
        },
      ],
    },
    ...(user &&
    user.tenantId === HARDCODED_TENANTS.mnr &&
    user?.allowedRoles?.includes(Role.CustomsBroker)
      ? [
          {
            groupTitle: translate("WeDeclare"),
            Icon: CustomsIcon,
            redirecting: `/select/we-declare`,
          },
        ]
      : []),
    {
      groupTitle: translate("statistics"),
      Icon: InsertChartOutlinedIcon,
      redirecting: `/select/statistics`,
    },
    ...(user?.hasAllowedRoles([Role.CustomsBroker])
      ? [
          {
            groupTitle: translate("tasks"),
            Icon: TaskIcon,
            redirecting: `/select/tasks`,
          },
        ]
      : []),
    {
      groupTitle: translate("help"),
      Icon: InsertDriveFile,
      items: [
        {
          text: translate("giveUsFeedback"),
          redirecting:
            "https://forms.office.com/Pages/ResponsePage.aspx?id=wGlTb2c3lkqi4PtLPBP3RN1PCF4b_MJItBSspwVBZP9URjFCVTRRWDNPV1JGSU0xRzBBSUpFVjhKSS4u",
        },
      ],
    },
  ];

  return (
    <Layout navigation={menuItems} renderActions={renderFileUpload}>
      <Switch>
        <PrivateRoute
          path="/select"
          exact
          component={() => (
            <Redirect to={get(menuItems, "[0].items[0].redirecting")} />
          )}
        />
        <PrivateRoute
          exact
          path={`${match.path}/documents`}
          component={Documents}
        />
        <PrivateRoute exact path={`${match.path}/emails`} component={Emails} />
        <PrivateRoute
          path={`${match.path}/files-voyages`}
          component={Finished}
        />
        <PrivateRoute
          path={`${match.path}/we-declare`}
          component={WeDeclare}
          permission={Permission.DECLARATION_VIEW__NAVIGATE}
        />
        <PrivateRoute
          path={`${match.path}/declaration/:file_id/:dr_no`}
          component={DeclarationNew}
          permission={Permission.DECLARATION_CREATE__NAVIGATE}
        />
        <PrivateRoute path={`${match.path}/preview/:id`} component={Preview} />
        <PrivateRoute
          path={`${match.path}/history/:id`}
          component={FileHistory}
        />
        <PrivateRoute
          path={`${match.path}/classification`}
          component={Classify}
        />
        <PrivateRoute path={`${match.path}/statistics`} component={Dashboard} />
        {user.hasAllowedRoles([Role.CustomsBroker]) && (
          <PrivateRoute path={`${match.path}/tasks`} component={Tasks} />
        )}
      </Switch>
    </Layout>
  );
};

export default SelectRoutes;
