import React, { useCallback, useMemo } from "react";
import { Formik, FormikConfig } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useCreateTenant, { CreateTenantVariables } from "./useCreateTenant";
import useUpdateTenant from "./useUpdateTenant";
import { TenantTableEntity } from "../../hooks/useGetTenantsTable";
import { Nullable } from "utils/utilityTypes";

export type TenantFormEntity = Nullable<
  Pick<
    TenantTableEntity,
    "tenantName" | "externalTenantId" | "address" | "roles"
  >,
  "address"
>;

const defaultValues: TenantFormEntity = {
  tenantName: "",
  externalTenantId: "",
  address: null,
  roles: [],
};

interface TenantFormikProps {
  tenant?: TenantTableEntity;
  onSubmit: () => Promise<any>;
  children: FormikConfig<TenantFormEntity>["children"];
}

export default ({ children, onSubmit, tenant }: TenantFormikProps) => {
  const { t } = useTranslation();
  const [create] = useCreateTenant();
  const [update] = useUpdateTenant();

  const initialValues = useMemo<TenantFormEntity>(
    () => ({
      tenantName: tenant?.tenantName ?? defaultValues.tenantName,
      externalTenantId:
        tenant?.externalTenantId ?? defaultValues.externalTenantId,
      address: tenant?.address ?? defaultValues.address,
      roles: tenant?.roles ?? defaultValues.roles,
    }),
    [tenant]
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        tenantName: yup.string().trim().required().max(50),
        externalTenantId: yup.string().uuid(t("Must be a valid UUID")).trim(),
        address: yup.object().nullable().required(),
        roles: yup.array(),
      }),
    [t]
  );

  const handleSubmit = useCallback<FormikConfig<TenantFormEntity>["onSubmit"]>(
    (values, { setSubmitting, resetForm }) => {
      const castedValues = validationSchema.cast(values) as TenantFormEntity;
      const variables: CreateTenantVariables = {
        tenantName: castedValues.tenantName,
        externalTenantId: castedValues.externalTenantId || null,
        addressId: castedValues.address!.id,
        roles: castedValues.roles!.map((el) => el.id),
      };

      const onSuccess = () => {
        setSubmitting(false);
        onSubmit().then(() => {
          resetForm();
        });
      };
      const onError = () => {
        setSubmitting(false);
      };

      if (tenant) {
        update({
          variables: {
            tenantId: tenant.id,
            ...variables,
          },
        })
          .then(onSuccess)
          .catch(onError);
      } else {
        create({
          variables,
        })
          .then(onSuccess)
          .catch(onError);
      }
    },
    [tenant, create, update]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};
