import gql from "graphql-tag";
import { useAditUpdateTenantUserMutation } from "graphql/generated";

gql`
  mutation aditUpdateTenantUser($object: AditUserDto!) {
    updateAditUser(updateUserInput: $object)
  }
`;

export default useAditUpdateTenantUserMutation;
