import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import "react-resizable/css/styles.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import packageJson from "../package.json";
import "typeface-rubik";
import "typeface-open-sans";

//localStorage version
if (localStorage.getItem("version") !== "10.13.2023") {
  localStorage.clear();
  localStorage.setItem("version", "10.13.2023");
}

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(<App />);

// Initialize Sentry, but only for production!
if ("production" === process.env.REACT_APP_ENVIRONMENT) {
  Sentry.init({
    dsn:
      process.env.REACT_APP_SENTRY_URL ||
      "https://c3ca4bfabed94e659eb208fecc48e19d@sentry.io/1800819",
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: `${packageJson.name}@${packageJson.version}`,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

//  If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
