import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import TenantDocumentType from "./TenantDocumentType";
import {
  AditGetTenantDocumentTypesTableQuery,
  useAditGetTenantDocumentTypesTableQuery,
} from "graphql/generated";

export type TenantDocumentTypeTableEntity =
  AditGetTenantDocumentTypesTableQuery["document_type"][number];
export type TenantDocumentFlagTableEntity =
  AditGetTenantDocumentTypesTableQuery["document_flag"][number];

gql`
  query AditGetTenantDocumentTypesTable(
    $tenantId: uuid!
    $where: document_type_bool_exp!
  ) {
    document_type(order_by: { creation_date: asc }, where: $where) {
      document_type_tenant_settings(where: { tenant: { _eq: $tenantId } }) {
        tenant
        code
        default_flags
        meta
        creation_date
        creation_user
        last_modified_date
        last_modified_user
      }
      name
      display_name
      translations
    }
    document_flag {
      id
      name
      translation
      color
    }
  }
`;

const useGetTenantDocumentTypes = ({ tenantId }: { tenantId: string }) => {
  const { data, ...rest } = useAditGetTenantDocumentTypesTableQuery({
    variables: {
      tenantId,
      where: {
        document_type_tenant_settings: { code: { _is_null: false } },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    i18n: { language },
  } = useTranslation();

  const { tenantDocumentTypes } = useMemo(() => {
    return {
      tenantDocumentTypes:
        data?.document_type?.map(
          (dbDocumentType) =>
            new TenantDocumentType(
              {
                tenant_id: tenantId,
                document_type: dbDocumentType,
                document_type_flags: data.document_flag,
              },
              { language }
            )
        ) || [],
    };
  }, [data, language, tenantId]);

  return {
    tenantDocumentTypes,
    ...rest,
  };
};

export default useGetTenantDocumentTypes;
