import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Theme,
  Tooltip,
  Box,
} from "@periplus/ui-library";
import { CustomsIcon, BotIcon } from "assets/Icons";
import Colored from "components/Colored";
import { DeleteOutline } from "@mui/icons-material";
import { DocumentTypeFlag } from "domain/documentType/types";
import { useTranslation } from "react-i18next";
import { useAuth, Role } from "contexts/Auth/AuthContext";
import { makeStyles } from "tss-react/mui";

interface IListItemFProps {
  withColor: boolean;
  document_type: string;
  triggerDelete(
    fileNumber: string,
    document?: {
      id: string;
      document_type: string;
    }
  ): void;
  fileNumber: string;
  onDelete?(
    fileNumber: string,
    document?: {
      id: string;
      document_type: string;
    }
  ): void;
  isSelected: boolean;
  sequenceIndex: number;
  docType: any;
  withoutPages: boolean;
  provided?: any;
  defaultCursor: string;
  id: string;
  onItemClick?(): void;
  onToggleCustoms?(doc: any, toggleStatus: boolean): void;
  doc: any;
  isCustomRelevant: boolean;
  isAIRequested: boolean;
  onToggleAIRequest?(doc: any, toggleStatus: boolean): void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  smallWidth: {
    minWidth: 30,
  },

  iconButton: {},

  icon: {
    color: "#9e9e9e",
    fontSize: 18,
  },

  listItem: {
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.4),
    "&:hover": {
      backgroundColor: "#0001",
    },
  },

  listItemText: {
    marginLeft: theme.spacing(1),
  },

  listItemActive: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0001",
    },
  },

  fileDraggingOver: {
    background: "rgb(0 0 0 / 4%)",
  },

  listItemDisabled: {
    opacity: 0.5,
  },

  listItemIcon: {
    width: 24,
    height: 24,
    fill: "lightgray",
    "& path": {
      fill: "lightgray",
    },
    cursor: "pointer",
  },

  listItemIconActive: {
    fill: "black",
    "& path": {
      fill: "black",
    },
  },

  listItemIconDisabled: {
    cursor: "default",
  },

  listItemMain: {
    display: "flex",
    gap: 4,
  },
}));

const FileItem = ({
  withColor,
  document_type,
  triggerDelete,
  fileNumber,
  onDelete,
  isSelected,
  sequenceIndex,
  docType,
  withoutPages,
  provided,
  defaultCursor,
  id,
  onItemClick,
  onToggleCustoms,
  doc,
  isCustomRelevant,
  isAIRequested,
  onToggleAIRequest,
}: IListItemFProps) => {
  const { user } = useAuth();
  const {
    classes: {
      smallWidth,
      listItem,
      listItemDisabled,
      listItemActive,
      listItemText,
      listItemIcon,
      listItemIconActive,
      listItemIconDisabled,
      listItemMain,
      iconButton,
      icon,
    },
    cx,
  } = useStyles();
  const { t } = useTranslation();

  const isBackOfficeRelevant =
    (docType.default_flags & DocumentTypeFlag.backoffice_relevant) ===
    DocumentTypeFlag.backoffice_relevant;

  return (
    <ListItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={cx(defaultCursor, listItemMain)}
      key={id}
      tabIndex={0}
      onClick={onItemClick}
      sx={{ px: 1 }}
    >
      <Box
        sx={{
          minWidth: 60,
          maxWidth: 60,
          display: "flex",
        }}
      >
        {isBackOfficeRelevant &&
          user?.hasAllowedRoles([Role.CustomsBroker]) && (
            <ListItemIcon className={smallWidth}>
              <Tooltip
                title={t("classify:Create task for validation") as string}
              >
                <BotIcon
                  onClick={() => onToggleAIRequest?.(doc, isCustomRelevant)}
                  className={cx(listItemIcon, {
                    [listItemIconActive]: isAIRequested,
                    [listItemIconDisabled]: !onToggleAIRequest,
                  })}
                />
              </Tooltip>
            </ListItemIcon>
          )}
        <ListItemIcon className={smallWidth} sx={{ ml: "auto" }}>
          <Tooltip title={t("classify:Customs relevant") as string}>
            <CustomsIcon
              onClick={() =>
                onToggleCustoms && onToggleCustoms(doc, isCustomRelevant)
              }
              className={cx(listItemIcon, {
                [listItemIconActive]: isCustomRelevant,
                [listItemIconDisabled]: !onToggleCustoms,
              })}
            />
          </Tooltip>
        </ListItemIcon>
      </Box>
      <ListItemText
        className={`${listItem} ${
          withColor ? (withoutPages ? listItemDisabled : listItemActive) : null
        }`}
        title={docType && docType.translated_name}
      >
        <div
          style={{
            display: "flex",
            fontSize: 14,
          }}
        >
          {withColor ? (
            <Colored color={docType && docType.meta.color}>
              {sequenceIndex}
            </Colored>
          ) : (
            `${sequenceIndex}. `
          )}
          <div
            className={listItemText}
            style={{
              textDecoration: isSelected ? "underline" : "none",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {docType && docType.translated_name}
          </div>
        </div>
      </ListItemText>
      {onDelete && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            triggerDelete(fileNumber, {
              id,
              document_type,
            });
          }}
          className={iconButton}
          tooltip={t("Delete")}
        >
          <DeleteOutline className={icon} />
        </IconButton>
      )}
    </ListItem>
  );
};

export default FileItem;
