import { gql } from "@apollo/client";
import { useAditUpdateTenantMutation } from "graphql/generated";

gql`
  mutation AditUpdateTenant(
    $tenantId: String!
    $addressId: Int!
    $externalTenantId: String
    $roles: [Int!]
    $tenantName: String!
  ) {
    editTenantACS(
      tenantId: $tenantId
      addressId: $addressId
      externalTenantId: $externalTenantId
      roles: $roles
      tenantName: $tenantName
    )
  }
`;

export default useAditUpdateTenantMutation;
