import {
  AditGetAddressesListQuery,
  AditGetAddressesListQueryVariables,
  useAditGetAddressesListLazyQuery,
  useAditGetAddressesListQuery,
} from "graphql/generated";
import { useMemo } from "react";

export type AddressListEntity = NonNullable<
  AditGetAddressesListQuery["addresses"]
>[number];

export const getAddressesQueryVariables = ({
  search,
  ...rest
}: AditGetAddressesListQueryVariables) => ({
  ...(search && { search }),
  ...rest,
});

export const useGetAddressesLazy = (
  baseOptions?: Parameters<typeof useAditGetAddressesListLazyQuery>[0]
) => {
  const [query, result] = useAditGetAddressesListLazyQuery(baseOptions);

  return useMemo(() => {
    return [
      query,
      {
        ...result,
        data: {
          addresses: result.data?.addresses ?? [],
        },
      },
    ] as const;
  }, [result, query]);
};

export default (
  baseOptions?: Parameters<typeof useAditGetAddressesListQuery>[0]
) => {
  const result = useAditGetAddressesListQuery(baseOptions);

  return useMemo(() => {
    return {
      ...result,
      data: {
        addresses: result.data?.addresses ?? [],
      },
    };
  }, [result]);
};
