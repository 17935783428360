import { gql } from "@apollo/client";

export type OrgId = {
  org_id: number;
  org_id_description: string;
  active: boolean | null;
};

export interface IGetOrgIdItem {
  orgId: OrgId;
  org_id: number;
}

export interface IGetOrgIds {
  orgIds: IGetOrgIdItem[];
}

export const GET_USERS_ORGIDS = gql`
  query getUsersOrgIds($userId: _uuid) {
    orgIds: pp_user_org_id(args: { _uids: $userId }) {
      org_id
      orgId {
        org_id
        org_id_description
        active
      }
    }
  }
`;
