import gql from "graphql-tag";
import { useAditDeleteTenantUserMutation } from "graphql/generated";

gql`
  mutation AditDeleteTenantUser($id: UUID!) {
    removeUser(id: $id)
  }
`;

export default useAditDeleteTenantUserMutation;
