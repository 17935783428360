import { useMutation } from "@apollo/client";
import { INSERT_ADDRESS } from "graphql/mutations/address";

import { PartialAddress } from "graphql/hooks/useGetAddress";

const useInsertAddress = () => {
  const [insertAddress] = useMutation(INSERT_ADDRESS);

  const handleInsert = (objects: PartialAddress) => {
    return insertAddress({
      variables: { objects },
    });
  };

  return handleInsert;
};

export default useInsertAddress;
