import React, { FC, useMemo, useState } from "react";
import { BoxProps, Carousel, Paper } from "@periplus/ui-library";
import { useSasToken } from "utils/azureBlobStorageUtils";
import { DeclarationDocumentTableEntity } from "../hooks/useGetDeclarationDocumentsTable";
const { CarouselDialog } = Carousel;

interface BigThumbnailProps extends BoxProps {
  pages: DeclarationDocumentTableEntity["pages"];
  onClose: () => void;
}

const BigThumbnail: FC<BigThumbnailProps> = ({ pages, onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const thumbnailsSasToken = useSasToken({ container: "thumbs" });

  const pagesUrls = useMemo(() => {
    return pages.map((page) => {
      return {
        page_id: page.id,
        url: `${page.thumbnailBigUrl}?${thumbnailsSasToken}`,
      };
    });
  }, [pages, thumbnailsSasToken]);

  return (
    <CarouselDialog
      totalSlides={pages.length}
      currentSlide={selectedIndex + 1}
      onSlideChange={(newSlide) => setSelectedIndex(newSlide - 1)}
      onClose={onClose}
    >
      <Paper
        elevation={6}
        style={{
          transform: `rotate(${pages[selectedIndex].rotate}deg)`,
        }}
      >
        <img
          style={{ width: "100%" }}
          src={
            pagesUrls.find(
              (pageUrl) => pageUrl.page_id === pages[selectedIndex].id
            )!.url
          }
          alt="page"
        />
      </Paper>
    </CarouselDialog>
  );
};

export default BigThumbnail;
