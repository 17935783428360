import React from "react";
import { Box, FormSubmitButton } from "@periplus/ui-library";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useTranslation } from "react-i18next";
import TenantFormik from "../components/TenantForm/TenantFormik";
import { TenantTableEntity } from "../hooks/useGetTenantsTable";
import TenantForm from "../components/TenantForm/TenantForm";

interface Props {
  tenant: TenantTableEntity;
  onSave: () => Promise<any>;
}

export default ({ tenant, onSave }: Props) => {
  const { t } = useTranslation();

  return (
    <TenantFormik tenant={tenant} onSubmit={onSave}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <FormSubmitButton
          endIcon={<SaveOutlinedIcon />}
          sx={{ alignSelf: "end" }}
        >
          {t("Save")}
        </FormSubmitButton>
        <TenantForm sx={{ mt: 1 }} />
      </Box>
    </TenantFormik>
  );
};
