import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import {
  ActionBar,
  ActionBarItem,
  Switch,
  Loader,
  Button,
  Grid,
  Divider,
  PageContainer,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory } from "react-router-dom";
import get from "lodash/get";

import RotateRight from "@mui/icons-material/RotateRight";
import RotateLeft from "@mui/icons-material/RotateLeft";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import ViewColumnOutlined from "@mui/icons-material/ViewColumnOutlined";
import ViewModuleOutlined from "@mui/icons-material/ViewModuleOutlined";
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

import Text from "components/Text/Text";
import ViewLayout from "pages/Select/Classify/components/ViewLayout";
import Classifier from "./components/Classifier";
import FileTree from "./components/FileTree";
import SelectionWrapper from "pages/Select/Classify/components/SelectionWrapper";

import DocumentView, { ExpandStatus } from "./components/DocumentView";
import { FileDialog, FinishDialog } from "./components/";

import useGetDocuments from "graphql/hooks/useGetDocuments";
import useGetDocumentTypes from "domain/documentType/useGetDocumentTypes";
import { useAuth } from "contexts/Auth/AuthContext";
import useElementSelector from "pages/Select/Classify/components/useElementSelector";
import useClassifier, { Document, File } from "hooks/useClassifier";
import useToggle from "hooks/useToggle";
import DocumentSelector from "pages/Select/Classify/components/DocumentSelector";
import { Permission } from "rbac-rules";
import { accessCheck } from "components/PrivateRoute/Can";
import { useApplicationAction } from "contexts/ApplicationContext";
import useGetDeclaration from "graphql/hooks/useGetDeclaration";
import { FileType } from "graphql/hooks/useTransmitFile";
import PageImg from "./components/PageImg";
import { DeclarationState } from "./components/FileDialog";

//import useAppendDocumentMeta from "hooks/useAppendDocumentMeta";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  divider: {
    margin: "-8px 15px",
  },
  font: {
    minWidth: "auto",
    textTransform: "capitalize",
    fontWeight: 300,
  },
  boldFont: {
    fontWeight: 400,
  },
  leftSpace: { marginLeft: 15 },
  rightSpace: {
    marginRight: 5,
  },
  space: {
    marginTop: 16,
  },
  withoutWidth: {
    width: "auto",
  },
  screenHeight: {
    overflowY: "auto",
    overflow: "hidden",
    height: "calc(100vh - 210px)",
  },
  withoutTopMargin: {
    marginTop: 0,
  },
  selectionWrapper: {
    height: "100%",
    backgroundColor: "white",
  },
});

const LayoutVariants = [
  {
    id: 1,
    Icon: InsertDriveFileOutlined,
    Text: "singleView",
  },
  {
    id: 3,
    Icon: ViewColumnOutlined,
    Text: "threeView",
  },
  {
    id: 6,
    Icon: ViewModuleOutlined,
    Text: "sixView",
  },
];

const Classification: FC<{}> = () => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { user, tenantConfig } = useAuth();
  const appAction = useApplicationAction();
  const history = useHistory();
  const classifierSearchRef = useRef<HTMLInputElement>(null);

  const canNavigateToDeclaration =
    user &&
    accessCheck(user.allowedRoles, Permission.DECLARATION_VIEW__NAVIGATE);
  const shouldBeRedirectedToDeclaration =
    canNavigateToDeclaration &&
    sessionStorage.getItem("classify_for")?.includes("declarations");

  const documents: string[] = JSON.parse(
    sessionStorage.getItem("classify_documents") || "[]"
  );

  const documentsInfo = useGetDocuments(documents, {
    skip: shouldBeRedirectedToDeclaration,
  });

  const declarationsInfo = useGetDeclaration(documents, {
    skip: !shouldBeRedirectedToDeclaration,
  });

  const {
    data: {
      declarations: [declaration],
    },
    refetch: refetchDeclaration,
  } = declarationsInfo;

  const { documentData, loading, error } = useMemo(() => {
    if (shouldBeRedirectedToDeclaration) {
      const { loading, error } = declarationsInfo;
      return {
        documentData: declaration?.file?.documents || [],
        loading,
        error,
      };
    }
    const { data, loading, error } = documentsInfo;
    return {
      documentData: data,
      loading: loading,
      error: error,
    };
  }, [documentsInfo, declarationsInfo]);

  const {
    data: documentTypes,
    loading: documentTypesLoading,
    error: documentTypesError,
  } = useGetDocumentTypes();

  const lastSessionViewMode = localStorage.getItem("viewMode");
  const [currentView, setView] = useState<number>(
    lastSessionViewMode ? +lastSessionViewMode : 3
  );

  const updateView = (view: number) => {
    setView(view);
    localStorage.setItem("viewMode", view.toString());
  };

  const [fileToEdit, setFileToEdit] = useState<File>();
  const [showFileDialog, setShowFileDialog] = useState<boolean>(false);
  const [currentDocumentType, setCurrentDocumentType] = useState<{
    [key: string]: any;
  }>({});

  const didConfirmAutoClassification = useRef(false);
  //const [autoClassification, setAutoClassification] = useState<boolean>(false);

  const [rotationMutation, setRotationMutation] = useState<{
    [key: string]: any;
  }>({});
  const [finishDialog, toggleFinishDialog] = useToggle();
  const [hasAutoClassificationPages, setAutoClassify] = useToggle();

  const [expandedStatus, setExpandedStatus] = useState<ExpandStatus>(
    ExpandStatus.Full
  );

  useEffect(() => {
    if (!shouldBeRedirectedToDeclaration || !declaration?.file.identification) {
      return;
    }

    const fileNumber = declaration?.file.identification;
    const orgId = Number(process.env.REACT_APP_DEFAULT_WD_ORG_ID);
    const type = FileType.Declaration;
    if (!declarationsInfo.loading) {
      actions.addFile({
        fileNumber,
        orgId,
        type,
        fileId: documents[0],
      });

      handleDeclareFile({
        fileNumber,
        orgId,
        type,
      });
    }
  }, [declarationsInfo, hasAutoClassificationPages]);

  //const appendDocumentMeta = useAppendDocumentMeta();

  const documentsWithCachedPages = useMemo(() => {
    return documentData.map(({ pages, ...rest }) => {
      const filteredPages = pages.filter(
        ({ page_status }: { page_status: string }) =>
          page_status !== "classified"
      );
      return {
        ...rest,
        pages: filteredPages,
      };
    });
  }, [documentData]);

  const {
    selected,
    disabled,
    focused,
    cancel,
    disable,
    handleSelect,
    exclude,
    include,
  } = useElementSelector({
    pagesStructure: documentsWithCachedPages.map(({ pages }) => pages.length),
    itemsPerLine: currentView,
  });

  const [state, actions, getters] = useClassifier();

  const [draggedInfo, setDraggedInfo] = useState<any>();

  useEffect(() => {
    const hasAutoClassification =
      tenantConfig?.UseAutoclassification?.value &&
      documentsWithCachedPages.some((document, documentIndex) => {
        let flag = false;
        document.meta?.doc_classification?.forEach(
          ({ page_numbers, doc_type }) => {
            if (doc_type === "unknown") {
              return;
            }

            page_numbers?.forEach((page_number: number) => {
              const pageIndex = document.pages.findIndex(
                (page) => page.page_number === page_number
              );

              if (
                pageIndex >= 0 &&
                !disabled.includes(`${documentIndex}.${pageIndex}`)
              ) {
                flag = true;
              }
            });
          }
        );

        return flag;
      });

    if (
      hasAutoClassification &&
      !disabled.length &&
      !declaration?.file.identification
    ) {
      documentsWithCachedPages.forEach((document) => {
        document.meta.doc_classification?.forEach((classificationInfo) => {
          if (classificationInfo.doc_type !== "unknown") {
            setShowFileDialog(true);
            return;
          }
        });
      });
    }
    setAutoClassify(hasAutoClassification);
    // eslint-disable-next-line
  }, [documentsWithCachedPages, tenantConfig, declaration]);

  const onKeyDown = useCallback(
    (e: Event) => {
      if (showFileDialog || finishDialog) return;

      const { keyCode } = e as KeyboardEvent;

      const tabButton = 9;
      if (keyCode !== tabButton) return;

      e.preventDefault();
      const inputElement = classifierSearchRef.current;
      if (inputElement) {
        document.activeElement === inputElement
          ? inputElement.blur()
          : inputElement.focus();
      }
    },
    [showFileDialog, finishDialog]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const enablePages = useCallback(
    (pagesIdsToEnable) => {
      const paths = documentsWithCachedPages.reduce((acc, document, index) => {
        const { pages } = document;

        const pagePaths: any = pages
          .map(
            (page, pIndex) =>
              pagesIdsToEnable.includes(page.id) && `${index}.${pIndex}`
          )
          .filter(Boolean);
        return acc.concat(pagePaths);
      }, []);

      if (paths.length) {
        cancel(paths);
      }
    },
    [cancel, documentsWithCachedPages]
  );

  const handleEdit = useCallback(
    (fileNumber: string) => {
      setFileToEdit(state.files[fileNumber]);
      setShowFileDialog(true);
    },
    [state]
  );

  const handleDelete = useCallback(
    (fileNumber: string, document?: { id: string; document_type: string }) => {
      if (!document) {
        const { documents } = state.files[fileNumber];
        const filePages = documents.reduce<string[]>(
          (acc, document) => acc.concat(state.documents[document].pages),
          []
        );

        enablePages(filePages);
        actions.removeFile(fileNumber);
        return;
      }
      enablePages(state.documents[document.id].pages);
      actions.removeDocument(document.id, fileNumber);
    },
    [actions, enablePages, state.documents, state.files]
  );

  const checkDuplicateFile = useCallback(
    (fileNumber: string) => {
      if (fileNumber !== "newFileNumber") {
        return fileNumber;
      }

      const duplicateFileNumber = state.filesArray.filter((fileName) =>
        fileName.includes(fileNumber)
      );
      return "newFileNumber" + (duplicateFileNumber.length || "");
    },
    [state]
  );

  const getSelectedIds = useCallback(
    (indexes = selected): string[] => {
      return indexes.map((index: string) => {
        const [document, page] = index.split(".");
        return get(documentsWithCachedPages, `${document}.pages.${page}.id`);
      });
    },
    [documentsWithCachedPages, selected]
  );

  const handleDeclareFile = useCallback(
    (file: {
      fileNumber: string;
      orgId?: number;
      type: number;
      fileId?: string;
      scheduleFileExport?: boolean;
      declarationData?: DeclarationState | null;
    }) => {
      if (fileToEdit) {
        actions.editFile(file);
        setFileToEdit(undefined);
        return;
      }

      if (shouldBeRedirectedToDeclaration) {
        file.type = FileType.Declaration;
        file.orgId = !!file.fileId
          ? file.orgId
          : Number(process.env.REACT_APP_DEFAULT_WD_ORG_ID);
        file.fileId = documents[0];
      }

      const selectedPageIDs = getSelectedIds();

      let fileNumber = file.fileNumber;
      if (!shouldBeRedirectedToDeclaration) {
        fileNumber = checkDuplicateFile(file.fileNumber);
      }
      actions.addFile({
        ...file,
        fileNumber,
      });

      if (!didConfirmAutoClassification.current && hasAutoClassificationPages) {
        //setAutoClassification(true);
        didConfirmAutoClassification.current = true;
      }

      if (
        file.type !== FileType.Folder &&
        selectedPageIDs.length === 0 &&
        hasAutoClassificationPages
      ) {
        documentsWithCachedPages.forEach((document, docIndex) => {
          document.meta.doc_classification?.forEach(
            ({ doc_type, page_numbers }) => {
              const pageList: string[] = [];
              if (doc_type !== "unknown") {
                page_numbers?.forEach((page_number: number) => {
                  const pageIndex = document.pages.findIndex(
                    (page) => page.page_number === page_number
                  );
                  if (pageIndex !== -1) {
                    const page = document.pages[pageIndex];
                    pageList.push(page.id);
                    disable(`${docIndex}.${pageIndex}`);
                  }
                });

                const documentType = documentTypes.find(
                  ({ name }) => name === doc_type
                );

                if (pageList.length) {
                  actions.addDocument(
                    {
                      pages: pageList,
                      document_type: doc_type,
                      document_flags:
                        (documentType && documentType.default_flags) || 0,
                    },

                    fileNumber
                  );
                }
              }
            }
          );
        });
        setAutoClassify(false);
        return;
      }

      if (draggedInfo) {
        actions.removeDocument(
          draggedInfo.document.id,
          draggedInfo.fromFileNumber
        );
        actions.addDocument(draggedInfo.document, fileNumber);
        setDraggedInfo(undefined);
        return;
      }

      const documentType = documentTypes.find(
        ({ name }) => name === currentDocumentType.name
      );

      if (documentType === undefined) {
        return;
      }

      let default_flags = 0;
      if (documentType) {
        default_flags = documentType.default_flags;
      }

      actions.addDocument(
        {
          document_type: currentDocumentType.name,
          pages: selectedPageIDs,
          document_flags: default_flags,
        },
        fileNumber
      );

      disable();
    },
    [
      getSelectedIds,
      checkDuplicateFile,
      actions,
      hasAutoClassificationPages,
      documentTypes,
      currentDocumentType.name,
      disable,
      draggedInfo,
      documentsWithCachedPages,
      setAutoClassify,
      declaration,
      fileToEdit,
    ]
  );

  const handleAddDocument = useCallback(
    (docType: any) => {
      const selectedPageIDs = getSelectedIds();

      const documentType = documentTypes.find(
        ({ name }) => name === docType.name
      );
      let default_flags = 0;
      if (documentType) {
        default_flags = documentType.default_flags;
      }

      if (!state.selected.id) {
        setCurrentDocumentType(docType);
        setShowFileDialog(true);
      } else {
        actions.addDocument(
          {
            pages: selectedPageIDs,
            document_type: docType.name,
            document_flags: default_flags,
          },
          state.selected.id
        );
        disable();
      }
    },
    [actions, disable, documentTypes, getSelectedIds, state.selected.id]
  );

  const handleRotationValue = (value: number, previousValue: number) => {
    if (value < 0 && previousValue === 0) {
      previousValue = 360;
    }

    if (value === 0 && previousValue < 0) {
      value = 360;
    }

    let newRotation = value + previousValue;
    if (newRotation >= 360 || newRotation <= 0) {
      newRotation = 0;
    }

    return newRotation;
  };

  const handleRotationMutation = (rotationValue: number) => {
    setRotationMutation((mutation: any) => {
      selected.forEach((index) => {
        const [documentIndex, pageIndex] = index.split(".");
        const page = documentsWithCachedPages[+documentIndex].pages[+pageIndex];
        const updatedPage = mutation[page.id];
        const rotation = (updatedPage && updatedPage.rotate) || page.rotate;
        mutation[page.id] = {
          ...page,
          rotate: handleRotationValue(rotationValue, rotation),
        };
      });

      return Object.assign({}, mutation);
    });
  };

  const onMoveDocumentBetweenFiles = (
    document: Document,
    fromFile: string,
    toFile: string
  ) => {
    if (toFile === "add") {
      actions.selectFile("");
      setDraggedInfo({ document, fromFileNumber: fromFile });
      setShowFileDialog(true);
    } else {
      document?.id && actions.removeDocument(document.id, fromFile);
      actions.addDocument(document, toFile);
    }
  };

  const renderPage = useCallback(
    ({ style, path, focusOnly, onClick }) => {
      const arrayPath = path.split(".");
      const page = get(documentsWithCachedPages, [
        arrayPath[0],
        "pages",
        arrayPath[1],
      ]);

      if (!page) return null;

      const { thumbnailBigUrl, id, rotate } = page;

      const updatedPage = rotationMutation[id];
      const pageRotation = (updatedPage && updatedPage.rotate) || rotate;

      const classifiedPage: any = getters.getPageDocument(id);
      const documentType =
        classifiedPage &&
        documentTypes.find(
          (documentType) => documentType.name === classifiedPage.document_type
        );
      const isPageDisabled = disabled.includes(path);
      const horizontalValues = [90, -90, 270, -270];
      const width = horizontalValues.includes(pageRotation)
        ? {
            width: "70%",
          }
        : {
            width: "100%",
          };

      const isSelected = selected.includes(path);
      const isFocused = focused === path;

      return (
        <div
          onClick={onClick}
          style={{
            borderRadius: "4px",
            boxShadow: isFocused
              ? "0 0 8px 0 rgba(66, 212, 244, 0.5), 0 0 8px 0 rgba(66, 212, 244, 0.6)"
              : "none",
            ...style,
          }}
          key={path}
        >
          <SelectionWrapper
            selected={isSelected}
            shadowColor={documentType && documentType.meta?.color}
            elevation={4}
            label
            className={classes.selectionWrapper}
            onClick={handleSelect(path, focusOnly)}
            style={{
              cursor: isPageDisabled ? "auto" : "pointer",
            }}
            documentData={{
              ...classifiedPage,
              documentType,
            }}
          >
            <DocumentSelector
              p={4}
              data-pp="document_page_wrapper"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                padding: "0px 12px",
              }}
            >
              <PageImg
                thumbnailBigUrl={thumbnailBigUrl}
                pageRotation={pageRotation}
                classifiedPage={classifiedPage}
                isPageDisabled={isPageDisabled}
                width={width}
              />
            </DocumentSelector>
          </SelectionWrapper>
        </div>
      );
    },
    [
      documentsWithCachedPages,
      rotationMutation,
      getters,
      documentTypes,
      disabled,
      selected,
      focused,
      classes.selectionWrapper,
      handleSelect,
    ]
  );

  const onExpand = useCallback((index) => include(index.toString()), [include]);
  const onCollapse = useCallback(
    (index) => exclude(index.toString()),
    [exclude]
  );

  if (loading || documentTypesLoading) {
    return (
      <Grid
        container
        style={{
          height: "80vh",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Grid>
    );
  }

  if (error || documentTypesError) {
    let errorMessage = error && error.message;
    if (documentTypesError) {
      errorMessage = documentTypesError.message;
    }
    return (
      <Grid
        container
        style={{
          height: "80vh",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Text>{errorMessage}</Text>
      </Grid>
    );
  }

  return (
    <PageContainer title={t("navigation:classification")}>
      <Prompt when={!finishDialog} message={t("alerts:unsavedChanges")} />
      {showFileDialog && (
        <FileDialog
          file={fileToEdit}
          toggleDialog={() => {
            setCurrentDocumentType({});
            setShowFileDialog(false);
            setFileToEdit(undefined);
          }}
          onCancel={() => {
            didConfirmAutoClassification.current = true;
            setCurrentDocumentType({});
            setShowFileDialog(false);
            setFileToEdit(undefined);
            setAutoClassify(false);
          }}
          isFileNumberDuplicate={(fileNumber: string) =>
            state.filesArray.some((fileId) => fileId.includes(fileNumber))
          }
          shipmentInfo={handleDeclareFile}
          declaration={declaration}
        />
      )}
      {finishDialog && (
        <FinishDialog
          data={getters.getDataAsNested()}
          rotationMutation={rotationMutation}
          onClose={() => toggleFinishDialog(false)}
          onSubmit={() => {
            // Promise.all(
            //   documentData.map(async (document) => {
            //     return appendDocumentMeta(document.id, {
            //       autoClassification,
            //     });
            //   })
            // );
            history.push(
              shouldBeRedirectedToDeclaration
                ? "/select/we-declare/declarations"
                : "/select/documents"
            );
          }}
          documents={documentsWithCachedPages.map(
            ({ id, name, pages }, dIndex) => {
              const doesDisabledInclude = (_: any, pIndex: number) =>
                disabled.includes(`${dIndex}.${pIndex}`);
              return {
                id,
                name,
                isSomeSelected: pages.some(doesDisabledInclude),
                isAllSelected: pages.every(doesDisabledInclude),
              };
            }
          )}
          setOrgId={actions.addOrgId}
        />
      )}

      <ActionBar
        sx={{
          border: 1,
          borderColor: "grey3.main",
          boxShadow: "unset",
        }}
      >
        <ActionBarItem
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          className={classes.withoutWidth}
        >
          <Switch
            label={t("expandAll")}
            onToggle={(e) => {
              setExpandedStatus(
                e.target.checked ? ExpandStatus.Full : ExpandStatus.None
              );
            }}
            checked={expandedStatus === ExpandStatus.Full}
          />
          <Button
            variant="outlined"
            color="primary"
            className={cx(classes.leftSpace, classes.font)}
            onClick={() => handleRotationMutation(90)}
            disabled={selected.length === 0}
          >
            <RotateRight />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={cx(classes.leftSpace, classes.font)}
            onClick={() => handleRotationMutation(-90)}
            disabled={selected.length === 0}
          >
            <RotateLeft />
          </Button>
        </ActionBarItem>
        <ActionBarItem
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          className={classes.withoutWidth}
        >
          <Text translate={false} className={classes.rightSpace}>
            {t("view")}
          </Text>
          <ViewLayout
            onChange={updateView}
            initialElementId={currentView}
            elements={LayoutVariants}
          />

          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          {shouldBeRedirectedToDeclaration && (
            <>
              <Button
                color="primary"
                variant="outlined"
                endIcon={<ChatOutlinedIcon />}
                onClick={() => {
                  refetchDeclaration().then(({ data }) => {
                    const { file, reference } = declaration || {};
                    appAction.showChat({
                      title: file.identification || reference,
                      discussionId:
                        data.declaration_request[0]?.declaration_discussions[0]
                          ?.discussion_id,
                      fileId: documents[0],
                    });
                  });
                }}
              >
                {t("declaration:requestInformation")}
              </Button>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            </>
          )}
          <Button
            color="primary"
            variant={shouldBeRedirectedToDeclaration ? "outlined" : "contained"}
            disabled={
              state.documentsArray.length === 0 &&
              (state.filesArray.length === 0 ||
                state.filesArray.some(
                  (fileId) => state.files[fileId].documents.length === 0
                ))
            }
            onClick={toggleFinishDialog}
            {...(!shouldBeRedirectedToDeclaration && {
              endIcon: <SaveOutlined />,
            })}
          >
            {t(
              shouldBeRedirectedToDeclaration
                ? "common:finish"
                : "classify:finishClassification"
            )}
          </Button>
        </ActionBarItem>
      </ActionBar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.space}
      >
        <Grid
          item
          xl={2}
          lg={3}
          md={3}
          xs={12}
          container
          direction="column"
          className={cx(classes.withoutTopMargin, classes.screenHeight)}
        >
          <FileTree
            canAddFolder={!shouldBeRedirectedToDeclaration}
            data={getters.getDataAsNested()}
            activeFileNumber={state.selected.id}
            onSelect={actions.selectFile}
            addDocument={actions.addDocument}
            removeDocument={actions.removeDocument}
            onAddClick={() => actions.selectFile("")}
            onEdit={handleEdit}
            onDelete={handleDelete}
            documentTypes={documentTypes}
            onToggleCustoms={(doc) => actions.changeCustomsRelevancy(doc.id)}
            onMoveDocumentBetweenFiles={onMoveDocumentBetweenFiles}
            onToggleAIRequest={(doc) => actions.requireManualAnnotation(doc.id)}
          />
        </Grid>
        <Grid
          item
          container
          xl={8}
          lg={6}
          md={6}
          xs={12}
          className={cx(classes.withoutTopMargin, classes.screenHeight)}
          justifyContent="center"
        >
          <DocumentView
            documents={documentsWithCachedPages}
            status={expandedStatus}
            pagePerRow={currentView}
            onChangeExpand={setExpandedStatus}
            onExpand={onExpand}
            onCollapse={onCollapse}
            focused={focused}
          >
            {renderPage}
          </DocumentView>
        </Grid>
        <Grid
          item
          xl={2}
          lg={3}
          md={3}
          container
          direction="column"
          className={cx(classes.withoutTopMargin, classes.screenHeight)}
          style={{
            overflowY: "hidden",
          }}
        >
          <Classifier
            selectedPagesAmount={selected.length}
            onSelect={handleAddDocument}
            disableKeyboard={showFileDialog || !selected.length}
            searchRef={classifierSearchRef}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Classification;
