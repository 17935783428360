import React, { CSSProperties, useEffect, useState } from "react";
import { Skeleton } from "@periplus/ui-library";
import { get } from "lodash";

import ContentWrapper from "./ContentWrapper";
import { Document } from "graphql/hooks/useGetFiles";
import DocumentList from "pages/Select/Classify/components/DocumentList";
import { useSasToken } from "utils/azureBlobStorageUtils";

type DocumentPreviewerProps = {
  loading?: boolean;
  documents: Document[];
  focusedDocument: {
    focusedIndex: string;
    userTriggers: boolean;
  };
  onScrollDocument?: (index: number) => void;
};

const DocumentPreviewer = ({
  loading,
  documents,
  focusedDocument,
  onScrollDocument,
}: DocumentPreviewerProps) => {
  const [expandedDocuments, setExpandedDocuments] = useState<{
    [key: string]: any;
  }>({});
  const thumbnailsSasToken = useSasToken({ container: "thumbs" });

  useEffect(() => {
    if (!loading) {
      setExpandedDocuments(
        documents.reduce((acc, { id }) => ({ ...acc, [id]: true }), {})
      );
    }
  }, [loading]);

  const handleExpandDocuments = (document: any) => {
    setExpandedDocuments((prevDocuments) => ({
      ...prevDocuments,
      [document.id]: !prevDocuments[document.id],
    }));
  };

  const renderDocument = ({
    style,
    path,
  }: {
    style: CSSProperties;
    path: string;
  }) => {
    const arrayPath = path.split(".");
    const page = get(documents, [arrayPath[0], "pages", arrayPath[1]]);

    if (!page) return null;

    const { thumbnailBigUrl, rotate } = page;

    const horizontalValues = [90, -90, 270, -270];
    const width = horizontalValues.includes(rotate)
      ? {
          width: "70%",
        }
      : {
          width: "100%",
        };

    return (
      <ContentWrapper
        style={{
          textAlign: "center",
          ...style,
        }}
        key={path}
      >
        <img
          alt="document_page"
          data-pp="document_page"
          src={thumbnailsSasToken && `${thumbnailBigUrl}?${thumbnailsSasToken}`}
          style={{
            transform: `rotate(${rotate}deg)`,
            userSelect: "none",
            ...width,
          }}
        />
      </ContentWrapper>
    );
  };

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />;
  }

  return (
    <DocumentList
      withoutBookmarks
      documents={documents as any}
      pagePerRow={1}
      toggleExpand={handleExpandDocuments}
      expandedDocuments={expandedDocuments}
      focused={focusedDocument.userTriggers ? focusedDocument.focusedIndex : ""}
      scrollOptions={{ alwaysToTop: true }}
      onScrolledItem={onScrollDocument}
    >
      {renderDocument}
    </DocumentList>
  );
};

export default DocumentPreviewer;
