import { gql } from "@apollo/client";
import client from "graphql/client";
import { OrgId } from "../../domain/orgId/types";

const UPDATE_ORG_ID = gql`
  query UPDATE_ORG_ID(
    $org_id: smallint
    $display_name: String
    $org_params: json
    $org_id_description: String
  ) {
    pp_org_id_update(
      args: {
        _org_id: $org_id
        _display_name: $display_name
        _org_params: $org_params
        _desk: $org_id_description
      }
    ) {
      json_value
    }
  }
`;

const useUpdateOrgId = () => {
  const query = (variables: OrgId) => {
    return client.query({
      query: UPDATE_ORG_ID,
      fetchPolicy: "no-cache",
      variables,
    });
  };

  return query;
};

export default useUpdateOrgId;
