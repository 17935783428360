import { gql } from "@apollo/client";
import {
  AditGetTenantUsersTableQuery,
  useAditGetTenantUsersTableQuery,
} from "graphql/generated";

export type TenantUserTableEntity =
  AditGetTenantUsersTableQuery["users"][number];

gql`
  query aditGetTenantUsersTable(
    $limit: Int
    $offset: Int
    $search: String
    $tenant: UUID
  ) {
    users: getUsers(
      args: { limit: $limit, offset: $offset, search: $search, tenant: $tenant }
    ) {
      id
      tenantId
      firstName
      lastName
      displayName
      email
      location
      isEnabled
      externalAuthProvider
      creationDate
      lastModifiedDate
      orgIds: orgIdEntities {
        ...OrgIdsListFragment
      }
      roles {
        ...AditRolesListFragment
      }
    }
  }
`;

export default useAditGetTenantUsersTableQuery;
