import { useEffect, useState, useMemo } from "react";
import { gql } from "@apollo/client";
import get from "lodash/get";

import client, { createApolloContext } from "graphql/client";

export enum FileFlags {
  Default = 0,
  Journey = 1,
  Folder = 2,
  Declaration = 8,
}

export enum NotificationStatus {
  Success = 1,
  Processing = 2,
  Deferred = 3,
  Error = 4,
}

export enum NotificationType {
  Export = 1,
  Upload = 2,
  Classify = 3,
  Identification = 4,
  Annotate = 5,
  Tesseract = 6,
  LBase = 7,
}

const GET_NOTIFICATIONS = gql`
  query getNotifications {
    notifications(count: 10)
  }
`;

interface getDocumentContentArgs {
  nm?: string;
  cd?: string;
  ld?: string;
  u?: string;
  e?: string;
  n?: number;
  o?: number;
}

interface getDocumentContentReturn {
  name?: string;
  creationDate?: string;
  lastUpdateDate?: string;
  user?: string;
  extension?: string;
  numberOfPages?: number;
  orgId?: number;
}

const getDocumentContent = (
  documentContent: getDocumentContentArgs = {}
): getDocumentContentReturn => {
  const { nm, cd, ld, u, e, n, o } = documentContent;
  return {
    name: nm,
    creationDate: cd,
    lastUpdateDate: ld,
    user: u,
    extension: e,
    numberOfPages: n,
    orgId: o,
  };
};

interface getFileContentArgs {
  f?: number;
  s?: string;
  n?: string;
  o?: number;
  fn?: string;
}

interface getFileContentReturn {
  flags?: number;
  status?: string;
  name?: string;
  orgId?: number;
  folderName?: string;
  isFolder?: boolean;
}

const getFileContent = (
  fileContent: getFileContentArgs = {}
): getFileContentReturn => {
  const { f, s, n, o, fn } = fileContent;
  const isFolder = ((f as any) & FileFlags.Folder) === FileFlags.Folder;
  return {
    flags: f,
    status: s,
    name: n,
    orgId: o,
    folderName: fn,
    isFolder,
  };
};

const useGetNotifications = () => {
  const [notifications, setNotifications] = useState<any>([]);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const maxDelay = 1000 * 60 * 1; // 1 minute
    const initialDelay = 0;
    const delayIncreasingStep = 500; // half an second
    let delay = initialDelay;

    const run = async () => {
      const { data, errors } = await client.query({
        query: GET_NOTIFICATIONS,
        fetchPolicy: "no-cache",
        context: createApolloContext({
          disableErrorNotification: true,
        }),
      });

      const notifications = get(data, "notifications", []);
      if (notifications.length > 0) {
        setNotifications(notifications);
      }

      // @ts-ignore
      if (errors?.length > 0 && delay < maxDelay) {
        delay += delayIncreasingStep;
      } else {
        delay = initialDelay;
      }

      timeoutId = setTimeout(() => {
        run();
      }, delay);
    };

    run();

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const returnValue = useMemo(() => {
    const messages = notifications.map(({ Message }: { Message: Object }) => ({
      ...Message,
    }));

    const updatedMessages = messages
      .filter(
        ({ t }: any) =>
          t !== NotificationType.Tesseract && t !== NotificationType.Annotate
      )
      .map((message: any) => {
        const status = NotificationStatus[message.s];
        const type = NotificationType[message.t];

        const contentType = get(message, "p.t");
        const getContent =
          contentType === "Document" ? getDocumentContent : getFileContent;
        const content = getContent(get(message, "p.c"));

        return {
          type,
          status,
          date: message.d,
          error: message.e,
          contentType,
          content,
        };
      });

    return updatedMessages;
  }, [notifications]);

  return returnValue;
};

export default useGetNotifications;
