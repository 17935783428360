const getDynamicBaseUrl = (): string => {
  const envBaseUrl: string | undefined =
    process.env.REACT_APP_AUTH_SERVICE_BASE_URL;

  if (envBaseUrl) {
    return envBaseUrl;
  }

  const currentDomain: string = window.location.hostname;
  const protocol: string = window.location.protocol;

  const domainParts: string[] = currentDomain.split(".");
  const rootDomain: string =
    domainParts.length > 2
      ? domainParts.slice(domainParts.length - 2).join(".")
      : currentDomain;

  const dynamicBaseUrl: string = `${protocol}//demo.swagger.api.adit.${rootDomain}`;

  return dynamicBaseUrl;
};

export default getDynamicBaseUrl;
