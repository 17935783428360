import { gql } from "@apollo/client";
import {
  AditGetCurrentUserQuery,
  useAditGetCurrentUserLazyQuery,
} from "graphql/generated";
import { NotNullable } from "utils/utilityTypes";

type CurrentUserEntityOriginal = NonNullable<AditGetCurrentUserQuery["user"]>;
export type CurrentUserEntity = NotNullable<
  CurrentUserEntityOriginal,
  "firstName" | "lastName"
>;

gql`
  query AditGetCurrentUser {
    user: getCurrentUser {
      userId: id
      firstName
      lastName
      email
      uiSettings
      tenantId
      modules {
        name
      }
    }
  }
`;

export default useAditGetCurrentUserLazyQuery;
