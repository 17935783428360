import React, { FC } from "react";
import { PageContainer } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import {
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import GeneralTab from "./GeneralTab";
import UsersTab from "./UsersTab";
import DocumentTypesTab from "./DocumentTypesTab";
import useGetTenantTable from "../hooks/useGetTenantsTable";
import SettingsTab from "./SettingsTab";
import AuthRoute from "App/Routes/Auth/AuthRoute";

export const TENANT_TABS = [
  { id: "general", label: "General" },
  { id: "users", label: "Users" },
  { id: "document-types", label: "Document Types" },
  { id: "settings", label: "Settings" },
] as const;

interface TenantProps {}
interface UrlParams {
  id: string;
}

const Tenant: FC<TenantProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams<UrlParams>();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const {
    data: { tenants: [tenant] } = { tenants: [] },
    loading,
    refetch,
  } = useGetTenantTable({
    variables: {
      id,
    },
  });

  return (
    <PageContainer
      title={tenant?.tenantName}
      loading={loading}
      tabs={TENANT_TABS.map((tab) => t(tab.label))}
      selectedTab={TENANT_TABS.findIndex(
        (tab) => tab.id === pathname.split("/").pop()!
      )}
      onTabChange={(newTabIndex) =>
        history.push(`${url}/${TENANT_TABS[newTabIndex].id}`)
      }
    >
      <Switch>
        <AuthRoute path={`${path}/general`}>
          <GeneralTab tenant={tenant} onSave={refetch} />
        </AuthRoute>
        <AuthRoute path={`${path}/users`}>
          <UsersTab tenant={tenant} />
        </AuthRoute>
        <AuthRoute path={`${path}/document-types`}>
          <DocumentTypesTab tenant={tenant} />
        </AuthRoute>
        <AuthRoute path={`${path}/settings`}>
          <SettingsTab tenant={tenant} />
        </AuthRoute>
      </Switch>
    </PageContainer>
  );
};

export default Tenant;
