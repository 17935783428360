import React, { useMemo } from "react";
import { Dialog, FormAutocomplete, FormTextField } from "@periplus/ui-library";
import { Form, Formik } from "formik";
import { makeStyles } from "tss-react/mui";
import Countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TenantUserTableEntity } from "./hooks/useGetTenantUsers";
import useCreateTenantUser from "./hooks/useCreateTenantUser";
import useUpdateTenantUser from "./hooks/useUpdateTenantUser";
import { TenantTableEntity } from "../../hooks/useGetTenantsTable";
import RoleFormAutocomplete from "domain/user/components/RoleAutocomplete/RoleFormAutocomplete";
import OrgIdFormAutocomplete from "domain/orgId/components/OrgIdAutocomplete/OrgIdFormAutocomplete.tsx";

interface IUserFormDialogProps {
  user?: TenantUserTableEntity;
  tenantConfig?: any;
  tenant: TenantTableEntity;
  onClose(): void;
  onConfirm(): void;
}

enum UserFields {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Roles = "roles",
  OrgIds = "orgIds",
  Location = "location",
}

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const useStyles = makeStyles()({
  form: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gap: 16,
  },
});

const UserFormDialog: React.FC<IUserFormDialogProps> = ({
  tenantConfig,
  user,
  onClose,
  onConfirm,
  tenant,
}) => {
  const { t } = useTranslation();
  const allowAddUserInUserManagement =
    tenantConfig?.AllowAddUserInUserManagement?.value;
  const { classes } = useStyles();

  const [createUser] = useCreateTenantUser();
  const [updateUser] = useUpdateTenantUser();

  const initialValues = useMemo(
    () => ({
      [UserFields.FirstName]: user?.firstName ?? "",
      [UserFields.LastName]: user?.lastName ?? "",
      [UserFields.Email]: user?.email ?? "",
      [UserFields.Roles]: user?.roles ?? [],
      [UserFields.OrgIds]: user?.orgIds ?? [],
      [UserFields.Location]: user?.location || null,
    }),
    [user]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        [UserFields.FirstName]: Yup.string()
          .trim()
          .required()
          .max(100)
          .matches(/^[\p{L}\s]+$/u, t("Can contain only letters")),
        [UserFields.LastName]: Yup.string()
          .trim()
          .required()
          .max(100)
          .matches(/^[\p{L}\s]+$/u, t("Can contain only letters")),
        [UserFields.Email]: Yup.string().email().trim().required().max(254),
        [UserFields.Roles]: Yup.array(),
        [UserFields.OrgIds]: Yup.array(),
        [UserFields.Location]: Yup.string().nullable(),
      }),
    [t]
  );

  const handleSubmit = (
    values: typeof initialValues,
    { setSubmitting }: any
  ) => {
    const castedValues = {
      ...(validationSchema.cast(values) as typeof initialValues),
      roles: values.roles.map((el) => el.id),
      orgIds: values.orgIds.map((el) => el.orgId),
    };

    const onSuccess = () => {
      setSubmitting(false);
      onConfirm();
    };
    const onError = () => {
      setSubmitting(false);
    };

    if (user) {
      updateUser({
        variables: {
          object: {
            userId: user.id,
            firstName: castedValues.firstName,
            lastName: castedValues.lastName,
            orgIds: castedValues.orgIds,
            roles: castedValues.roles,
            location: castedValues.location,
          },
        },
      })
        .then(onSuccess)
        .catch(onError);
    } else {
      createUser({
        variables: {
          object: {
            firstName: castedValues.firstName,
            lastName: castedValues.lastName,
            email: castedValues.email,
            tenantId: tenant.id,
            orgIds: castedValues.orgIds,
            roles: castedValues.roles,
            location: castedValues.location,
          },
        },
      })
        .then(onSuccess)
        .catch(onError);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm }) => (
        <Dialog
          open
          fullWidth
          maxWidth="md"
          mainTitle={
            user
              ? `${t("common:edit")}: ${user.firstName} ${user.lastName}`
              : `${t("common:new")}: ${t("common:user")}`
          }
          onClose={onClose}
          onConfirm={submitForm}
          ConfirmButtonProps={{
            loading: isSubmitting,
          }}
        >
          <Form className={classes.form}>
            <FormTextField
              name={UserFields.FirstName}
              variant="outlined"
              fullWidth
              label={t("common:firstName")}
              disabled={!allowAddUserInUserManagement}
              required
            />
            <FormTextField
              name={UserFields.LastName}
              variant="outlined"
              fullWidth
              label={t("common:lastName")}
              disabled={!allowAddUserInUserManagement}
              required
            />
            <FormTextField
              name={UserFields.Email}
              variant="outlined"
              fullWidth
              label={t("common:email")}
              disabled={!!user || !allowAddUserInUserManagement}
              required
            />
            <RoleFormAutocomplete
              name={UserFields.Roles}
              fullWidth
              multiple
              disabled={!allowAddUserInUserManagement}
              InputProps={{
                label: t("Roles"),
              }}
            />
            <OrgIdFormAutocomplete
              name={UserFields.OrgIds}
              multiple
              disabled={!allowAddUserInUserManagement}
              InputProps={{
                label: t("Org Ids"),
              }}
            />
            <FormAutocomplete
              name={UserFields.Location}
              freeSolo
              autoSelect
              options={Object.values(Countries.getNames("en")).map((country) =>
                Array.isArray(country) ? country[0] : country
              )}
              InputProps={{
                variant: "outlined",
                label: t("common:location"),
              }}
              disabled={!allowAddUserInUserManagement}
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default UserFormDialog;
