import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "contexts/Auth/AuthContext";
import { Typography, Button, Box } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import FormTitle from "../NonAuthenticatedRoutes/components/FormTitle";

export default () => {
  const { t } = useTranslation();

  const { logout } = useAuth();

  return (
    <Switch>
      <Route
        path="/"
        exact
        component={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <FormTitle>{t("error:unauthorizedTitle")}</FormTitle>
            <Typography>{t("error:unauthorizedDescription1")}</Typography>
            <Typography>{t("error:unauthorizedDescription2")}</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => logout()}
              sx={{
                minWidth: "100px",
                alignSelf: "start",
                mt: 1,
              }}
            >
              {t("Logout")}
            </Button>
          </Box>
        )}
      />
      <Route component={() => <Redirect to="/" />} />
    </Switch>
  );
};
