import { useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { useSubscription } from "@apollo/client";
import { useAuth } from "contexts/Auth/AuthContext";
import { Document } from "./types";
import { Sorted } from "graphql/helpers";
import { useAditGetDocumentsForSubscriptionQuery } from "graphql/generated";

interface Variables {
  page: number;
  itemsPerPage: number;
  onlyMine: boolean;
  sorted?: Sorted;
  search?: string;
}

gql`
  query AditGetDocumentsForSubscription(
    $offset: Int
    $limit: Int
    $creationUser: uuid
    $search: String
    $orderBy: String
  ) {
    documents: pp_doc_search_hs(
      args: {
        _creationuser: $creationUser
        _limit: $limit
        _offset: $offset
        _searchval: $search
        _orderby: $orderBy
      }
    ) {
      id
      document_extension
      document_name
      creation_date
      creation_user
      pages_m2m_aggregate(
        where: { page: { page_status: { _neq: "classified" } } }
      ) {
        aggregate {
          count
        }
      }
      org_id
      org_id_description
      document_status
      document_flags
      document_blobs
      total_rows
      user_display_name
    }
  }
`;

export const GET_DOCUMENT_UPDATED_SUBSCRIPTION = gql`
  subscription getDocumentUpdates($my: Boolean) {
    pp_doc_max_date_hs: pp_doc_max_date_v2(args: { _my: $my }) {
      max_change_date
    }
  }
`;

const useGetDocumentsSubscription = ({
  page,
  itemsPerPage,
  onlyMine,
  sorted,
  search,
}: Variables) => {
  const { user } = useAuth();
  const [, setChangeDate] = useState(null);

  const result = useAditGetDocumentsForSubscriptionQuery({
    variables: {
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage + 1,
      ...(onlyMine && { creationUser: user?.userId }),
      ...(sorted && {
        orderBy:
          Object.entries(sorted)
            .sort(([, a], [, b]) => a.pos - b.pos)
            .map(([id, sort]) => `${id} ${sort.dir} NULLS LAST`)
            .join(", ") + ", id ASC",
      }),
      search,
    },
    fetchPolicy: "no-cache",
  });

  useSubscription(GET_DOCUMENT_UPDATED_SUBSCRIPTION, {
    variables: {
      my: onlyMine,
    },
    onData: ({ data: { data: documentUpdateTime } }) => {
      const newChangeDate =
        documentUpdateTime?.pp_doc_max_date_hs?.[0]?.max_change_date || null;
      setChangeDate((prev) => {
        if (prev !== null) {
          result.refetch?.();
        }
        return newChangeDate;
      });
    },
  });

  const returnValue = useMemo(() => {
    const documents = (
      (result.data || result.previousData)?.documents ?? []
    ).map(
      (document: any) =>
        ({
          ...document,
          created_by_user: {
            display_name: document.user_display_name,
          },
          orgId: {
            org_id_description: document.org_id_description,
          },
        } as Document)
    );

    return {
      ...result,
      data: {
        documents: documents.slice(0, itemsPerPage),
        isLastPage: documents.length <= itemsPerPage,
      },
    };
  }, [result, itemsPerPage]);

  return returnValue;
};

export default useGetDocumentsSubscription;
