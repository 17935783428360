import React from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import {
  Box,
  Label,
  ListItemIcon,
  ListItemText,
  MRTable,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  ThreeDotsMenu,
} from "@periplus/ui-library";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import usePageSettings from "domain/user/usePageSettings";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import { TenantTableEntity } from "../../hooks/useGetTenantsTable";
import useGetTenantDocumentTypes from "./hooks/useGetTenantDocumentTypes";
import TenantDocumentType from "./hooks/useGetTenantDocumentTypes/TenantDocumentType";
import Colored from "components/Colored";
import DocumentTypeFormDialog from "./DocumentTypeFormDialog";

interface Props {
  tenant: TenantTableEntity;
}

export default ({ tenant }: Props) => {
  const { t } = useTranslation();
  const [dialogState, setDialogState] = useState<{
    dialog?: "form";
    entity?: TenantDocumentType;
  }>({});

  const { pageSettings, setPageSettingsForMRTable } =
    usePageSettings<TableLocalStorage>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { tenantDocumentTypes, loading, refetch } = useGetTenantDocumentTypes({
    tenantId: tenant.id,
  });

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<TenantDocumentType>[], TenantDocumentType[]]
  >(
    () => [
      [
        {
          header: t("Name"),
          id: "firstName",
          accessorFn: (originalRow) => originalRow.name,
        },
        {
          header: t("Color"),
          id: "color",
          accessorFn: (originalRow) => originalRow.meta?.color,
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.meta?.color && (
              <Colored color={originalRow.meta.color} />
            ),
        },
        {
          header: t("Identifier Code"),
          id: "code",
          accessorFn: (originalRow) => originalRow.code,
        },
        {
          header: t("Flags"),
          id: "flags",
          accessorFn: (originalRow) =>
            originalRow.defaultFlags?.map((el) => el.name).join(", "),
          Cell: ({ row: { original: originalRow } }) => (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {originalRow.defaultFlags?.map((defaultFlag) => (
                <Label
                  color={defaultFlag.color}
                  key={defaultFlag.id}
                  sx={{
                    marginRight: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {defaultFlag.name}
                </Label>
              ))}
            </Box>
          ),
          minSize: 400,
        },
      ],
      [...tenantDocumentTypes],
    ],
    [t, tenantDocumentTypes]
  );

  const [pagination, setPagination] = useState<MRTable.MRT_PaginationState>({
    pageSize: pageSettings.itemsPerPage,
    pageIndex: 0,
  });
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(pageSettings.columnPinning ?? {});
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(pageSettings.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    pageSettings.columnOrder ?? columns.map((c) => c.id!)
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: pagination.pageSize,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
      });
    },
    // eslint-disable-next-line
    [
      pagination.pageSize,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <>
      <MRTable.Table
        columns={columns}
        data={tableData}
        state={{
          pagination,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          isLoading: loading,
        }}
        enableRowActions
        enableColumnFilters
        onPaginationChange={setPagination}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        onRefresh={() => {
          refetch();
        }}
        renderRowActions={({ row: { original } }) => (
          <ThreeDotsMenu
            options={[
              {
                content: (
                  <>
                    <ListItemIcon>
                      <CreateOutlinedIcon color="action" />
                    </ListItemIcon>
                    <ListItemText primary={t("Edit")} />
                  </>
                ),
                action: (close) => {
                  close();
                  setDialogState({
                    dialog: "form",
                    entity: original,
                  });
                },
              },
            ]}
          />
        )}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 162px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            ...(table.getState().isFullScreen && {
              padding: `${theme.spacing(1)}!important`,
            }),
          }),
        })}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableBodyCellProps: {
              sx: {
                justifyContent: "center",
              },
            },
          },
        }}
      />
      {dialogState?.dialog === "form" && (
        <DocumentTypeFormDialog
          tenantDocumentType={dialogState.entity!}
          onConfirm={() => {
            refetch();
            setDialogState({});
          }}
          onClose={() => setDialogState({})}
        />
      )}
    </>
  );
};
