import { useAuth } from "contexts/Auth/AuthContext";
import { useLocation } from "react-router-dom";
import {
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_SortingState,
  MRT_VisibilityState,
} from "@periplus/ui-library/lib/components/MRTable";

export interface ColumnsSettings {
  [key: string]: { pos: number; visible?: boolean };
}

export interface TableLocalStorage<
  T = any,
  DisablePagination extends boolean = false
> {
  itemsPerPage: DisablePagination extends true ? number | undefined : number;
  filters?: T;
  columnSizing?: MRT_ColumnSizingState;
  columnPinning?: MRT_ColumnPinningState;
  columnVisibility?: MRT_VisibilityState;
  columnOrder?: MRT_ColumnOrderState;
  sorting?: MRT_SortingState;
  showColumnFilters?: boolean;
  columnsSettings?: ColumnsSettings;
}

//If usePageLocalStorage and useUrlSearchParams called together, then usePageLocalStorage should be called firstly
function usePageLocalStorage<T = any>(
  initialState?: T
): {
  pageLocalStorage: T;
  setPageLocalStorage: (newValues: Partial<T>) => void;
  getPageLocalStorage: () => T;
} {
  const { user } = useAuth();
  const {
    state: { path },
  } = useLocation<{ path: string }>();

  const pageLocalStorage = {
    ...initialState,
    ...(JSON.parse(localStorage.getItem("users") || "{}")[
      user?.userId as string
    ]?.pages?.[path] || {}),
  };

  const setPageLocalStorage = (newValues: any) => {
    const userId = user?.userId as string;
    const parsedUsersLocalStorage = JSON.parse(
      localStorage.getItem("users") || "{}"
    );
    localStorage.setItem(
      `users`,
      JSON.stringify({
        ...parsedUsersLocalStorage,
        [userId]: {
          ...parsedUsersLocalStorage[userId],
          pages: {
            ...parsedUsersLocalStorage[userId]?.pages,
            [path]: {
              ...parsedUsersLocalStorage[userId]?.pages?.[path],
              ...newValues,
            },
          },
        },
      })
    );
  };

  const getPageLocalStorage = () => ({
    ...initialState,
    ...(JSON.parse(localStorage.getItem("users") || "{}")[
      user?.userId as string
    ]?.pages?.[path] || {}),
  });

  return { pageLocalStorage, setPageLocalStorage, getPageLocalStorage };
}

export default usePageLocalStorage;
