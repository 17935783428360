import { gql } from "@apollo/client";
import {
  AditCreateTenantMutationVariables,
  useAditCreateTenantMutation,
} from "graphql/generated";

export type CreateTenantVariables = AditCreateTenantMutationVariables;

gql`
  mutation AditCreateTenant(
    $addressId: Int!
    $externalTenantId: String
    $roles: [Int!]
    $tenantName: String!
  ) {
    createTenantACS(
      addressId: $addressId
      externalTenantId: $externalTenantId
      roles: $roles
      tenantName: $tenantName
    )
  }
`;

export default useAditCreateTenantMutation;
