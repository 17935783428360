import { gql } from "@apollo/client";
import {
  AditGetTenantsTableQuery,
  useAditGetTenantsTableQuery,
} from "graphql/generated";

export type TenantTableEntity = AditGetTenantsTableQuery["tenants"][number];

gql`
  query AditGetTenantsTable($id: String) {
    tenants: getTenants(id: $id) {
      accountNumber
      addressId
      creationDate
      externalTenantId
      id
      isWdCustomer
      lastModifiedDate
      permissions
      tenantName
      tenantType
      traderIdentificationNumber
      address {
        ...AdiAddressesListFragment
      }
      roles {
        ...AditRolesListFragment
      }
    }
  }
`;

export default useAditGetTenantsTableQuery;
