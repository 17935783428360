import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { gql } from "@apollo/client";
import { useAuth } from "contexts/Auth/AuthContext";
import { HARDCODED_TENANTS } from "domain/tenant/type";

export const PATCH_UPDATE_TENANT_SETTING = gql`
  mutation PATCH_UPDATE_TENANT_SETTING($tenantId: ID!, $settings: JSON) {
    patchTenantConfig(tenantId: $tenantId, value: $settings)
  }
`;

const usePatchUpdateTenantSettings = (tenantId?: string) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [patchUpdateTenantSettings] = useMutation(PATCH_UPDATE_TENANT_SETTING);

  const updateTenantSettings = useCallback(
    async (settings) => {
      setLoading(true);
      try {
        patchUpdateTenantSettings({
          variables: {
            tenantId: tenantId || user?.tenantId || HARDCODED_TENANTS.mnr,
            settings,
          },
        }).then(() => {
          setLoading(false);
          enqueueSnackbar("Settings successfully saved", {
            variant: "success",
          });
        });
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    [enqueueSnackbar, patchUpdateTenantSettings, tenantId, user]
  );

  return {
    update: updateTenantSettings,
    loading,
  };
};

export default usePatchUpdateTenantSettings;
