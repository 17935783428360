import { Box, FormTextField, combineSX, styled } from "@periplus/ui-library";
import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import AddressFormAutocomplete from "domain/address/components/AddressFormAutocomplete";
import { Form } from "formik";
import RoleFormAutocomplete from "domain/user/components/RoleAutocomplete/RoleFormAutocomplete";

const StyledForm = styled(Form)();

type Props = ComponentProps<typeof StyledForm>;

export default (props: Props) => {
  const { t } = useTranslation();

  return (
    <StyledForm
      {...props}
      sx={combineSX(
        {
          display: "flex",
          flexDirection: "column",
          gap: 1,
        },
        props.sx
      )}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <FormTextField
          name="tenantName"
          label={t("Name")}
          required
          sx={{ flexGrow: 1 }}
        />
        <FormTextField
          name="externalTenantId"
          label={t("External Tenant Id")}
          sx={{ flexGrow: 1 }}
        />
      </Box>
      <AddressFormAutocomplete
        name="address"
        fullWidth
        InputProps={{
          label: t("Address"),
          required: true,
        }}
      />
      <RoleFormAutocomplete
        name="roles"
        fullWidth
        multiple
        InputProps={{
          label: t("Roles"),
        }}
      />
    </StyledForm>
  );
};
