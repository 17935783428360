import React, { PureComponent } from "react";
import { Button, Box, Grid } from "@periplus/ui-library";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Text } from "components/Text";

/**
 * Prevents a catastrophic error from bubbling up the React tree
 * and displays a slightly better experience than the default
 * blank white screen.
 */
export default class ErrorBoundary extends PureComponent {
  state = {
    crashed: false,
  };

  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { crashed: true };
  }

  componentDidCatch(error: Error) {
    console.error(error);
    this.setState({ crashed: true });
  }

  render() {
    const { crashed } = this.state;

    if (crashed) {
      return (
        <Box
          position="absolute"
          left="50%"
          top="50%"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <Grid container direction="column">
            <Box width="100%" textAlign="center">
              <ErrorOutline color="error" style={{ fontSize: "6rem" }} />
            </Box>
            <Text variant="h3">error:crash</Text>
            <Grid container direction="row" alignItems="baseline">
              <Text>error:try</Text>
              &nbsp;
              <Button
                onClick={() => window.location.reload()}
                style={{
                  textTransform: "lowercase",
                  padding: 0,
                  color: "#42a5f5",
                }}
              >
                <Text>common:refreshing</Text>
              </Button>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return this.props.children;
  }
}
