import React, { useState } from "react";
import {
  Search,
  IconButton,
  Skeleton,
  Grid,
  Theme,
  Typography,
  Box,
} from "@periplus/ui-library";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTranslation } from "react-i18next";
import ContentWrapper from "./ContentWrapper";
import CircleIcon from "./CircleIcon";
import FileTree from "./FileTree";
import { Document } from "graphql/hooks/useGetFiles";

//import { DocumentTypeFlag } from "domain/documentType/types";
//import { Link } from "react-router-dom";
//import SmartToyIcon from "@mui/icons-material/SmartToy";
import { makeStyles } from "tss-react/mui";
import { getDownloadUrl } from "utils/azureBlobStorageUtils";
import { download } from "utils/files";

const useStyles = makeStyles()((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  fontSize125: {
    fontSize: "1.25rem",
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  clearButtonStyles: {
    width: 20,
    padding: 0,
  },
}));

type DocumentTypeProps = {
  fileId?: string;
  document: Document;
  focused?: boolean;
};

const DocumentType = ({ fileId, document, focused }: DocumentTypeProps) => {
  const { i18n } = useTranslation();
  const { classes, cx } = useStyles();
  const { translations } = document.documentType;

  //const documentFlags = Number(document.document_flags);

  // const isInternalValidation =
  //   (documentFlags & DocumentTypeFlag.ocr_datapoint_completed) ===
  //   DocumentTypeFlag.ocr_datapoint_completed;

  // const isFounSrvCompleted =
  //   (documentFlags & DocumentTypeFlag.foun_srv_completed) ===
  //   DocumentTypeFlag.foun_srv_completed;

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <CircleIcon
          color="white"
          bgColor={document.documentType.meta?.color || "#FF7A00"}
          bgOpacity={1}
          number={document.sequence}
        />
        <Typography
          variant="caption"
          style={{
            color: "#1D1D4B",
            textDecoration: focused ? "underline" : "",
          }}
        >
          {translations[i18n.language] || translations.en}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {/* {isInternalValidation && isFounSrvCompleted && (
          <Link
            to={`/select/validationV2/${fileId}?documentId="${document.id}"`}
          >
            <IconButton color="primary" size="small" tooltip={t("validateV2")}>
              <SmartToyIcon className={clsx(classes.fontSize125)} />
            </IconButton>
          </Link>
        )} */}
        <IconButton
          size="small"
          onClick={async () => {
            const extension = document.document_blobs.blobs[0].extension;
            const downloadUrl = await getDownloadUrl({
              url: document.document_blobs.blobs[0].url,
              contentType: `application/${extension}`,
              contentDisposition: `inline; filename="${encodeURI(
                document.document_name
              )}.${extension}"`,
            });
            download(downloadUrl);
          }}
        >
          <LaunchIcon className={cx(classes.fontSize125)} />
        </IconButton>
      </Box>
    </Box>
  );
};

type DocumentTypesProps = {
  fileId?: string;
  fileIdentification: string;
  documents: Document[];
  onDocumentTypeClick?: (index: number) => void;
  focusedDocumentType?: string;
};

const DocumentTypes = ({
  fileId,
  fileIdentification,
  documents,
  onDocumentTypeClick,
  focusedDocumentType,
}: DocumentTypesProps) => {
  const { i18n, t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const { classes } = useStyles();

  if (documents.length === 0) {
    return (
      <ContentWrapper>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Skeleton variant="rectangular" width="100%" height="30px" />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width="100%" height="82px" />
          </Grid>
        </Grid>
      </ContentWrapper>
    );
  }

  const filteredDocuments = documents.filter((document) => {
    if (!searchValue) {
      return true;
    }

    const { translations } = document.documentType;
    const docTypeName =
      translations[i18n.language]?.toLowerCase() ||
      translations.en?.toLowerCase() ||
      "";

    const searchValueLowered = searchValue.toLowerCase();

    return searchValue.length > 2
      ? docTypeName.includes(searchValueLowered)
      : docTypeName.startsWith(searchValueLowered);
  });

  const focused = focusedDocumentType ? focusedDocumentType.split(".")[0] : 0;
  return (
    <ContentWrapper sx={{ maxHeight: `calc(100vh - 370px)`, overflow: "auto" }}>
      <Search
        label={t("common:search") + "..."}
        value={searchValue}
        onChange={setSearchValue}
        className={classes.fullWidth}
      />
      <FileTree
        title={fileIdentification || t("common:mrsReferenceNumber")}
        onItemClick={onDocumentTypeClick}
        files={filteredDocuments.map((document, index) => (
          <DocumentType
            fileId={fileId}
            document={document}
            focused={+focused === index}
          />
        ))}
      />
    </ContentWrapper>
  );
};

export default DocumentTypes;
