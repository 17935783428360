import { gql } from "@apollo/client";
import { useSubscription, useQuery } from "@apollo/client";
import { GET_FILE_UPDATED_SUBSCRIPTION } from "../../graphql/hooks/useGetFilesSubscription";
import { GET_DOCUMENT_UPDATED_SUBSCRIPTION } from "../document/useGetDocumentsSubscription";
import { EmailView } from "./types";
import { Sorted } from "graphql/helpers";
import { useMemo, useState } from "react";

interface Variables {
  page: number;
  itemsPerPage: number;
  sorted?: Sorted;
  search?: string;
  onData: () => void;
}

export const GET_EMAILS = gql`
  query getEmails(
    $offset: Int
    $limit: Int
    $orderBy: String
    $search: String
  ) {
    pp_email_search_hs(
      args: {
        _offset: $offset
        _limit: $limit
        _search: $search
        _orderby: $orderBy
      }
      where: {
        documents: {
          _and: [
            { is_deleted: { _eq: false } }
            { document_status: { _neq: "classified" } }
            { document_status: { _neq: "classified_pending" } }
          ]
        }
      }
    ) {
      id
      name
      meta
      file_status
      creation_user
      creation_date
      file_url
      company
      sender
      total_rows
      documents(
        where: {
          _or: {
            is_deleted: { _eq: false }
            document_status: { _neq: "classified" }
          }
        }
      ) {
        id
        document_type
        document_status
        document_name
        document_extension
        document_flags
        creation_date
        sequence
        meta
        document_blobs
        created_by_user {
          display_name
        }
        pages_m2m_aggregate(
          where: { page: { page_status: { _neq: "classified" } } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

const useGetEmailsSubscription = ({
  page,
  itemsPerPage,
  sorted,
  search,
  onData,
}: Variables) => {
  const [, setChangeDate] = useState(null);

  const result = useQuery(GET_EMAILS, {
    variables: {
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage + 1,
      search,
      ...(sorted && {
        orderBy:
          Object.entries(sorted)
            .sort(([, a], [, b]) => a.pos - b.pos)
            .map(([id, sort]) => `${id} ${sort.dir} NULLS LAST`)
            .join(", ") + ", id ASC",
      }),
    },
    fetchPolicy: "no-cache",
  });

  useSubscription(GET_FILE_UPDATED_SUBSCRIPTION, {
    onData: ({ data: { data: fileUpdateTime } }) => {
      const newChangeDate =
        fileUpdateTime?.pp_doc_max_date_hs?.[0]?.max_change_date || null;
      setChangeDate((prev) => {
        if (prev !== null) {
          result.refetch?.();
          onData();
        }
        return newChangeDate;
      });
    },
  });

  useSubscription(GET_DOCUMENT_UPDATED_SUBSCRIPTION, {
    onData: ({ data: { data: documentUpdateTime } }) => {
      const newChangeDate =
        documentUpdateTime?.pp_doc_max_date_hs?.[0]?.max_change_date || null;
      setChangeDate((prev) => {
        if (prev !== null) {
          result.refetch?.();
          onData();
        }
        return newChangeDate;
      });
    },
  });

  return useMemo(() => {
    const files = ((result.data ?? result.previousData)?.pp_email_search_hs ||
      []) as EmailView[];
    return {
      ...result,
      data: {
        files: files.slice(0, itemsPerPage),
        isLastPage: files.length <= itemsPerPage,
      },
    };
  }, [result, itemsPerPage]);
};

export default useGetEmailsSubscription;
