import React, { FC, useState, useMemo, useCallback, ChangeEvent } from "react";
import {
  DatePickerRange,
  ActionBar,
  ActionBarItem,
  Skeleton,
  Autocomplete,
  Grid,
  Paper,
  Typography,
  Theme,
  PageContainer,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import dayjs, { Dayjs } from "dayjs";
import useGetFileStats from "graphql/hooks/useGetFileStats";
import useGetDocumentStats from "graphql/hooks/useGetDocumentStats";
import useGetAiStatsByDay, {
  AiStatsByDay,
} from "graphql/hooks/useGetAiStatsByDay";
import useGetAiStats from "graphql/hooks/useGetAiStats";
import useGetUsers from "graphql/hooks/useGetUsers";
import StatisticCard from "./components/StatisticCard";
import { useAuth } from "contexts/Auth/AuthContext";
import { makeStyles } from "tss-react/mui";
import { useGetOrgIdsList } from "domain/orgId/components/OrgIdAutocomplete/useGetOrgIdsList";

// TODO: take a second look to this function
const prepareDataForChart = (data: AiStatsByDay[]): any => {
  const allPossibleDates = data
    .reduce(
      (acc, { creation_date }) =>
        acc.includes(creation_date) ? acc : [...acc, creation_date],
      [] as string[]
    )
    .sort((a, b) => a.localeCompare(b))
    .splice(-12);

  const statisticByDocType: {
    [key: string]: {
      id: string;
      color: string;
      data: { [key: string]: { x: string; y: string } };
    };
  } = {};
  data.forEach(({ document_type, creation_date, success_ratio, color }) => {
    if (!allPossibleDates.includes(creation_date)) {
      return;
    }

    statisticByDocType[document_type] = {
      id: document_type,
      color,
      data: {
        ...statisticByDocType[document_type]?.data,
        [creation_date]: {
          x: dayjs(creation_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
          y: success_ratio.toFixed(1),
        },
      },
    };
  });

  const updatedDocTypes = Object.values(statisticByDocType).map(
    ({ data, ...rest }) => {
      const newData: { x: string; y: string }[] = [];
      allPossibleDates.forEach((date) => {
        newData.push(
          data[date] || {
            x: dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
            y: "0.0",
          }
        );
      });

      return {
        ...rest,
        data: newData,
      };
    }
  );

  return updatedDocTypes;
};

const useStyles = makeStyles()((theme: Theme) => ({
  cardWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > *": {
      flex: "1",

      "& > *": {
        height: "100%",
      },
    },
  },
  createdFiles: {
    backgroundColor: "#F736A8",
    color: "#fff",
  },
  createdJourneys: {
    backgroundColor: "#6F589F",
    color: "#fff",
  },
  activeUsers: {
    backgroundColor: "#221ECD",
    color: "#fff",
  },
  totalDocuments: {
    backgroundColor: "#604DFF",
    color: "#fff",
  },
  totalAutoClassified: {
    backgroundColor: "#F45B69",
    color: "#fff",
  },
  chartContainer: {
    padding: theme.spacing(2),
    width: "100%",
    height: 500,
  },
  tooltip: {
    padding: theme.spacing(0.5),
  },
}));

const localeToSize: { [key: string]: number } = {
  de: 90,
  en: 140,
  ru: 180,
};

const getListsAsIdAndLabels = (
  list: { [key: string]: any }[],
  idKey: string,
  objValue: string | ((value: { [key: string]: any }) => string | null)
): [string[], { [key: number]: string }] => {
  const idsList: string[] = [];
  const idsToLabelList: { [key: number]: string } = {};

  list?.forEach((item) => {
    const value =
      typeof objValue === "string" ? item[objValue] : objValue(item);
    if (value) {
      idsList.push(item[idKey]);
      idsToLabelList[item[idKey]] = value;
    }
  });

  return [idsList, idsToLabelList];
};

const Dashboard: FC<{}> = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  const {
    data: { users: usersList },
    loading: usersLoading,
  } = useGetUsers({
    tenantId: user?.tenantId,
  });

  const { data: { orgIds } = { orgIds: [] }, loading: orgIdsLoading } =
    useGetOrgIdsList();

  const [orgIdsList, orgIdsToLabel] = getListsAsIdAndLabels(
    orgIds,
    "orgId",
    "orgIdDescription"
  );
  const [usersIdsList, userIdsToName] = getListsAsIdAndLabels(
    usersList,
    "id",
    "display_name"
  );

  const [filters, setFilters] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    userIds: string[];
    orgIds: string[];
  }>({
    startDate: dayjs().utc().subtract(2, "years"),
    endDate: dayjs().utc(),
    userIds: [],
    orgIds: [],
  });

  const setDates = useCallback(
    (dates: { startDate: Dayjs | null; endDate: Dayjs | null }) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...dates,
      }));
    },
    []
  );

  const handleChangeAutoCompleteFilter = useCallback(
    (key: string) => (_: ChangeEvent<{}>, value: string[] | null) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [key]: value || [],
      }));
    },
    []
  );

  const variables = useMemo(
    () => ({
      ...filters,
      startDate: filters.startDate?.startOf("day").utc().format(),
      endDate: filters.endDate?.endOf("day").utc().format(),
    }),
    [filters]
  );

  const { data, error } = useGetFileStats(variables);
  const { data: docStats, loading: docStatsLoading } =
    useGetDocumentStats(variables);
  const { data: aiStatsByDay, loading: aiStatsByDayLoading } =
    useGetAiStatsByDay(variables);
  const { data: aiStats, loading: aiStatsLoading } = useGetAiStats(variables);

  docStats.sort(
    (a: { docs_qty: number }, b: { docs_qty: number }) =>
      b.docs_qty - a.docs_qty
  );

  const aiStatsByDayData = prepareDataForChart(aiStatsByDay);

  const itemsPerChart = 12;
  const splitDocStats = useMemo(
    () => docStats.slice(0, itemsPerChart),
    [docStats]
  );

  if (error) {
    return null;
  }

  return (
    <PageContainer title={t("navigation:statistics")}>
      <ActionBar
        sx={(theme) => ({
          border: `1px solid ${theme.palette.grey3.main}`,
          overflow: "auto",
        })}
      >
        <ActionBarItem container wrap="nowrap">
          <DatePickerRange
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={setDates}
          />
          <Autocomplete<string, true>
            disabled={usersLoading}
            multiple
            options={usersIdsList}
            getOptionLabel={(option: any) => userIdsToName[option]}
            filterSelectedOptions
            value={filters.userIds}
            limitTags={3}
            onChange={handleChangeAutoCompleteFilter("userIds")}
            ChipProps={{
              style: { maxWidth: "66%" },
            }}
            InputProps={{
              label: t("common:user"),
            }}
            style={{ width: 250, marginLeft: 16 }}
          />
          <Autocomplete<string, true>
            aria-label="Org id"
            style={{ width: 250, marginLeft: 16 }}
            disabled={orgIdsLoading}
            multiple
            options={orgIdsList}
            getOptionLabel={(option: any) => orgIdsToLabel[option]}
            filterSelectedOptions
            limitTags={3}
            value={filters.orgIds}
            onChange={handleChangeAutoCompleteFilter("orgIds")}
            ChipProps={{
              style: { maxWidth: "66%" },
            }}
            InputProps={{
              label: t("common:orgId"),
            }}
          />
        </ActionBarItem>
      </ActionBar>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <StatisticCard
            title={t("statistic:activeUsers")}
            value={data.activeUsers}
            className={classes.activeUsers}
          />
        </Grid>
        <Grid item>
          <StatisticCard
            title={t("statistic:filesCreated")}
            value={data.file}
            className={classes.createdFiles}
          />
        </Grid>
        <Grid item>
          <StatisticCard
            title={t("statistic:journeysCreated")}
            value={data.journey}
            className={classes.createdJourneys}
          />
        </Grid>
        <Grid item>
          <StatisticCard
            title={t("statistic:totalDocuments")}
            value={docStats.reduce(
              (acc: number, { docs_qty }: { docs_qty: number }) =>
                acc + docs_qty,
              0
            )}
            className={classes.totalDocuments}
          />
        </Grid>
        <Grid item>
          <StatisticCard
            title={t("statistic:totalAutoClassified")}
            value={aiStatsByDay.reduce(
              (acc: number, { docs_qty }: { docs_qty: number }) =>
                acc + docs_qty,
              0
            )}
            className={classes.totalAutoClassified}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item md={12} lg={6} xs={12}>
          <Paper variant="outlined" className={classes.chartContainer}>
            {aiStatsLoading ? (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : (
              <ResponsiveBar
                data={aiStats}
                keys={["success_ratio"]}
                indexBy="document_type"
                margin={{
                  top: 8,
                  right: 8,
                  bottom: 90,
                  left: 66,
                }}
                padding={0.3}
                colors={({ data }) => data.color}
                // @ts-ignore
                colorBy="index"
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -10,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: `${t("statistic:overallSuccessRate")}, %`,
                  legendPosition: "middle",
                  legendOffset: -60,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                motionStiffness={90}
                motionDamping={15}
                tooltip={({ value, data }) => (
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">
                        <b>{data.document_type}</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {`${t("statistic:successRate")}: `}
                        <b>{value}%</b>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              />
            )}
          </Paper>
        </Grid>
        <Grid item md={12} lg={6} xs={12}>
          <Paper variant="outlined" className={classes.chartContainer}>
            {aiStatsByDayLoading ? (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : (
              <ResponsiveLine
                data={aiStatsByDayData}
                margin={{ top: 8, right: 140, bottom: 40, left: 50 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -25,
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: `${t("statistic:dailySuccessRate")}, %`,
                  legendOffset: -44,
                  legendPosition: "middle",
                }}
                colors={({ color }) => color}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh
                tooltip={({ point: { data, serieColor, serieId } }) => (
                  <Paper
                    variant="outlined"
                    elevation={3}
                    className={classes.tooltip}
                  >
                    <Grid container direction="column">
                      <Grid container direction="row" alignItems="center">
                        <span
                          style={{
                            display: "block",
                            backgroundColor: serieColor,
                            width: 12,
                            height: 12,
                            marginRight: 7,
                          }}
                        />
                        <Typography variant="caption">
                          <strong>{serieId}</strong>
                        </Typography>
                      </Grid>
                      <Typography variant="caption">
                        {`${t("common:date")}: `}
                        <strong>{data.x}</strong>
                      </Typography>
                      <Typography variant="caption">
                        {`${t("statistic:successRate")}: `}
                        <strong>{data.y}%</strong>
                      </Typography>
                    </Grid>
                  </Paper>
                )}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                  },
                ]}
              />
            )}
          </Paper>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Paper variant="outlined" className={classes.chartContainer}>
            {docStatsLoading ? (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : (
              <ResponsiveBar
                data={splitDocStats}
                keys={["docs_qty"]}
                indexBy="document_type"
                margin={{
                  top: 8,
                  right: 8,
                  bottom: localeToSize[i18n.language],
                  left: 66,
                }}
                padding={0.3}
                colors={({ data }) => data.color}
                // @ts-ignore
                colorBy="index"
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -25,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t("statistic:documentsCount"),
                  legendPosition: "middle",
                  legendOffset: -60,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                motionStiffness={90}
                motionDamping={15}
                tooltip={({ value, data }) => (
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">
                        {`${t("statistic:totalDocuments")}: `}
                        <b>{value}</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {`${t("statistic:totalPages")}: `}
                        <b>{data.total_pages}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Dashboard;
