import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthRoutes from "./Auth";
import { useAuth } from "contexts/Auth/AuthContext";
import useFirebaseAnalytics from "hooks/useFirebaseAnalytics";
import { useApplicationAction } from "contexts/ApplicationContext";
import NonAuth from "./NonAuth";

const useAnalytics = () => {
  const { analytics } = useFirebaseAnalytics();
  const auth = useAuth();
  const user = auth.user;
  const isAuthenticated = auth.isAuthenticated;
  const { i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(useLocation().search);
  const appAction = useApplicationAction();

  const search = queryParams.get("q");

  useEffect(() => {
    /* eslint-disable no-restricted-globals */
    if (screen) {
      // @ts-ignore
      analytics.logEvent("screen_view", {
        screen_resolution: `${screen.width}x${screen.height}`,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    analytics.setUserProperties({ guest: !isAuthenticated });

    if (isAuthenticated && user) {
      analytics.setUserProperties({ name: user.email });
      analytics.setUserId(user.userId);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  useEffect(() => {
    const { pathname } = location;
    appAction.saveLocation(pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    const { pathname } = location;

    analytics.logEvent("page_view", {
      page_location: pathname,
      search,
      language: i18n.language,
    });
    // eslint-disable-next-line
  }, [location, i18n.language]);

  useEffect(() => {
    // @ts-ignore
    analytics.logEvent("search", { search_term: search });
    //eslint-disable-next-line
  }, []);
};

const Routes: FC = () => {
  const { isAuthenticated, isAuthorized } = useAuth();
  useAnalytics();

  if (!isAuthenticated || !isAuthorized) return <NonAuth />;

  return <AuthRoutes />;
};

export default Routes;
