import { gql } from "@apollo/client";
import { useAditGetCurrentUserTenantConfigLazyQuery } from "graphql/generated";

gql`
  query AditGetCurrentUserTenantConfig($tenantId: ID!) {
    getTenantConfig(tenantId: $tenantId)
  }
`;

export default useAditGetCurrentUserTenantConfigLazyQuery;
