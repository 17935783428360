import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  PropsWithChildren,
} from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import getDynamicBaseUrl from "utils/urlHelper";
import useGetCurrentUser, {
  CurrentUserEntity,
} from "./hooks/useGetCurrentUser";
import { getDecodedAccessToken } from "graphql/client";
import { Box, Loader } from "@periplus/ui-library";
import useGetCurrentUserTenantConfig from "./hooks/useGetCurrentUserTenantConfig";
import useUpdateCurrentUserUiSettings from "./hooks/useUpdateCurrentUserUiSettings";

export const SESSION_EXPIRED_STORAGE_KEY = "sessionExpired";

export enum Role {
  FreightForwarder = "freight_forwarder",
  Admin = "admin",
  AlphaTester = "alpha_tester",
  TenantAdmin = "tenant_admin",
  CustomsBroker = "customs_broker",
  Default = "default",
  WdFull = "wd_full",
  SupplierExtended = "supplier_extended",
}

export interface UserUiSettings {
  language?: string;
  sidebarOpen?: boolean;
  pages: { [key: string]: any };
  [key: string]: any;
}

type IUser = CurrentUserEntity & {
  allowedRoles: Role[];
  hasAllowedRoles: (roles: Role[]) => boolean;
  uiSettings: UserUiSettings;
};

interface IAuthContext {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  user?: IUser;
  tenantConfig?: any;
  login: (username: string, password: string) => Promise<boolean>;
  twoFactorAuth: (code: string) => Promise<void>;
  microsoftLogin: (toke: string) => Promise<void>;
  logout: () => void;
  refetch: () => Promise<void>;
  updateUiSettings: (partialNewUiSettings: Partial<UserUiSettings>) => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();
  const [twoFactorToken, setTwoFactorToken] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser>();
  const [tenantConfig, setTenantConfig] = useState<any>();
  const isAuthenticated = Boolean(user);
  const isAuthorized = Boolean(
    user?.modules?.some((el) => el.name === "adit") && user.allowedRoles.length
  );

  const [getCurrentUser] = useGetCurrentUser();
  const [getCurrentUserTenantConfig] = useGetCurrentUserTenantConfig();

  const [updateCurrentUserUiSettings] = useUpdateCurrentUserUiSettings();

  const getUserData = useCallback(async () => {
    const decodedToken = await getDecodedAccessToken();
    if (!decodedToken) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const { data: { user: dbUser } = {} } = await getCurrentUser();
    if (!dbUser) {
      setLoading(false);
      return;
    }

    const { data: { getTenantConfig: currentUserTenantConfig } = {} } =
      await getCurrentUserTenantConfig({
        variables: {
          tenantId: dbUser.tenantId,
        },
      });
    setTenantConfig(currentUserTenantConfig);

    setUser({
      ...dbUser,
      allowedRoles:
        decodedToken!["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"],
      hasAllowedRoles(roles: Role[]) {
        return this.allowedRoles.some((allowedRole) =>
          roles.includes(allowedRole)
        );
      },
      uiSettings: {
        pages: {},
        ...dbUser.uiSettings.adit,
      },
    } as IUser);

    setLoading(false);
  }, [getCurrentUser, getCurrentUserTenantConfig]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  const login: IAuthContext["login"] = useCallback(
    async (username, password) => {
      localStorage.setItem(SESSION_EXPIRED_STORAGE_KEY, JSON.stringify(false));
      try {
        const res = await axios.post(
          `${getDynamicBaseUrl()}/auth/login`,
          {
            username,
            password,
          },
          { withCredentials: true }
        );
        if (res.data.data?.is2FaEnabled) {
          setTwoFactorToken(res.data.data.token);
          return true;
        }
        await getUserData();
      } catch (err: any) {
        enqueueSnackbar(err?.response?.data?.message || err?.message, {
          variant: "error",
        });
      }

      return false;
    },
    [getUserData, enqueueSnackbar]
  );

  const twoFactorAuth: IAuthContext["twoFactorAuth"] = useCallback(
    async (code) => {
      try {
        await axios.post(
          `${getDynamicBaseUrl()}/auth/2fa/authenticate`,
          {
            authCode: code,
          },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${twoFactorToken}` },
          }
        );
        await getUserData();
      } catch (err: any) {
        enqueueSnackbar(err?.response?.data?.message || err?.message, {
          variant: "error",
        });
      }
    },
    [getUserData, enqueueSnackbar, twoFactorToken]
  );

  const microsoftLogin: IAuthContext["microsoftLogin"] = useCallback(
    async (token) => {
      localStorage.setItem(SESSION_EXPIRED_STORAGE_KEY, JSON.stringify(false));
      try {
        await axios.post(
          `${getDynamicBaseUrl()}/auth/microsoft-login`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        await getUserData();
      } catch (err: any) {
        enqueueSnackbar(err?.response?.data?.message || err?.message, {
          variant: "error",
        });
      }
    },
    [getUserData, enqueueSnackbar]
  );

  const logout: IAuthContext["logout"] = useCallback(async () => {
    try {
      await axios.post(
        `${getDynamicBaseUrl()}/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      window.location.href = "/login";
      window.location.reload();
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message || err?.message, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  const updateUiSettings: IAuthContext["updateUiSettings"] = useCallback(
    (partialNewUiSettings) => {
      const newUiSettings = {
        ...user!.uiSettings,
        ...partialNewUiSettings,
      };
      setUser((prev) => prev && { ...prev, uiSettings: newUiSettings });
      updateCurrentUserUiSettings({
        variables: {
          id: user!.userId,
          ui_settings: { adit: newUiSettings },
        },
      });
    },
    [user, updateCurrentUserUiSettings]
  );

  if (!user && loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthorized,
        user,
        tenantConfig,
        login,
        twoFactorAuth,
        microsoftLogin,
        logout,
        refetch: getUserData,
        updateUiSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
