import React, { FC, HtmlHTMLAttributes, useCallback, useMemo } from "react";
import {
  Dialog,
  FormTextField,
  FormAutocomplete,
  Theme,
} from "@periplus/ui-library";
import { OrgId } from "domain/orgId/types";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useGetOrgIdsSecured from "graphql/hooks/useGetOrgIdsSecured";
import useInsertOrgId from "graphql/hooks/useInsertOrgId";
import useUpdateOrgId from "graphql/hooks/useUpdateOrgId";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

interface OrgIdFormDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  orgId?: OrgId;
  onClose: () => void;
  onConfirm: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 16,
  },
  spanedColumn: {
    gridColumn: "span 2",
  },
}));

const OrgIdFormDialog: FC<OrgIdFormDialogProps> = ({
  orgId,
  onClose,
  onConfirm,
}) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    data: { org_ids },
  } = useGetOrgIdsSecured();
  const insertOrgId = useInsertOrgId();
  const updateOrgId = useUpdateOrgId();

  const mainTitle = orgId ? "Edit" : "Create";

  const initialValues = useMemo(
    () => ({
      org_id: orgId ? orgId.org_id : "",
      display_name: orgId?.display_name || "",
      org_id_description: orgId?.org_id_description || "",
      org_params: {
        file_ids: orgId?.org_params?.file_ids?.join(",") || "",
        addresses: orgId?.org_params?.addresses || "",
        org_ids:
          orgId?.org_params?.org_ids?.map((el) => ({
            org_id: el,
            display_name: org_ids.find((org_id) => org_id.org_id === el)
              ?.display_name,
          })) || [],
      },
    }),
    [orgId, org_ids]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        org_id: Yup.number()
          .typeError("Should be number")
          .required()
          .max(32767)
          .min(-32767)
          .test("isDuplicated", "This id already taken", (value) => {
            return (
              (orgId && orgId.org_id === value) ||
              !org_ids.some((org_id) => org_id.org_id === value)
            );
          }),
        display_name: Yup.string().trim().required(),
        org_id_description: Yup.string().trim(),
        org_params: Yup.object().shape({
          file_ids: Yup.string().trim(),
          addresses: Yup.string().trim(),
          //@ts-ignore
          org_ids: Yup.array().of<OrgId>(Yup.object()),
        }),
      }),
    [org_ids]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const {
        org_id,
        display_name,
        org_id_description,
        org_params: { file_ids, addresses, org_ids },
      } = validationSchema.cast(values);
      const orgParamsVariables = {
        org_id: Number(org_id),
        file_ids: file_ids ? file_ids.split(",") : undefined,
        addresses: addresses || undefined,
        //@ts-ignore
        org_ids: org_ids.map((el) => el.org_id),
      };
      const variables = {
        org_id: Number(org_id),
        display_name,
        org_id_description,
        org_params:
          orgParamsVariables.file_ids ||
          orgParamsVariables.addresses ||
          orgParamsVariables.org_ids.length
            ? orgParamsVariables
            : (null as any),
      };
      const mutation = orgId ? updateOrgId : insertOrgId;
      //@ts-ignore
      const { errors } = await mutation(variables);
      if (errors?.length) {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
      } else {
        onConfirm();
      }
    },
    [onConfirm]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => (
        <Dialog
          mainTitle={mainTitle}
          onClose={onClose}
          onConfirm={submitForm}
          ConfirmButtonProps={{
            loading: isSubmitting,
          }}
        >
          <Form className={classes.form}>
            <FormTextField name="org_id" label="Id" disabled={Boolean(orgId)} />
            <FormTextField name="display_name" label="Name" />
            <FormTextField
              className={classes.spanedColumn}
              name="org_id_description"
              label="Description"
              multiline
            />
            <FormTextField
              className={classes.spanedColumn}
              name="org_params.addresses"
              label="Addresses"
            />
            <FormTextField
              className={classes.spanedColumn}
              name="org_params.file_ids"
              label="Files Ids"
              multiline
            />
            <FormAutocomplete
              className={classes.spanedColumn}
              name="org_params.org_ids"
              options={org_ids}
              getOptionLabel={(option) => option.display_name}
              isOptionEqualToValue={(option, value) =>
                option.org_id === value.org_id
              }
              multiple
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default OrgIdFormDialog;
