import { gql } from "@apollo/client";
import { useAditUpdatePasswordMutation } from "graphql/generated";

gql`
  mutation AditUpdatePassword($updatePasswordInput: UpdatePasswordDto!) {
    updatePassword(updatePasswordInput: $updatePasswordInput)
  }
`;

export default useAditUpdatePasswordMutation;
