export const download = (value: string | string[]) => {
  const urls = typeof value === "string" ? [value] : value;
  urls.forEach((url) => {
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", "download");
    element.setAttribute("target", "_blank");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
};
