import React from "react";
import { Dialog } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import TenantFormik from "../components/TenantForm/TenantFormik";
import TenantForm from "../components/TenantForm/TenantForm";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

export default ({ onClose, onConfirm }: Props) => {
  const { t } = useTranslation();
  return (
    <TenantFormik onSubmit={onConfirm}>
      {({ submitForm, isSubmitting, isValid, dirty }: any) => (
        <Dialog
          maxWidth="sm"
          fullWidth
          onClose={onClose}
          mainTitle={`${t("create")} ${t("Tenant")}`}
          onConfirm={submitForm}
          ConfirmButtonProps={{
            loading: isSubmitting,
            disabled: isSubmitting || !dirty || !isValid,
          }}
        >
          <TenantForm />
        </Dialog>
      )}
    </TenantFormik>
  );
};
