import React, { FC, HtmlHTMLAttributes } from "react";
import { File } from "domain/file/types";
import { Text } from "components/Text";
import { useMutation } from "@apollo/client";
import { SCHEDULE_FILE } from "graphql/mutations/file";
import { ThreeDotsMenu } from "@periplus/ui-library";

interface ActionsProps extends HtmlHTMLAttributes<HTMLElement> {
  file: File;
}

const Actions: FC<ActionsProps> = ({ file }) => {
  const { file_status, id } = file;
  const [scheduleFile] = useMutation(SCHEDULE_FILE);

  const options = [
    // {
    //   content: (
    //     <>
    //       <ListItemIcon className={classes.listIcon}>
    //         <History />
    //       </ListItemIcon>
    //       <Text>common:history</Text>
    //     </>
    //   ),
    //   action: () => {
    //     history.push(`/select/history/${identification}`);
    //   },
    //   disabled: !identification,
    // },
  ] as any[];

  // options.push({
  //   content: (
  //     <>
  //       <ListItemIcon className={classes.listIcon}>
  //         <GetApp />
  //       </ListItemIcon>
  //       <Text>common:customsDocs</Text>
  //     </>
  //   ),
  //   action: async (close: any) => {
  //     window.open(
  //       await getDownloadUrlAsync(
  //         file_url,
  //         "application/pdf",
  //         `inline; filename="${encodeURI(identification) || "MRS-"}.pdf"`
  //       ),
  //       "_blank"
  //     );
  //     close();
  //   },
  //   disabled: !file_url,
  // });

  if (
    [
      "archived_failed",
      "transmission_failed",
      "identification_request_failed",
      "ready_for_archive",
    ].includes(file_status)
  ) {
    options.push({
      content: (
        <>
          <Text>common:reschedule</Text>
        </>
      ),
      action: async (close: any) => {
        await scheduleFile({
          variables: {
            fileIds: [id],
          },
        });
        close();
      },
      disabled: false,
    });
  }
  return !!options.length && <ThreeDotsMenu options={options} />;
};

export default Actions;
