import gql from "graphql-tag";
import {
  AditGetRolesListQuery,
  useAditGetRolesListQuery,
} from "graphql/generated";

export type RoleListEntity = AditGetRolesListQuery["roles"][number];

gql`
  query AditGetRolesList {
    roles: getAditRoles {
      ...AditRolesListFragment
    }
  }

  fragment AditRolesListFragment on Role {
    id
    name
    isWdRelevant
    description
    translations
  }
`;

export default useAditGetRolesListQuery;
