import React from "react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AuthProvider } from "contexts/Auth/AuthContext";
import Routes from "./Routes";
import { ApolloProvider } from "@apollo/client";
import client from "graphql/client";
import { ApplicationProvider } from "contexts/ApplicationContext";
import { useTranslation } from "react-i18next";
import { UiLibraryProvider, theme, CssBaseline } from "@periplus/ui-library";
import { ThemeProvider } from "@mui/material";
import { LanguageType } from "../i18n";
import { SnackbarProvider } from "contexts/SnackbarContext";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../msalAuthConfig";
import ErrorBoundary from "./ErrorBoundary";

const App: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <ApolloProvider client={client}>
          <SnackbarProvider>
            <AuthProvider>
              <ApplicationProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <UiLibraryProvider language={language as LanguageType}>
                    <ErrorBoundary>
                      <Routes />
                    </ErrorBoundary>
                  </UiLibraryProvider>
                </ThemeProvider>
              </ApplicationProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ApolloProvider>
      </MsalProvider>
    </BrowserRouter>
  );
};

export default Sentry.withProfiler(App);
