import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import {
  AlertDialog,
  Button,
  ListItemIcon,
  ListItemText,
  MRTable,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  ThreeDotsMenu,
  Typography,
} from "@periplus/ui-library";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import usePageSettings from "domain/user/usePageSettings";
import { TableLocalStorage } from "hooks/usePageLocalStorage";
import UserFormDialog from "./UserFormDialog";
import useGetTenantUsers, {
  TenantUserTableEntity,
} from "./hooks/useGetTenantUsers";
import useSendResetPasswordLink from "./hooks/useSendResetPasswordLink";
import CheckIcon from "@mui/icons-material/Check";
import useUpdateTenantUser from "./hooks/useUpdateTenantUser";
import BlockIcon from "@mui/icons-material/Block";
import useGetTenantSettings from "graphql/hooks/useGetTenantSettings";
import { TenantTableEntity } from "../../hooks/useGetTenantsTable";
import useDeleteTenantUser from "./hooks/useDeleteTenantUser";
import { isEqual } from "lodash";
import dayjs from "dayjs";
import config from "config";

interface Props {
  tenant: TenantTableEntity;
}

export default ({ tenant }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [userDialogState, setUserDialogState] = useState<{
    dialog?: "form" | "deletion";
    user?: TenantUserTableEntity;
  }>({});
  const { enqueueSnackbar } = useSnackbar();

  const { pageSettings, setPageSettingsForMRTable } =
    usePageSettings<TableLocalStorage>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { data: tenantConfig } = useGetTenantSettings();
  const {
    data: { users } = {
      users: [],
    },
    loading,
    refetch,
  } = useGetTenantUsers({
    variables: {
      tenant: tenant.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateUser] = useUpdateTenantUser();
  const [sendResetPasswordLink] = useSendResetPasswordLink();
  const [deleteUser, { loading: deleteUserLoading }] = useDeleteTenantUser();

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<TenantUserTableEntity>[], TenantUserTableEntity[]]
  >(
    () => [
      [
        {
          header: t("First Name"),
          id: "firstName",
          accessorFn: (originalRow) => originalRow?.firstName,
        },
        {
          header: t("Last Name"),
          id: "lastName",
          accessorFn: (originalRow) => originalRow?.lastName,
          size: 250,
        },
        {
          header: t("Location"),
          accessorFn: (originalRow) => originalRow?.location,
          id: "location",
        },
        {
          header: t("Roles"),
          id: "roles",
          accessorFn: (originalRow) =>
            originalRow?.roles
              ?.map((role) => role.translations[language])
              .join(", "),
        },
        {
          header: t("Email"),
          id: "email",
          accessorFn: (originalRow) => originalRow?.email,
        },
        {
          header: t("Org Ids"),
          id: "orgIds",
          accessorFn: (originalRow) =>
            originalRow?.orgIds?.map((el) => el?.orgId).join(", "),
          Cell: ({ row: { original: originalRow } }) => (
            <Typography tooltip>
              {originalRow?.orgIds?.map((el) => el?.orgId).join(", ")}
            </Typography>
          ),
        },
        {
          header: t("Blocked"),
          id: "isEnabled",
          filterVariant: "checkbox",
          accessorFn: (originalRow) => !originalRow.isEnabled,
          Cell: ({ row: { original: originalRow } }) =>
            !originalRow.isEnabled && <CheckIcon />,
        },
        {
          header: t("External Auth"),
          id: "externalAuthProvider",
          filterVariant: "checkbox",
          accessorFn: (originalRow) => originalRow.externalAuthProvider,
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.externalAuthProvider && <CheckIcon />,
        },
        {
          header: t("Created at"),
          id: "creationDate",
          accessorFn: (originalRow) =>
            originalRow.creationDate &&
            dayjs(originalRow.creationDate).startOf("day"),
          sortingFn: "datetime",
          filterVariant: "date",
          muiFilterDatePickerProps: {
            format: config.dateFormat,
          },
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.creationDate &&
            dayjs(originalRow.creationDate).format(config.dateFormat),
        },
        {
          header: t("Modified at"),
          id: "lastModifiedDate",
          accessorFn: (originalRow) =>
            originalRow.lastModifiedDate &&
            dayjs(originalRow.lastModifiedDate).startOf("day"),
          sortingFn: "datetime",
          filterVariant: "date",
          muiFilterDatePickerProps: {
            format: config.dateFormat,
          },
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.lastModifiedDate &&
            dayjs(originalRow.lastModifiedDate).format(config.dateFormat),
        },
      ],
      [...users],
    ],
    [t, language, users]
  );

  const [pagination, setPagination] = useState<MRTable.MRT_PaginationState>({
    pageSize: pageSettings.itemsPerPage,
    pageIndex: 0,
  });
  const [columnSizing, setColumnSizing] =
    useState<MRTable.MRT_ColumnSizingState>(pageSettings.columnSizing ?? {});
  const [columnPinning, setColumnPinning] =
    useState<MRTable.MRT_ColumnPinningState>(pageSettings.columnPinning ?? {});
  const [columnVisibility, setColumnVisibility] =
    useState<MRTable.MRT_VisibilityState>(pageSettings.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>(
    (() => {
      const defaultColumnsOrder = columns.map((c) => c.id!);
      const cachedColumnsOrder = pageSettings?.columnOrder;
      if (
        cachedColumnsOrder &&
        isEqual([...defaultColumnsOrder].sort(), [...cachedColumnsOrder].sort())
      ) {
        return cachedColumnsOrder;
      }

      return defaultColumnsOrder;
    })()
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: pagination.pageSize,
        columnSizing,
        columnPinning,
        columnVisibility,
        columnOrder,
      });
    },
    // eslint-disable-next-line
    [
      pagination.pageSize,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <>
      <MRTable.Table
        columns={columns}
        data={tableData}
        initialState={{
          sorting: [{ id: "creationDate", desc: true }],
        }}
        state={{
          pagination,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          isLoading: loading,
        }}
        enableRowActions
        enableColumnFilters
        renderToolbarInternalActions={({ table }) => (
          <>
            <MRTable.MRT_ToggleFiltersButton table={table} />
            <MRTable.MRT_ShowHideColumnsButton table={table} />
            <MRTable.MRT_ToggleFullScreenButton table={table} />
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowRightAltIcon />}
              onClick={() => setUserDialogState({ dialog: "form" })}
            >
              {t("Create New")}
            </Button>
          </>
        )}
        onPaginationChange={setPagination}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        onRefresh={() => {
          refetch();
        }}
        renderRowActions={({ row: { original } }) => (
          <ThreeDotsMenu
            options={[
              {
                content: (
                  <>
                    <ListItemIcon>
                      <CreateOutlinedIcon color="action" />
                    </ListItemIcon>
                    <ListItemText primary={t("Edit")} />
                  </>
                ),
                action: (close) => {
                  close();
                  setUserDialogState({
                    dialog: "form",
                    user: original,
                  });
                },
              },
              {
                ...(original.externalAuthProvider && {
                  disabled: true,
                  tooltip: t(
                    "Not available for users authenticated externally"
                  ),
                }),
                content: (
                  <>
                    <ListItemIcon>
                      <MailOutlinedIcon color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Reset password")}
                      primaryTypographyProps={{
                        sx: {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                  </>
                ),
                action: (close) => {
                  close();
                  sendResetPasswordLink({
                    variables: {
                      email: original.email,
                    },
                  })
                    .then(() => {
                      enqueueSnackbar(t("Success"), {
                        variant: "success",
                      });
                    })
                    .catch(() => {});
                },
              },
              {
                content: (
                  <>
                    <ListItemIcon>
                      <BlockIcon color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(original.isEnabled ? "Block" : "Unblock")}
                    />
                  </>
                ),
                action: (close) => {
                  close();
                  updateUser({
                    variables: {
                      object: {
                        userId: original.id,
                        isEnabled: !original.isEnabled,
                      },
                    },
                  })
                    .then(() => {
                      enqueueSnackbar(t("Success"), {
                        variant: "success",
                      });
                      refetch();
                    })
                    .catch(() => {});
                },
              },
              {
                content: (
                  <>
                    <ListItemIcon>
                      <DeleteOutlineOutlinedIcon color="error" />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Delete")}
                      primaryTypographyProps={{ color: "error" }}
                    />
                  </>
                ),
                action: (close) => {
                  close();
                  setUserDialogState({
                    dialog: "deletion",
                    user: original,
                  });
                },
              },
            ]}
          />
        )}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 162px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            ...(table.getState().isFullScreen && {
              padding: `${theme.spacing(1)}!important`,
            }),
          }),
        })}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableBodyCellProps: {
              sx: {
                justifyContent: "center",
              },
            },
          },
        }}
      />
      {userDialogState?.dialog === "form" && (
        <UserFormDialog
          tenantConfig={tenantConfig}
          user={userDialogState.user}
          tenant={tenant}
          onClose={() => setUserDialogState({})}
          onConfirm={() => {
            setUserDialogState({});
            refetch();
          }}
        />
      )}
      {userDialogState?.dialog === "deletion" && (
        <AlertDialog
          variant="warning"
          onClose={() => setUserDialogState({})}
          onConfirm={() => {
            deleteUser({
              variables: {
                id: userDialogState.user!.id,
              },
            })
              .then(() => {
                enqueueSnackbar(t("Success"), {
                  variant: "success",
                });
                refetch();
              })
              .catch(() => {})
              .finally(() => {
                setUserDialogState({});
              });
          }}
          ConfirmButtonProps={{ loading: deleteUserLoading }}
        >
          {t("Are you sure you want to delete this user?")}
        </AlertDialog>
      )}
    </>
  );
};
