import { AutocompleteProps } from "@periplus/ui-library";
import {
  OrgIdListEntity,
  useGetOrgIdsList,
  useGetUserOrgIdsList,
} from "./useGetOrgIdsList";
import { StrictOmit } from "utils/utilityTypes";

interface UserOrgIdAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends AutocompleteProps<
    OrgIdListEntity,
    Multiple,
    DisableClearable,
    false
  > {
  userOrgIdsOnly?: boolean;
}

export interface UserOrgIdAutocompleteHookProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends StrictOmit<
    UserOrgIdAutocompleteProps<Multiple, DisableClearable>,
    "options"
  > {}

export default <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  userOrgIdsOnly,
  ...rest
}: UserOrgIdAutocompleteHookProps<
  Multiple,
  DisableClearable
>): UserOrgIdAutocompleteProps<Multiple, DisableClearable> => {
  const { data: { orgIds } = { orgIds: [] }, loading: loadingOrgIds } =
    useGetOrgIdsList({
      skip: userOrgIdsOnly,
    });

  const {
    data: { userOrgIds } = { userOrgIds: [] },
    loading: loadingUserOrgIds,
  } = useGetUserOrgIdsList({
    skip: !userOrgIdsOnly,
  });

  return {
    options: userOrgIdsOnly ? userOrgIds : orgIds,
    loading: userOrgIdsOnly ? loadingUserOrgIds : loadingOrgIds,
    getOptionLabel: (option) => `${option.orgId}: ${option.orgIdDescription}`,
    isOptionEqualToValue: (option, value) => option.orgId === value.orgId,
    filterSelectedOptions: true,
    ...rest,
  };
};
