import gql from "graphql-tag";
import { useAditSendResetPasswordLinkMutation } from "graphql/generated";

gql`
  mutation AditSendResetPasswordLink($email: String!) {
    sendAditResetPasswordLink(email: $email)
  }
`;

export default useAditSendResetPasswordLinkMutation;
