import React, { FC, useCallback, useMemo } from "react";
import {
  FormTextField,
  FormDatePicker,
  Typography,
  Dialog,
  Theme,
} from "@periplus/ui-library";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { IApiKey, IApiKeyScope } from "graphql/hooks/useGetApiKeys";
import ScopeCheckbox from "./ScopeCheckbox";
import { makeStyles } from "tss-react/mui";
import dayjs from "dayjs";
import { getAccessToken } from "graphql/client";

const useStyles = makeStyles()((theme: Theme) => ({
  cancelButtonLabel: {
    fontWeight: 500,
    color: "#828282",
  },
  divider: {
    color: "#eeeeee",
  },
  closeButton: {
    color: "#737A96",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 8px 8px 16px",
  },
  dialogContent: {
    padding: "24px 12px",
    [theme.breakpoints.up("sm")]: {
      padding: "40px 32px 24px 32px",
    },
  },
  input: {
    marginTop: 8,
    width: "100%",
    maxWidth: 210,
  },
}));

const AccessTokenDialog: FC<{
  accessTokens: IApiKey[];
  accessToken?: IApiKey;
  onClose: () => void;
  onSubmit: (newApiKey: string) => void;
}> = ({ accessTokens, accessToken, onClose, onSubmit }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required()
          .test(
            "nameDuplication",
            t("settingsAccessTokens:nameDuplicationError"),
            (value) => !accessTokens.some((el) => el.display_name === value)
          ),
      }),
    []
  );

  const initialValues = useMemo(() => {
    const defaultScopes = {
      "adit-api:call": true,
      "adit-api:import_edec_data": false,
      "adit-api:create_document": false,
      "adit-api:update_file_status": false,
    };
    return accessToken
      ? {
          name: accessToken.display_name,
          expiresAt: dayjs(accessToken.meta.expiresAt),
          scopes: (accessToken.meta.scopes.split(" ") as IApiKeyScope[]).reduce(
            (acc, scope) => {
              acc[scope] = true;
              return acc;
            },
            { ...defaultScopes }
          ),
        }
      : {
          name: "",
          expiresAt: null,
          scopes: defaultScopes,
        };
  }, []);
  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    const accessToken = await getAccessToken();
    const { apiKey } = await (
      await fetch("https://staging.swagger.api.adit.periplus.ch/api/api-key", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name: values.name,
          expiresAt: values.expiresAt,
          scopes: Object.keys(values.scopes)
            .filter((scope) => values.scopes[scope])
            .join(" "),
        }),
      })
    ).json();
    onSubmit(apiKey);
    setSubmitting(false);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => {
        const handleChangeScope = (scope: IApiKeyScope, value: boolean) =>
          setFieldValue("scopes", { ...values.scopes, [scope]: value });
        return (
          <Dialog
            mainTitle={`${t(accessToken ? "edit" : "new")}: ${t(
              "settingsAccessTokens:accessToken"
            )}`}
            onClose={onClose}
            onConfirm={submitForm}
            maxWidth="xs"
            ConfirmButtonProps={{
              disabled: isSubmitting,
              loading: isSubmitting,
            }}
          >
            <Form>
              <Typography variant="subtitle1" color="textSecondary">
                {t("name")}
              </Typography>
              <FormTextField name="name" className={classes.input} />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginTop: 16 }}
              >
                {t("settingsAccessTokens:expiresAt")}
              </Typography>
              <FormDatePicker
                name="expiresAt"
                className={classes.input}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                }}
              />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginTop: 16 }}
              >
                {t("settingsAccessTokens:scopes")}
              </Typography>
              <div style={{ marginTop: 8 }}>
                <ScopeCheckbox
                  scope="adit-api:call"
                  checked={values.scopes["adit-api:call"]}
                  description={t(
                    "settingsAccessTokens:scope_description_adit-api_call"
                  )}
                  disabled
                />
                <ScopeCheckbox
                  scope="adit-api:import_edec_data"
                  checked={values.scopes["adit-api:import_edec_data"]}
                  description={t(
                    "settingsAccessTokens:scope_description_adit-api_import_edec_data"
                  )}
                  onChange={(checked) =>
                    handleChangeScope("adit-api:import_edec_data", checked)
                  }
                />
                <ScopeCheckbox
                  scope="adit-api:create_document"
                  checked={values.scopes["adit-api:create_document"]}
                  description={t(
                    "settingsAccessTokens:scope_description_adit-api_create_document"
                  )}
                  onChange={(checked) =>
                    handleChangeScope("adit-api:create_document", checked)
                  }
                />
                <ScopeCheckbox
                  scope="adit-api:update_file_status"
                  checked={values.scopes["adit-api:update_file_status"]}
                  description={t(
                    "settingsAccessTokens:scope_description_adit-api_update_file_status"
                  )}
                  onChange={(checked) =>
                    handleChangeScope("adit-api:update_file_status", checked)
                  }
                />
              </div>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AccessTokenDialog;
