import gql from "graphql-tag";
import { useAditCreatTenantUserMutation } from "graphql/generated";

gql`
  mutation aditCreatTenantUser($object: AditUserDto!) {
    createAditUser(createUserInput: $object)
  }
`;

export default useAditCreatTenantUserMutation;
