import React from "react";
import { FormAutocomplete } from "@periplus/ui-library";
import useAutocomplete, {
  RoleAutocompleteHookProps as AutocompleteHookProps,
} from "./useRoleAutocomplete";
import { StrictOmit } from "utils/utilityTypes";

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends StrictOmit<
    AutocompleteHookProps<Multiple, DisableClearable>,
    "value"
  > {
  name: string;
}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ name, ...rest }: Props<Multiple, DisableClearable>) {
  const props = useAutocomplete(rest);

  return <FormAutocomplete name={name} {...props} />;
}
