import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { APPEND_META_DOCUMENT } from "graphql/mutations/document";

const useAppendDocumentMeta = () => {
  const [appendDocumentMeta] = useMutation(APPEND_META_DOCUMENT);

  return useCallback(
    (docId: string, updated: { [key: string]: any }) => {
      return appendDocumentMeta({
        variables: {
          id: docId,
          meta: updated,
        },
      });
    },
    [appendDocumentMeta]
  );
};

export default useAppendDocumentMeta;
