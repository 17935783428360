import React, { FC } from "react";
import { Loader } from "@periplus/ui-library";
import { RouteChildrenProps } from "react-router";
import { Switch, Redirect } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
//import ImportListTriggers from "./ImportListTriggers/ImportListTriggersList";
import TenantsTable from "./Tenants/TenantsTable";
import DocumentTypeTemplates from "./DocumentTypeTemplates";
import AccessTokens from "./AccessTokens";
import AddressManagement from "./AdressManagement";
import OrgIds from "./OrgIds";
import RetrainModel from "./RetrainModel";
import { Role, useAuth } from "contexts/Auth/AuthContext";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Tenant from "./Tenants/Tenant";
import Layout from "components/Layout";
import { NavigationGroup } from "components/Layout/Layout";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ChangePassword from "./ChangePassword/ChangePassword";

const SelectTabs: FC<RouteChildrenProps> = ({ match }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const translate = (text: string) => t(`navigation:${text}`);

  const menuItems: NavigationGroup[] = [
    {
      groupTitle: translate("profile"),
      Icon: PersonOutlinedIcon,
      items: [
        {
          text: translate("change-password"),
          redirecting: "/settings/change-password",
        },
      ],
    },
    ...(user?.hasAllowedRoles([Role.Admin])
      ? [
          {
            groupTitle: translate("general"),
            Icon: SettingsOutlinedIcon,
            items: [
              {
                text: translate("tenants"),
                redirecting: "/settings/tenants",
              },
              // {
              //   text: translate("import-list-triggers"),
              //   redirecting: "/settings/import-list-triggers",
              // },
              {
                text: translate("access-tokens"),
                redirecting: "/settings/access-tokens",
              },
              {
                text: "OrgIds",
                redirecting: "/settings/org-ids",
              },
            ],
          },
          {
            groupTitle: translate("documentClasses"),
            Icon: LibraryBooksOutlinedIcon,

            items: [
              {
                text: translate("address-management"),
                redirecting: "/settings/address-management",
              },
              {
                text: translate("classifier-templates"),
                redirecting: "/settings/classifier-templates",
              },
              {
                text: translate("ai-settings"),
                redirecting: "/settings/retrain-model",
              },
            ],
          },
        ]
      : []),
  ];

  if (!match) {
    return <Loader />;
  }

  return (
    <Layout navigation={menuItems}>
      <Switch>
        <PrivateRoute
          path="/settings"
          exact
          component={() => (
            <Redirect
              to={get(menuItems, "[0].items[0].redirecting") as string}
            />
          )}
        />
        <PrivateRoute
          path={`${match.path}/change-password`}
          exact
          component={ChangePassword}
        />
        <PrivateRoute
          path={`${match.path}/tenants`}
          exact
          component={TenantsTable}
        />
        {/* <PrivateRoute
          path={`${match.path}/import-list-triggers`}
          exact
          component={ImportListTriggers}
        /> */}
        <PrivateRoute path={`${match.path}/tenants/:id`} component={Tenant} />
        <PrivateRoute
          path={`${match.path}/address-management`}
          component={AddressManagement}
        />
        <PrivateRoute
          path={`${match.path}/classifier-templates`}
          component={DocumentTypeTemplates}
        />
        <PrivateRoute
          path={`${match.path}/access-tokens`}
          component={AccessTokens}
        />
        <PrivateRoute path={`${match.path}/org-ids`} component={OrgIds} />
        <PrivateRoute
          path={`${match.path}/retrain-model`}
          component={RetrainModel}
        />
      </Switch>
    </Layout>
  );
};

export default SelectTabs;
