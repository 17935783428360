import { AutocompleteProps } from "@periplus/ui-library";
import { StrictOmit } from "utils/utilityTypes";
import useGetRolesList, { RoleListEntity } from "./useGetRolesList";
import { useTranslation } from "react-i18next";

interface RoleAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends AutocompleteProps<
    RoleListEntity,
    Multiple,
    DisableClearable,
    false
  > {}

export interface RoleAutocompleteHookProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends StrictOmit<
    RoleAutocompleteProps<Multiple, DisableClearable>,
    "options"
  > {}

export default <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  ...rest
}: RoleAutocompleteHookProps<
  Multiple,
  DisableClearable
>): RoleAutocompleteProps<Multiple, DisableClearable> => {
  const {
    i18n: { language },
  } = useTranslation();
  const { data: { roles: options } = { roles: [] }, loading } =
    useGetRolesList();

  return {
    options,
    loading,
    getOptionLabel: (option) => option.translations[language],
    isOptionEqualToValue: (option, value) => option.id === value.id,
    ...rest,
  };
};
