import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  HtmlHTMLAttributes,
} from "react";
import {
  Switch,
  DatePickerRange,
  FilterMenu as LuiFilterMenu,
  Typography,
  Select,
  ToggleButtonGroup,
  Theme,
  Grid,
  Box,
  MenuItem,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import { useAuth } from "contexts/Auth/AuthContext";
import { useTranslation } from "react-i18next";
import { Filters } from "./Finished";
import { makeStyles } from "tss-react/mui";
import OrgIdAutocomplete from "domain/orgId/components/OrgIdAutocomplete";
import {
  OrgIdListEntity,
  useGetOrgIdsListLazy,
} from "domain/orgId/components/OrgIdAutocomplete/useGetOrgIdsList";
import { Overwrite } from "utils/utilityTypes";

interface FilterMenuProps
  extends Omit<HtmlHTMLAttributes<HTMLElement>, "onChange"> {
  filters: Filters;
  onChange: (newFilters: Filters | undefined) => void;
  onClear: () => void;
}

type FiltersForm = Overwrite<Filters, { orgIds: OrgIdListEntity[] }>;

type StatusType = "archived" | "processing" | "failed";

const statuses = {
  archived: {
    color: "rgba(46,231,175,0.7)",
  },
  processing: {
    color: "rgba(255,153,0,0.7)",
  },
  failed: {
    color: "rgba(230,28,28,0.7)",
  },
};

const convertFiltersToLocalState = (filters: Filters): FiltersForm => ({
  ...filters,
  orgIds: [],
});

const useStyles = makeStyles()((theme: Theme) => ({
  row: {
    maxWidth: 452,
    "&:not(:last-child)": {
      marginBottom: 24,
    },
  },
  filtersGroup: {
    maxWidth: "210px!important",
  },
  filtersGroupContent: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
    minHeight: 30,
  },
  label: {},
  statusChip: {
    display: "flex",
    alignItems: "baseline",
  },
  statusChipDot: {
    width: 9,
    height: 9,
    borderRadius: "50%",
    marginRight: 6,
  },
  statusChipLabel: {
    fontSize: 12,
  },
}));

const FilterMenu: FC<FilterMenuProps> = ({ filters, onChange, onClear }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { tenantConfig } = useAuth();

  const [state, setState] = useState<FiltersForm>(
    convertFiltersToLocalState(filters)
  );

  const [getInitialOrgIds, { loading: initialOrgIdsLoading }] =
    useGetOrgIdsListLazy();
  useEffect(() => {
    setState(convertFiltersToLocalState(filters));
    if (filters.orgIds?.length) {
      getInitialOrgIds({
        variables: {
          ids: filters.orgIds,
        },
      }).then(({ data }) => {
        setState((prev) => ({
          ...prev,
          orgIds: data?.orgIds ?? [],
        }));
      });
    }

    // eslint-disable-next-line
  }, [filters]);

  const handleConfirm = () =>
    onChange({ ...state, orgIds: state.orgIds.map((el) => el.orgId) });

  const handleCancel = () => setState(convertFiltersToLocalState(filters));

  const handleClear = () => onClear();

  const activeCount = useMemo(() => {
    let result = 0;
    if (tenantConfig?.UseJourneys?.value && !filters.files) result++;
    if (tenantConfig?.UseJourneys?.value && !filters.journey) result++;
    if (filters.myFilesOnly) result++;
    if (filters.status?.length) result++;
    if (filters.orgIds?.length) result++;
    if (
      filters.startDate &&
      !dayjs(filters.startDate).isSame(
        dayjs().subtract(2, "week").startOf("day").format()
      )
    )
      result++;
    if (
      filters.endDate &&
      !dayjs(filters.endDate).isSame(dayjs().endOf("day").format())
    )
      result++;
    return result;
  }, [tenantConfig?.UseJourneys?.value, state]);

  const renderStatusChip = (status: StatusType) => (
    <div className={classes.statusChip} key={status}>
      <div
        className={classes.statusChipDot}
        style={{
          backgroundColor: statuses[status].color,
        }}
      />
      <Typography
        className={classes.statusChipLabel}
        variant="subtitle1"
        style={{
          color: statuses[status].color,
        }}
      >
        {t(status)}
      </Typography>
    </div>
  );

  const toggleData = [
    {
      label: t("classify:allEntries"),
      value: true,
    },
    {
      label: t("classify:myEntries"),
      value: false,
    },
  ];

  return (
    <LuiFilterMenu
      activeCount={activeCount}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onClear={handleClear}
    >
      <Grid className={classes.row} container>
        {tenantConfig?.UseJourneys?.value && (
          <Grid
            className={classes.filtersGroup}
            item
            xs
            style={{ marginRight: 32 }}
          >
            <Typography
              className={classes.label}
              variant="overline"
              color="textSecondary"
            >
              {t("classify:fileType")}
            </Typography>
            <Box className={classes.filtersGroupContent}>
              <Switch
                label={t("classify:files")}
                checked={Boolean(state.files)}
                onToggle={(event) =>
                  setState((prev) => ({ ...prev, files: event.target.checked }))
                }
              />
              <Switch
                label={t("classify:journey")}
                checked={Boolean(state.journey)}
                onToggle={(event) =>
                  setState((prev) => ({
                    ...prev,
                    journey: event.target.checked,
                  }))
                }
                style={{ marginLeft: 16 }}
              />
            </Box>
          </Grid>
        )}
        <Grid className={classes.filtersGroup} item xs>
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:display")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <ToggleButtonGroup
              value={Boolean(state.myFilesOnly)}
              exclusive
              onChange={(e, value) =>
                setState((prev) => ({ ...prev, myFilesOnly: value }))
              }
              options={toggleData}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.row} container>
        <Grid
          className={classes.filtersGroup}
          item
          xs
          style={{ marginRight: 32 }}
        >
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:status")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <Select
              clearable
              value={state.status || []}
              onChange={(value) =>
                setState((prev) => ({ ...prev, status: value }))
              }
              variant="outlined"
              MenuProps={{
                variant: "menu",
              }}
              multiple
              renderValue={(selectedOptions) => {
                return (
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                    }}
                  >
                    {(selectedOptions as StatusType[]).map((selectedOption) => {
                      return renderStatusChip(selectedOption);
                    })}
                  </div>
                );
              }}
              style={{ width: "100%" }}
            >
              {Object.keys(statuses).map((statusesKey) => (
                <MenuItem value={statusesKey} key={statusesKey}>
                  {renderStatusChip(statusesKey as StatusType)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid className={classes.filtersGroup} item xs>
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:orgId")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <OrgIdAutocomplete
              userOrgIdsOnly
              value={state.orgIds}
              onChange={(_, newValue) => {
                setState((prev) => ({
                  ...prev,
                  orgIds: newValue,
                }));
              }}
              loading={initialOrgIdsLoading}
              multiple
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.row} container>
        <Grid item xs>
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:dateRange")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <DatePickerRange
              startDate={
                state.startDate
                  ? dayjs(state.startDate)
                  : dayjs().subtract(2, "week").startOf("day")
              }
              endDate={
                state.endDate ? dayjs(state.endDate) : dayjs().endOf("day")
              }
              onChange={({ startDate, endDate }) =>
                setState((prev) => ({
                  ...prev,
                  startDate: !startDate
                    ?.startOf("day")
                    .isSame(dayjs().subtract(2, "week").startOf("day"))
                    ? startDate?.format()
                    : undefined,
                  endDate: !endDate?.endOf("day").isSame(dayjs().endOf("day"))
                    ? endDate?.format()
                    : undefined,
                }))
              }
            />
          </Box>
        </Grid>
      </Grid>
    </LuiFilterMenu>
  );
};

export default FilterMenu;
