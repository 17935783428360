import { useMutation } from "@apollo/client";
import { TRANSMIT_FILE } from "graphql/mutations/file";
import { FileFlags } from "graphql/hooks/useGetNotifications";
import { DeclarationState } from "pages/Select/Classify/components/FileDialog";

export enum FileType {
  File = 0,
  Journey = 1,
  Folder = 2,
  Email = 4,
  Declaration = 8,
}

export type ComingDocumentType = {
  pages: string[];
  document_type: string;
  flags: number;
  document_flags?: number;
  manual_annotation_due_date?: string;
  id?: string;
};

export type ComingFileState = {
  fileId?: string;
  fileNumber: string;
  documents: ComingDocumentType[];
  orgId?: number;
  type: number;
  folderName?: string;
  notify_after_extraction: string[];
  declaration_creation_assigned_to: string;
  scheduleFileExport?: boolean;
  declarationData?: DeclarationState;
};

const useTransmitFile = () => {
  const [transmitFile] = useMutation(TRANSMIT_FILE);

  const callback = (
    variables: ComingFileState[],
    keepDocuments: string[],
    originalDocumentIds: string[],
    documentsToDelete: string[],
    confirmClassification: boolean
  ) => {
    const files = variables.map(
      ({
        fileId,
        documents,
        fileNumber,
        orgId,
        type,
        folderName,
        notify_after_extraction,
        declaration_creation_assigned_to,
        scheduleFileExport,
        declarationData,
      }) => {
        let declaration = null;
        if (declarationData) {
          declaration = {
            payerAddressId: declarationData?.payerAddress?.id,
            consignorAddressId: declarationData?.consignorAddress?.id,
            consigneeAddressId: declarationData?.consigneeAddress?.id,
            importerAddressId: declarationData?.importerAddress?.id,
            totalGrossWeight: declarationData?.totalGrossWeight,
            totalColis: declarationData?.totalColis,
            payerName: declarationData?.payerName,
            etaDate: declarationData?.etaDate,
            payerReference: declarationData?.payerReference,
            customsOfficeNumber:
              declarationData?.customsOffice?.number?.toString(),
          };
        }
        return {
          flags: type,
          fileNumber: fileNumber || "",
          ...(type === FileType.Declaration ? { fileId } : {}),
          orgId,
          folderName,
          notify_after_extraction,
          declaration_creation_assigned_to,
          documents: documents.map(
            ({
              document_type,
              flags,
              document_flags,
              pages,
              manual_annotation_due_date,
            }) => ({
              documentType: document_type,
              flags:
                ((flags | (document_flags || 0)) as number) |
                (!!folderName ? FileFlags.Folder : FileFlags.Default),
              selectedPages: pages,
              manual_annotation_due_date,
            })
          ),
          scheduleFileExport,
          existingDocumentIds: originalDocumentIds,
          documentsToDelete: documentsToDelete,
          declarationData: declaration,
          reviewedAndVerified: confirmClassification,
        };
      }
    );
    return transmitFile({
      variables: { data: { files, keepDocuments } },
    });
  };

  return callback;
};

export default useTransmitFile;
