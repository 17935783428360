import { useMutation } from "@apollo/client";
import { UPDATE_ADDRESS } from "graphql/mutations/address";

import { PartialAddress } from "graphql/hooks/useGetAddress";

const useUpdateAddress = () => {
  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  const handleUpdate = (id: number, values: PartialAddress) => {
    return updateAddress({
      variables: { id, values },
    });
  };

  return handleUpdate;
};

export default useUpdateAddress;
