import {
  Form,
  FormSubmitButton,
  FormTextField,
  PageContainer,
} from "@periplus/ui-library";
import { Formik } from "formik";
import useUpdatePassword from "./hooks/useUpdatePassword";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useAuth } from "contexts/Auth/AuthContext";
import { passwordRegex } from "utils/password";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

function ChangePassword() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [updateUser] = useUpdatePassword();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().trim().required(),
    newPassword: Yup.string()
      .trim()
      .required()
      .matches(passwordRegex, t("validation:passwordPolicy")),
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref("newPassword"), null], t("validation:matchPassword"))
      .required(),
  });

  const handleSubmit = async (values: any) => {
    const castedValues = validationSchema.cast(values) as typeof initialValues;

    updateUser({
      variables: {
        updatePasswordInput: {
          id: auth.user!.userId,
          oldPassword: castedValues.oldPassword,
          newPassword: castedValues.newPassword,
        },
      },
    })
      .then(() => {
        enqueueSnackbar("Password Changed Successfully", {
          variant: "success",
        });
      })
      .catch(() => {});
  };

  return (
    <PageContainer title={t("navigation:change-password")}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {() => (
          <Form
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              [theme.breakpoints.up("xs")]: {
                width: "100%",
              },

              [theme.breakpoints.up("md")]: {
                width: "33.3%",
              },
            })}
          >
            <FormTextField
              name={"oldPassword"}
              variant="outlined"
              label={t("common:oldPassword")}
              type="password"
              fullWidth
            />
            <FormTextField
              name={"newPassword"}
              variant="outlined"
              label={t("common:newPassword")}
              type="password"
              fullWidth
            />
            <FormTextField
              name={"confirmPassword"}
              variant="outlined"
              fullWidth
              label={t("common:confirmPassword")}
              type="password"
            />
            <FormSubmitButton
              endIcon={<SaveOutlinedIcon />}
              unsavedChanges={false}
              sx={{
                ml: "auto",
              }}
            />
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
}

export default ChangePassword;
