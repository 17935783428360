import { useState, useEffect } from "react";
import client, { createApolloContext } from "graphql/client";
import {
  AditGetSasTokenQuery,
  AditGetSasTokenQueryVariables,
} from "graphql/generated";
import { gql } from "@apollo/client";

export const GET_SAS_TOKEN = gql`
  query AditGetSASToken(
    $container: String!
    $contentType: String
    $contentDisposition: String
  ) {
    getSASToken(
      container: $container
      contentType: $contentType
      contentDisposition: $contentDisposition
    ) {
      container
      sasToken
      expiryDate
    }
  }
`;

type SasToken = { token: string; expiry: number };

const getSasTokenCache = (cacheKey: string): SasToken | undefined =>
  (
    JSON.parse(
      window.sessionStorage.getItem("sasTokensCache") || "{}"
    ) as Record<string, SasToken>
  )[cacheKey];
const setSasTokenCache = (cacheKey: string, newSasToken: SasToken) => {
  window.sessionStorage.setItem(
    "sasTokensCache",
    JSON.stringify({
      ...JSON.parse(window.sessionStorage.getItem("sasTokensCache") || "{}"),
      [cacheKey]: newSasToken,
    })
  );
};

const getSasToken = async ({
  container,
  contentType,
  contentDisposition,
}: AditGetSasTokenQueryVariables) => {
  const cacheKey = JSON.stringify({
    container,
    contentType,
    contentDisposition,
  });

  const cachedToken = getSasTokenCache(cacheKey);
  if (cachedToken && cachedToken.expiry > Date.now()) {
    return cachedToken;
  }

  const {
    data: { getSASToken },
  } = await client.query<AditGetSasTokenQuery, AditGetSasTokenQueryVariables>({
    query: GET_SAS_TOKEN,
    fetchPolicy: "no-cache",
    variables: {
      container,
      contentType,
      contentDisposition,
    },
    context: createApolloContext({
      disableErrorNotification: true,
    }),
  });

  const safetyExpirationMargin = 1 * 10 * 1000;
  const newSasToken: SasToken = {
    token: getSASToken!.sasToken!,
    expiry:
      new Date(getSASToken!.expiryDate!).getTime() - safetyExpirationMargin,
  };
  setSasTokenCache(cacheKey, newSasToken);

  return newSasToken;
};

export const getDownloadUrl = async ({
  url,
  contentType,
  contentDisposition,
}: {
  url: string;
  contentType?: AditGetSasTokenQueryVariables["contentType"];
  contentDisposition?: AditGetSasTokenQueryVariables["contentDisposition"];
}) => {
  const sasToken = await getSasToken({
    container: url.split("/")[3],
    /* as browsers can't open non-PDF's ignoring this setting */
    contentType: url.split(".").pop() === "pdf" ? contentType : undefined,
    contentDisposition,
  });

  return `${url}?${sasToken.token}`;
};

export const useSasToken = ({
  container,
  contentType,
  contentDisposition,
}: AditGetSasTokenQueryVariables) => {
  const cacheKey = JSON.stringify({
    container,
    contentType,
    contentDisposition,
  });
  const [sasToken, setSasToken] = useState(getSasTokenCache(cacheKey)?.token);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const startSasTokenGeneration = async () => {
      const newSasToken = await getSasToken({
        container,
        contentType,
        contentDisposition,
      });
      setSasToken(newSasToken.token);
      timeoutId = setTimeout(() => {
        startSasTokenGeneration();
      }, newSasToken.expiry - Date.now());
    };

    startSasTokenGeneration();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [cacheKey]);

  return sasToken;
};
