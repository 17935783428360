import { useCallback, useEffect, useMemo, useState } from "react";
import { createWorker } from "tesseract.js";
import { useMutation } from "@apollo/client";
import client from "graphql/client";
import {
  DELETE_DATAPOINT,
  UPDATE_PAGE_EXTRACT_VALUE,
  UPSERT_PAGE_EXTRACT_VALUE,
} from "../mutations/page";
import { DataPoint, Document } from "./useGetValidationDocument";
import { EXTRACT_TEXT } from "../queries/page";

const recognizeText = async (
  image: string,
  rectangle: { top: number; left: number; width: number; height: number }
) => {
  const worker = createWorker({});
  try {
    await worker.load();
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    const {
      data: { text },
    } = await worker.recognize(image, {
      rectangle,
    });
    return text;
  } catch (error) {
    console.error(error);
  } finally {
    worker.terminate();
  }
};

interface IUseDataPointsMutationsOptions {
  document?: Document;
  extractTextFrontend?: boolean;
  extract_type_id?: number;
}

const useDataPointsMutations = ({
  document,
  extractTextFrontend,
  extract_type_id,
}: IUseDataPointsMutationsOptions) => {
  const [dataPoints, setDataPoints] = useState<{
    [key: number]: { [key: string]: DataPoint };
  }>({});

  useEffect(() => {
    setDataPoints(
      document?.pages?.reduce((pagesAcc, page, pageIndex) => {
        pagesAcc[pageIndex] = page.pageExtracts[0]?.["dataPoints"] || {};
        return pagesAcc;
      }, {} as { [key: number]: { [key: number]: DataPoint } }) || {}
    );
  }, [document]);

  const [deleteDataPoint] = useMutation(DELETE_DATAPOINT);
  const [updatePageExtractValue] = useMutation(UPDATE_PAGE_EXTRACT_VALUE);
  const [upsertPageExtractValue] = useMutation(UPSERT_PAGE_EXTRACT_VALUE);

  const extractDataPoint = useCallback(
    async (
      newDataPoint: DataPoint,
      page: any
    ): Promise<DataPoint | undefined> => {
      if (!document) return;
      const { id: pageId, thumbnailBigUrl } = page;
      const [left, top, width, height] = newDataPoint.coordinates;
      let text;
      let dataPointsInside;
      if (extractTextFrontend) {
        text = await recognizeText(thumbnailBigUrl, {
          top,
          left,
          width,
          height,
        });
      } else {
        const response = await client.query({
          query: EXTRACT_TEXT,
          variables: {
            pageId,
            x: left,
            y: top,
            width,
            height,
            extract_type_id: extract_type_id,
          },
        });
        const jsonValue =
          response.data.pp_page_extract_text_fragment_v2[0].json_value;
        text = jsonValue?.val || "";
        dataPointsInside = jsonValue?.id_ist;
      }
      if (!text) return;
      return {
        ...newDataPoint,
        value: text,
        manual: true,
        dataPointsInside,
      };
    },
    [document, extractTextFrontend, extract_type_id]
  );

  const removeDataPoint = useCallback(
    async (dataPoint: DataPoint, page: any, currentPageIndex?: number) => {
      setDataPoints((prev) => {
        const pageIndex = currentPageIndex ? currentPageIndex : 0;
        const targetedDataPoints = prev[pageIndex];

        deleteDataPoint({
          variables: {
            dataPointId: dataPoint["id"],
            pageId: page.id,
            extract_type_id: extract_type_id,
          },
        });

        if (dataPoint.dataPointsInside) {
          const val: { [key: string]: DataPoint } = {};
          for (const dp of Object.values(dataPoint.dataPointsInside)) {
            if (targetedDataPoints[dp]) {
              delete targetedDataPoints[dp].parentDataPointId;
              val[dp] = targetedDataPoints[dp];
            }
          }
          upsertPageExtractValue({
            variables: {
              value: val,
              extract_type_id: extract_type_id,
              pageId: page.id,
            },
          });
        }

        delete targetedDataPoints[dataPoint["id"]];

        const newState = {
          ...prev,
          [pageIndex]: targetedDataPoints,
        };
        return newState;
      });
    },
    [deleteDataPoint, extract_type_id, dataPoints]
  );

  const updateDataPoint = useCallback(
    async (dataPoint: DataPoint, page: any, currentPageIndex?: number) => {
      setDataPoints((prev) => {
        const pageIndex = currentPageIndex ? currentPageIndex : 0;
        const targetedDataPoints = prev[pageIndex];

        targetedDataPoints[dataPoint["id"]] = dataPoint;
        const val: { [key: string]: DataPoint } = {};
        val[dataPoint["id"]] = dataPoint;
        updatePageExtractValue({
          variables: {
            value: val,
            extract_type_id: extract_type_id,
            pageId: page.id,
          },
        });
        const newState = {
          ...prev,
          [pageIndex]: targetedDataPoints,
        };
        return newState;
      });
    },
    [updatePageExtractValue, extract_type_id]
  );

  const updateDataPointAndInside = useCallback(
    (
      dataPoints: DataPoint[],
      page: any,
      dataPointsInside?: { [key: string]: DataPoint },
      currentPageIndex?: number,
      clearAreaAnnotations?: boolean
    ) => {
      setDataPoints((prev) => {
        const pageIndex = currentPageIndex ? currentPageIndex : 0;
        const targetedDataPoints = { ...prev[pageIndex] };
        const val: { [key: string]: DataPoint } = {};

        dataPoints.forEach((dataPoint) => {
          targetedDataPoints[dataPoint["id"]] = dataPoint;
          if (dataPointsInside) {
            for (const dp of Object.values(dataPointsInside)) {
              if (clearAreaAnnotations) {
                dp.type = undefined;
                dp.lineItemType = undefined;
                dp.parentDataPointId = undefined;
              } else {
                dp.type = dataPoint["type"];
                dp.lineItemType = dataPoint["lineItemType"];
                dp.parentDataPointId = dataPoint["parentDataPointId"];
              }
              targetedDataPoints[dp["id"]] = dp;
              val[dp["id"]] = dp;
            }
          }

          val[dataPoint["id"]] = dataPoint;
        });

        upsertPageExtractValue({
          variables: {
            value: val,
            extract_type_id: extract_type_id,
            pageId: page.id,
          },
        });
        const newState = {
          ...prev,
          [pageIndex]: targetedDataPoints,
        };
        return newState;
      });
    },
    [extract_type_id, upsertPageExtractValue]
  );

  return useMemo(() => {
    return {
      dataPoints: Object.values(dataPoints).reduce((acc, pageLevelValue) => {
        const dpList = pageLevelValue;
        const result = [];
        for (const value of Object.values(dpList)) {
          result.push(value);
        }
        acc.push(result);
        return acc;
      }, [] as any[][]) as DataPoint[][],
      extractDataPoint,
      removeDataPoint,
      updateDataPoint,
      updateDataPointAndInside,
    };
  }, [
    dataPoints,
    removeDataPoint,
    updateDataPoint,
    extractDataPoint,
    updateDataPointAndInside,
    extract_type_id,
  ]);
};

export default useDataPointsMutations;
