/**
 * Contains Role Based Access Control (RBAC) rules
 * Insipired by: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 */

import { Role } from "contexts/Auth/AuthContext";

/**
 * Role constants
 * TODO(df): Should use the generated types from GraphQL (the auth/user-mgt endpoint should query the management API to get the currently defined roles)!
 */

/**
 * Permission constants
 * Note the "syntax" with the double __ as a seperator between resource and verb
 */
export enum Permission {
  DECLARATION_VIEW__NAVIGATE = "declarationView:navigate",
  DECLARATION_CREATE__NAVIGATE = "declarationCreate:navigate",
}

export interface IRule {
  static?: Permission[];
  dynamic?: any; // TODO(df): Type
}

export type TRules<R> = Partial<{ [key in Role]: R }>;

const rules: TRules<IRule> = {
  [Role.AlphaTester]: {
    static: [Permission.DECLARATION_CREATE__NAVIGATE],
  },
  [Role.CustomsBroker]: {
    static: [Permission.DECLARATION_VIEW__NAVIGATE],
  },
};

export default rules;
