import React, { FC } from "react";
import { Alert } from "@periplus/ui-library";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import NotFoundPage from "./NotFoundPage";
import SelectRoutes from "pages/Select";
import SettingsRoute from "pages/Settings";
import NonAuthLayout from "../NonAuth/components/Layout";
import { useTranslation } from "react-i18next";

const AuthRoutes: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/select" />} />
        <Route
          path="/login"
          exact
          component={() => <Redirect to="/select" />}
        />
        <PrivateRoute path="/select" component={SelectRoutes} />
        <PrivateRoute path="/settings" component={SettingsRoute} />
        <Route
          path="/reset-password"
          exact
          component={() => (
            <NonAuthLayout>
              <Alert severity="error">{t("You are already signed in")}</Alert>
            </NonAuthLayout>
          )}
        />
        <Route path="/404" exact component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default AuthRoutes;
