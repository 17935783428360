import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  FiltersSidebar as LuiFiltersSidebar,
  Autocomplete,
  DatePicker,
  Typography,
  Box,
  TextField,
} from "@periplus/ui-library";
import dayjs, { Dayjs } from "dayjs";
import StatusFilter from "./StatusFilter";
import { useTranslation } from "react-i18next";
import AddressAutocomplete from "domain/address/components/AddressAutocomplete";
import {
  useGetAddressesLazy,
  AddressListEntity,
  getAddressesQueryVariables,
} from "domain/address/useGetAddresses";
import { DeclarationStatus } from "graphql/generated";
import OrgIdAutocomplete from "domain/orgId/components/OrgIdAutocomplete";
import { useDebounceCallback } from "@react-hook/debounce";
import { NotUndefinable, Overwrite } from "utils/utilityTypes";
import {
  OrgIdListEntity,
  useGetOrgIdsListLazy,
} from "domain/orgId/components/OrgIdAutocomplete/useGetOrgIdsList";

type RelativeDate =
  | "today"
  | "last2weeks"
  | "last4weeks"
  | "last1month"
  | "last3month"
  | "last6month";

export const DECLARATION_FILTER_RELATIVE_DATES = {
  today: {
    label: "Today",
    min_date: dayjs().startOf("day"),
    max_date: dayjs().endOf("day"),
  },
  last2weeks: {
    label: "Last 2 weeks",
    min_date: dayjs().subtract(2, "weeks").startOf("day"),
    max_date: dayjs().endOf("day"),
  },
  last4weeks: {
    label: "Last 4 weeks",
    min_date: dayjs().subtract(4, "weeks").startOf("day"),
    max_date: dayjs().endOf("day"),
  },
  last1month: {
    label: "Previous Month",
    min_date: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    max_date: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last3month: {
    label: "Last 3 Month",
    min_date: dayjs().subtract(3, "month").startOf("month").startOf("day"),
    max_date: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
  last6month: {
    label: "Last 6 Month",
    min_date: dayjs().subtract(6, "month").startOf("month").startOf("day"),
    max_date: dayjs().subtract(1, "month").endOf("month").endOf("day"),
  },
};

type AddressesFilters = {
  payer?: number[];
  consignor?: number[];
  consignee?: number[];
};
const ADDRESS_FILTERS = [
  "payer",
  "consignor",
  "consignee",
] as (keyof AddressesFilters)[];

export type DeclarationFilters = AddressesFilters & {
  org_ids?: number[];
  minTotalColis?: number;
  maxTotalColis?: number;
  minTotalGrossWeight?: number;
  maxTotalGrossWeight?: number;
  declaration_statuses?: DeclarationStatus[];
  relative_date?: RelativeDate | null;
  min_date?: string;
  max_date?: string;
};

type DeclarationFiltersForm = NotUndefinable<
  Overwrite<
    DeclarationFilters,
    {
      payer: AddressListEntity[];
      consignor: AddressListEntity[];
      consignee: AddressListEntity[];
      minTotalColis: string;
      maxTotalColis: string;
      minTotalGrossWeight: string;
      maxTotalGrossWeight: string;
      min_date: Dayjs | null;
      max_date: Dayjs | null;
      org_ids: OrgIdListEntity[];
    }
  >
>;

interface DeclarationFiltersSidebarProps {
  filters?: DeclarationFilters;
  onChange: (newFilters?: DeclarationFilters) => void;
}

const DeclarationFiltersSidebar: FC<DeclarationFiltersSidebarProps> = ({
  filters,
  onChange,
}) => {
  const { t } = useTranslation();

  const defaultValues = useMemo<DeclarationFiltersForm>(() => {
    const relative_date = "last4weeks";
    return {
      payer: [],
      consignor: [],
      consignee: [],
      org_ids: [],
      minTotalColis: "",
      maxTotalColis: "",
      minTotalGrossWeight: "",
      maxTotalGrossWeight: "",
      declaration_statuses: [],
      relative_date,
      min_date: DECLARATION_FILTER_RELATIVE_DATES[relative_date].min_date,
      max_date: DECLARATION_FILTER_RELATIVE_DATES[relative_date].max_date,
    };
  }, []);
  const [values, setValues] = useState<DeclarationFiltersForm>({
    ...defaultValues,
    relative_date: null,
    min_date: null,
    max_date: null,
  });

  const [getInitialAddresses, { loading: initialAddressesLoading }] =
    useGetAddressesLazy();
  const [getInitialOrgIds, { loading: initialOrgIdsLoading }] =
    useGetOrgIdsListLazy();
  useEffect(() => {
    const relative_date =
      filters?.relative_date !== undefined
        ? filters.relative_date
        : defaultValues.relative_date;
    setValues({
      ...ADDRESS_FILTERS.reduce((acc, addressType) => {
        acc[addressType] = defaultValues[addressType];
        return acc;
      }, {} as Record<keyof AddressesFilters, AddressListEntity[]>),
      org_ids: defaultValues.org_ids,
      minTotalColis:
        filters?.minTotalColis?.toString() ?? defaultValues.minTotalColis,
      maxTotalColis:
        filters?.maxTotalColis?.toString() ?? defaultValues.maxTotalColis,
      minTotalGrossWeight:
        filters?.minTotalGrossWeight?.toString() ??
        defaultValues.minTotalGrossWeight,
      maxTotalGrossWeight:
        filters?.maxTotalGrossWeight?.toString() ??
        defaultValues.maxTotalGrossWeight,
      declaration_statuses:
        filters?.declaration_statuses ?? defaultValues.declaration_statuses,
      relative_date,
      ...(relative_date
        ? {
            min_date: DECLARATION_FILTER_RELATIVE_DATES[relative_date].min_date,
            max_date: DECLARATION_FILTER_RELATIVE_DATES[relative_date].max_date,
          }
        : {
            min_date: filters?.min_date ? dayjs(filters?.min_date) : null,
            max_date: filters?.max_date
              ? dayjs(filters?.max_date).endOf("day")
              : null,
          }),
    });

    const addressIds = ADDRESS_FILTERS.reduce((acc, addressType) => {
      acc.push(...(filters?.[addressType] ?? []));
      return acc;
    }, [] as number[]);
    if (addressIds.length) {
      getInitialAddresses({
        variables: getAddressesQueryVariables({
          ids: addressIds,
        }),
      }).then(({ data }) => {
        setValues((prev) => ({
          ...prev,
          ...ADDRESS_FILTERS.reduce((acc, addressType) => {
            acc[addressType] =
              data?.addresses.filter((address) =>
                filters?.[addressType]?.some(
                  (filterAddressTypeAddress) =>
                    filterAddressTypeAddress === address.id
                )
              ) ?? [];
            return acc;
          }, {} as Record<keyof AddressesFilters, AddressListEntity[]>),
        }));
      });
    }

    if (filters?.org_ids?.length) {
      getInitialOrgIds({
        variables: {
          ids: filters?.org_ids,
        },
      }).then(({ data }) => {
        setValues((prev) => ({
          ...prev,
          org_ids: data?.orgIds ?? [],
        }));
      });
    }

    // eslint-disable-next-line
  }, []);

  const debouncedOnChange = useDebounceCallback(
    (newValues: DeclarationFiltersForm) => {
      const castToNumber = (value: string) =>
        value === "" ? undefined : parseFloat(value);

      const newFilters: DeclarationFilters = {
        org_ids: newValues.org_ids?.length
          ? newValues.org_ids.map((el) => el.orgId)
          : undefined,
        minTotalColis: castToNumber(newValues.minTotalColis),
        maxTotalColis: castToNumber(newValues.maxTotalColis),
        minTotalGrossWeight: castToNumber(newValues.minTotalGrossWeight),
        maxTotalGrossWeight: castToNumber(newValues.maxTotalGrossWeight),
        ...ADDRESS_FILTERS.reduce((acc, addressType) => {
          const addressTypeAddresses = newValues[addressType];
          if (addressTypeAddresses?.length) {
            acc[addressType] = addressTypeAddresses.map((a) => a.id);
          }
          return acc;
        }, {} as Record<keyof AddressesFilters, number[]>),
        declaration_statuses: newValues.declaration_statuses?.length
          ? newValues.declaration_statuses
          : undefined,
        relative_date: newValues.relative_date,
        ...(!newValues.relative_date && {
          min_date: newValues.min_date?.startOf("day").format(),
          max_date: newValues.max_date?.endOf("day").format(),
        }),
      };
      const filteredNewFilters = (
        Object.keys(newFilters) as Array<keyof DeclarationFilters>
      ).reduce((acc, newFilterKey) => {
        if (newFilters[newFilterKey] !== defaultValues[newFilterKey]) {
          //@ts-ignore
          acc[newFilterKey] = newFilters[newFilterKey];
        }

        return acc;
      }, {} as DeclarationFilters);
      onChange(
        Object.values(filteredNewFilters).some((filter) => filter !== undefined)
          ? filteredNewFilters
          : undefined
      );
    },
    300
  );

  const handleChange = useCallback(
    (newValues: typeof values) => {
      setValues(newValues);
      debouncedOnChange(newValues);
    },
    [debouncedOnChange]
  );

  return (
    <Box
      sx={(theme) => ({
        minWidth: 240,
        borderRight: `1px solid ${theme.palette.grey3.main}`,
      })}
    >
      <LuiFiltersSidebar
        sx={(theme) => ({
          maxHeight: "calc(100vh - var(--appbar-height))",
          overflow: "auto",
          borderRight: `1px solid ${theme.palette.grey3.main}`,
          position: "fixed",
          top: "var(--appbar-height)",
        })}
        groups={[
          {
            title: t("General"),
            active: Boolean(
              filters?.org_ids?.length ||
                filters?.minTotalColis ||
                filters?.maxTotalColis ||
                filters?.minTotalGrossWeight ||
                filters?.maxTotalGrossWeight
            ),
            content: (
              <>
                <OrgIdAutocomplete
                  userOrgIdsOnly
                  value={values.org_ids}
                  onChange={(_, newValues) =>
                    handleChange({
                      ...values,
                      org_ids: newValues,
                    })
                  }
                  loading={initialOrgIdsLoading}
                  multiple
                  InputProps={{
                    label: t("Org Id"),
                  }}
                />
                <Typography variant="overline" color="textSecondary">
                  {t("Total Colis")}
                </Typography>
                <TextField
                  label={t("From")}
                  value={values.minTotalColis}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, minTotalColis: newValue })
                  }
                />
                <TextField
                  label={t("To")}
                  value={values.maxTotalColis}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, maxTotalColis: newValue })
                  }
                />
                <Typography variant="overline" color="textSecondary">
                  {t("Total Gross Weight")}
                </Typography>
                <TextField
                  label={t("From")}
                  value={values.minTotalGrossWeight}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, minTotalGrossWeight: newValue })
                  }
                />
                <TextField
                  label={t("To")}
                  value={values.maxTotalGrossWeight}
                  type="number"
                  onChange={({ target: { value: newValue } }) =>
                    handleChange({ ...values, maxTotalGrossWeight: newValue })
                  }
                />
              </>
            ),
          },
          {
            title: t("Timeframe"),
            active: Boolean(
              filters?.relative_date !== undefined ||
                filters?.min_date ||
                filters?.max_date
            ),
            content: (
              <>
                {(() => {
                  return (
                    <Autocomplete<RelativeDate>
                      value={values.relative_date}
                      options={[
                        "today",
                        "last2weeks",
                        "last4weeks",
                        "last1month",
                        "last3month",
                        "last6month",
                      ]}
                      onChange={(e, newValue) =>
                        handleChange({
                          ...values,
                          relative_date: newValue,
                          min_date: newValue
                            ? DECLARATION_FILTER_RELATIVE_DATES[newValue]
                                .min_date
                            : null,
                          max_date: newValue
                            ? DECLARATION_FILTER_RELATIVE_DATES[newValue]
                                .max_date
                            : null,
                        })
                      }
                      getOptionLabel={(option) =>
                        t(DECLARATION_FILTER_RELATIVE_DATES[option].label)
                      }
                      InputProps={{
                        label: t("Recent Days"),
                      }}
                    />
                  );
                })()}
                <Typography variant="overline" color="textSecondary">
                  {t("or date range")}
                </Typography>
                <DatePicker
                  value={values.min_date}
                  onChange={(newValue) =>
                    handleChange({
                      ...values,
                      relative_date: null,
                      min_date: newValue,
                    })
                  }
                  label={t("From")}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                />
                <DatePicker
                  value={values.max_date}
                  onChange={(newValue) =>
                    handleChange({
                      ...values,
                      relative_date: null,
                      max_date: newValue,
                    })
                  }
                  label={t("To")}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: t("Addresses"),
            active: ADDRESS_FILTERS.some(
              (addressType) => filters?.[addressType]?.length
            ),
            content: (
              <>
                {ADDRESS_FILTERS.map((addressType) => (
                  <AddressAutocomplete
                    key={addressType}
                    value={values[addressType]}
                    onChange={(_, newValue) => {
                      handleChange({
                        ...values,
                        [addressType]: newValue,
                      });
                    }}
                    InputProps={{
                      label: t(`addressTypes:${addressType}`),
                    }}
                    multiple
                    loading={initialAddressesLoading}
                  />
                ))}
              </>
            ),
          },
          {
            title: t("Status"),
            active: Boolean(filters?.declaration_statuses),
            content: (
              <>
                <StatusFilter
                  value={values.declaration_statuses}
                  onChange={(newValue) =>
                    handleChange({ ...values, declaration_statuses: newValue })
                  }
                />
              </>
            ),
          },
        ]}
        onClear={() => {
          setValues(defaultValues);
          onChange(undefined);
        }}
      />
    </Box>
  );
};

export default DeclarationFiltersSidebar;
