import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import config from "config";
import { useAuth } from "contexts/Auth/AuthContext";
import { DocumentTypeFlag } from "domain/documentType/types";
import { gql } from "@apollo/client";
import { DocumentType } from "./types";

const ADIT_GET_DOCUMENT_TYPES = gql`
  query ADIT_GET_DOCUMENT_TYPES(
    $tenantId: uuid!
    $limit: Int
    $offset: Int
    $where: document_type_bool_exp!
  ) {
    document_type(
      order_by: { creation_date: asc }
      where: $where
      limit: $limit
      offset: $offset
    ) {
      document_type_tenant_settings(where: { tenant: { _eq: $tenantId } }) {
        code
        default_flags
        meta
      }
      last_modified_date: last_modified_date
      name
      display_name
      translations
    }
    document_type_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const useGetDocumentTypes = ({
  flag,
  tenantId,
  search,
  page,
  itemsPerPage,
}: {
  flag?: DocumentTypeFlag;
  tenantId?: string;
  search?: string;
  page?: number;
  itemsPerPage?: number;
} = {}) => {
  const { user } = useAuth();

  const queryData = useQuery<{
    document_type: DocumentType[];
    document_type_aggregate: {
      aggregate: {
        count: number;
      };
    };
  }>(ADIT_GET_DOCUMENT_TYPES, {
    variables: {
      tenantId: tenantId || user?.tenantId,
      ...(page &&
        itemsPerPage && {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
        }),
      where: {
        document_type_tenant_settings: { code: { _is_null: false } },
        ...(search && {
          name: { _like: `%${search}%` },
        }),
      },
    },
  });

  const {
    i18n: { language },
  } = useTranslation();

  const returnValue = useMemo(() => {
    const { data, ...rest } = queryData;
    const documentTypes = (data && data.document_type) || [];

    const updatedDocumentTypes = documentTypes.reduce(
      (acc, documentType: any) => {
        const {
          document_type_tenant_settings: [docTypeTenantSettings],
        } = documentType;
        const { last_modified_date, name, display_name, translations } =
          documentType;
        const rslt = {
          name,
          display_name,
          translations,
          last_modified_date,
          ...docTypeTenantSettings,
          translated_name:
            documentType?.translations[language] ||
            documentType?.translations[config.defaultLanguage] ||
            documentType.display_name,
        };

        if (flag !== undefined) {
          if (!(rslt.default_flags & flag)) {
            return acc;
          }
        }
        acc.push(rslt);
        return acc;
      },
      [] as DocumentType[]
    );

    return {
      data: updatedDocumentTypes,
      documentTypesCount: data?.document_type_aggregate.aggregate.count || 0,
      ...rest,
    };
  }, [flag, queryData, language]);

  return returnValue;
};

export default useGetDocumentTypes;
