import { useMutation } from "@apollo/client";
import { DELETE_ADDRESS } from "graphql/mutations/address";
import { useCallback } from "react";

const useDeleteAddress = () => {
  const [deleteAddress] = useMutation(DELETE_ADDRESS);

  const handleDelete = useCallback(
    (id: number) => {
      return deleteAddress({
        variables: { id },
      });
    },
    [deleteAddress]
  );

  return handleDelete;
};

export default useDeleteAddress;
